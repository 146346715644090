import { ProductWithVariation } from "../../components/bookingProvider/BookingContext";

interface Props {
  bookedProducts: ProductWithVariation[] | undefined;
}

const getCartSum = ({ bookedProducts }: Props): number => {
  let sum: number = 0;
  if (!bookedProducts) return sum;
  bookedProducts?.forEach((productWithVariation: ProductWithVariation) => {
    if (
      productWithVariation?.price !== null &&
      productWithVariation?.price !== undefined
    ) {
      sum += Number(productWithVariation.price);
    }
    return sum;
  });
  return sum;
};

export default getCartSum;
