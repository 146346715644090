import { from, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

const generateLink = (token?: string | null, logout?: () => void) => {
  const httpConnectionLink = new HttpLink({
    uri: `${import.meta.env.VITE_SERVER_URI}`,
  });

  const httpAuthLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const httpErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        Sentry.captureMessage(message, "error");
        if (message === "Unauthorized" || message === "Invalid token") {
          // Logout if unauthorized
          if (logout) logout();
        }
      });
    if (networkError) Sentry.captureException(networkError);
  });

  const httpLink = from([httpErrorLink, httpAuthLink, httpConnectionLink]);

  return from([httpErrorLink, httpAuthLink, httpLink]);
};

export default generateLink;
