import dayjs from "dayjs";
import { Buffer } from "buffer";

export const getTokenExpiryDate = (t: string) => {
  // Decode JWT
  const tokenParts = t.split(".");
  if (!tokenParts || tokenParts.length !== 3) return dayjs("1970-01-01");

  // Check expiry date
  const tokenData: { exp: number } = JSON.parse(
    Buffer.from(tokenParts[1], "base64").toString(),
  );

  return dayjs.unix(tokenData.exp);
};

export const getRoles = (t: string | null | undefined): string[] => {
  if (!t) return [];
  // Decode JWT
  const tokenParts = t.split(".");
  if (!tokenParts || tokenParts.length !== 3) return [];

  // Check expiry date
  const tokenData: { roles: string[] } = JSON.parse(
    Buffer.from(tokenParts[1], "base64").toString(),
  );

  return tokenData.roles;
};

export const tokenIsValid = (t: string) => {
  if (!t) return false;

  const expiresAt = getTokenExpiryDate(t);
  const maxAge = dayjs().subtract(5, "minutes");

  return expiresAt.valueOf() > maxAge.valueOf();
};

export const getTokensFromStorage = async () => {
  const loadedToken = window.localStorage.getItem("customerToken");

  return [loadedToken];
};

export const setTokensInStorage = (t: string) => {
  window.localStorage.setItem("customerToken", t);
};

export const deleteTokensFromStorage = () => {
  window.localStorage.removeItem("customerToken");
};

export const getOptionsFromToken = (t: string | null | undefined) => {
  if (!t) return {};
  // Decode JWT
  const tokenParts = t.split(".");
  if (!tokenParts || tokenParts.length !== 3) return {};

  const tokenData: {
    payload: {
      options: unknown;
    };
  } = JSON.parse(Buffer.from(tokenParts[1], "base64").toString());

  return tokenData.payload.options;
};

export const getUserIdFromToken = (t: string) => {
  // Decode JWT
  const tokenParts = t.split(".");
  if (!tokenParts || tokenParts.length !== 3) return 0;

  // Check expiry date
  const tokenData: { id: unknown } = JSON.parse(
    Buffer.from(tokenParts[1], "base64").toString(),
  );

  return tokenData.id;
};
