import { useCallback } from "react";
import { Form, Input, message, Radio, Typography } from "antd";
import formItemProps from "../../helper/form/formItemProps";
import {
  CouponVerificationOutput,
  Product,
  useVerifyCouponsMutation,
} from "../../graphql/schema";
import getPriceForCustomer from "../../helper/common/getPriceForCustomer";
import useBooking from "../bookingProvider/useBooking";
import { ProductWithVariation } from "../bookingProvider/BookingContext";
import getProductByBookedProduct from "../../helper/common/getProductByBookedProduct";
import CouponLabel from "../confirmation/CouponLabel";

interface Props {
  products: any[];
}

const DetailsDesktop = ({ products }: Props) => {
  const [form] = Form.useForm();
  const { stateValues, getStates, setStates } = useBooking();
  const [verify] = useVerifyCouponsMutation();

  const getCouponCodes = () => {
    const { couponCodes } = getStates(["couponCodes"]);
    return couponCodes?.length ? couponCodes : [];
  };

  const removeLastCouponCode = () => {
    const codes = getCouponCodes();
    if (codes.length) {
      codes.splice(codes.length - 1, 1);
    }
    setStates({
      ...stateValues,
      couponCodes: codes,
    });
  };
  const getBookedProducts = () => {
    const { bookedProducts } = getStates(["bookedProducts"]);
    return bookedProducts?.length ? bookedProducts : [];
  };

  const resetPrices = useCallback(() => {
    const bookedProducts = getBookedProducts();
    setStates({
      ...stateValues,
      couponCodes: [],
      bookedProducts: bookedProducts.map((product) => {
        let price = "0";
        if (product.variation) {
          const variation = products
            .find((p: Product) => p.id === product.id)
            .variations.find(
              (variation: any) => variation.id === product.variation,
            );
          if (variation?.prices)
            price = getPriceForCustomer(
              variation.prices,
              stateValues.customer,
            ).toString();
        } else {
          price = getPriceForCustomer(
            products.find((p: Product) => p.id === product.id).prices,
            stateValues.customer,
          ).toString();
        }

        const baseProduct = getProductByBookedProduct(product, products);
        return {
          ...product,
          price: price.toString(),
          netPrice: Number(price) / (1 + Number(baseProduct.taxRate) / 100),
        };
      }),
    });
  }, [getStates, setStates, stateValues]);

  const setNewPriceForBookedProducts = (
    bookedProducts: CouponVerificationOutput[] | undefined,
    codes: string[],
  ) => {
    if (!bookedProducts) return;
    const verifiedBookedProducts: ProductWithVariation[] = bookedProducts.map(
      (productOutput) => {
        const product: ProductWithVariation = {
          ...productOutput,
          id: productOutput.productId,
          name: "",
          category: "",
          variation: productOutput.variationId
            ? productOutput.variationId
            : undefined,
          price: productOutput.price ? productOutput.price : null,
        };

        const baseProduct = getProductByBookedProduct(product, products);
        const variation = baseProduct?.variations?.find(
          (variation: any) => variation.id === product.variation,
        );
        const taxRate = baseProduct?.taxRate || 0;
        const netPrice = Number(
          Number(product.price) / (1 + taxRate / 100),
        ).toFixed(2);
        return {
          ...product,
          id: product.id,
          variation: product.variation,
          price: product.price,
          netPrice,
          baseName: baseProduct?.name || "",
          name: variation?.name || baseProduct?.name || "",
          category: baseProduct?.productCategory?.name || "",
        };
      },
    );

    setStates({
      ...stateValues,
      bookedProducts: verifiedBookedProducts,
      couponCodes:
        JSON.stringify(verifiedBookedProducts) ===
        JSON.stringify(stateValues.bookedProducts)
          ? codes.filter((code) => code !== codes.pop())
          : codes,
    });
    if (codes.length < getCouponCodes().length) {
      if (codes.length === 0) {
        resetPrices();
      }
      form.setFieldValue(["coupon"], "");
      message.success("Gutschein entfernt");
    } else if (
      JSON.stringify(verifiedBookedProducts) ===
      JSON.stringify(stateValues.bookedProducts)
    ) {
      message.error("Gutschein nicht gültig");
    } else {
      message.success("Gutschein angewendet");
    }
  };

  const verifyCoupon = useCallback(
    async (codes?: string[]): Promise<boolean> => {
      const bookedProducts = getBookedProducts();
      if (!bookedProducts) return false;
      try {
        const response = await verify({
          variables: {
            dto: {
              products: bookedProducts.map((product) => ({
                productId: product.id,
                variationId: product.variation ? product.variation : undefined,
                price: product.price ? product.price.toString() : null,
              })),
              couponCodes: codes || getCouponCodes(),
              customer: {
                id: stateValues.customer.id || 0,
              },
            },
          },
        });
        setNewPriceForBookedProducts(
          response.data?.verifyCoupons || [],
          codes || getCouponCodes(),
        );
      } catch (error) {
        removeLastCouponCode();
        message.error("Gutschein nicht gültig");
      }
      return true;
    },
    [getStates, setStates, stateValues],
  );

  const addCouponCode = async () => {
    const code = form.getFieldValue("coupon");
    if (!code) return;
    const codes = getCouponCodes();
    if (!codes.some((c) => c.toLowerCase() === code.toLowerCase())) {
      codes.push(code);
      await verifyCoupon(codes);
    } else {
      message.error("Gutschein wurde bereits hinzugefügt");
    }
    form.setFieldValue("coupon", null);
  };

  const removeCouponCode = async (code: string) => {
    const codes = getCouponCodes().filter((c) => c !== code);
    await verifyCoupon(codes);
  };

  const handleChange = (values: any) => {
    setStates({
      ...stateValues,
      message: values?.message || "",
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(changedValues, values) => handleChange(values)}
    >
      <Form.Item
        style={{
          fontWeight: "bold",
        }}
        {...formItemProps("payment", "Zahlungsmethode", false)}
        valuePropName="checked"
      >
        <Radio
          defaultChecked
          disabled
          id="payment"
          style={{
            height: 20,
          }}
        >
          <Typography.Text>Vor Ort</Typography.Text>
        </Radio>
      </Form.Item>
      <Form.Item
        style={{
          fontWeight: "bold",
          width: "50%",
        }}
        {...formItemProps("coupon", "Gutscheincodes", false)}
      >
        <Input
          size="large"
          suffix={
            <Typography.Text
              style={{
                borderColor: "#d9d9d9",
                color: "#8B8B8B",
                cursor: "pointer",
              }}
              onClick={() => addCouponCode()}
            >
              Anwenden
            </Typography.Text>
          }
          style={{
            borderRadius: 15,
          }}
        />
      </Form.Item>
      {stateValues.couponCodes?.map((code) => (
        <CouponLabel code={code} remove={removeCouponCode} />
      ))}
      <Form.Item
        style={{
          fontWeight: "bold",
        }}
        {...formItemProps("message", "Deine Nachricht", false)}
      >
        <Input.TextArea
          rows={6}
          style={{
            borderRadius: 10,
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default DetailsDesktop;
