import React from "react";
import { Button, Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Layout, useLayout } from "../layout/LayoutContext";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

interface Props {
  content: React.ReactNode;
  title?: string;
  to?: string;
  onContinue?: () => Promise<void>;
}

const BottomBadge = ({
  content,
  to,
  title = "Fortfahren",
  onContinue = () => Promise.resolve(),
}: Props) => {
  const navigate = useNavigate();
  const { stateValues } = useBooking();
  const { responsive, device } = useLayout();

  const divStyle: React.CSSProperties = {
    position: "fixed",
    bottom: 0,
    height: 90,
    padding: responsive({
      desktop: "20px",
      tablet: "20px",
      mobile: "20px",
    }),
    paddingLeft: responsive({
      desktop: "20px",
      tablet: "40px",
      mobile: "40px",
    }),
    zIndex: 1000,
    width: "100%",
    borderTop: "1px solid #e8e8e8",
    backgroundColor: "#fff",
    display:
      !(device === Layout.DESKTOP) && stateValues.bookedProducts.length > 0
        ? "block"
        : "none",
  };
  return (
    <div style={divStyle}>
      <Row justify="space-between">
        {content}
        <Col>
          <Button
            style={{
              height: 47,
              backgroundColor: theme.colors.button,
              borderRadius: "20px",
            }}
            onClick={async () => {
              if (to) {
                navigate(to);
              }
              await onContinue();
            }}
          >
            <Typography.Text strong style={{ color: "#fff", marginBottom: 0 }}>
              {title}
            </Typography.Text>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default BottomBadge;
