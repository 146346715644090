import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useLayout } from "../layout/LayoutContext";
import useBooking from "../bookingProvider/useBooking";
import getCartSum from "../../helper/common/getCartSum";
import getRegularCartSum from "../../helper/common/getRegularCartSum";

interface Props {
  products: any[];
}

const DetailsBadgeContent = ({ products }: Props) => {
  const { responsive } = useLayout();
  const { stateValues } = useBooking();
  const [totalPrice, setTotalPrice] = useState<number | undefined>(undefined);
  const [regularTotalPrice, setRegularTotalPrice] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      const cartSum = getCartSum({
        bookedProducts: stateValues.bookedProducts,
      });
      setTotalPrice(cartSum);
      const regularCartSum = getRegularCartSum(
        stateValues.bookedProducts,
        products,
        stateValues.customer,
      );
      setRegularTotalPrice(regularCartSum);
    })();
  }, [stateValues]);

  if (!totalPrice || !regularTotalPrice) return null;

  return (
    <Col>
      <Row>
        <Col span={24}>
          <Typography.Title
            level={5}
            style={{
              marginBottom: 0,
            }}
          >
            Summe
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography.Text delete={regularTotalPrice > totalPrice}>
            {regularTotalPrice.toFixed(2).replace(".", ",")}€
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text
            style={{
              display: totalPrice < regularTotalPrice ? "block" : "none",
              marginLeft: 5,
            }}
          >
            {Number(totalPrice).toFixed(2).replace(".", ",")}€
          </Typography.Text>
        </Col>
      </Row>
    </Col>
  );
};

export default DetailsBadgeContent;
