import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../base/SubHeader";
import { Layout, useLayout } from "../layout/LayoutContext";
import { ProductCategoryOutput } from "../../graphql/schema";
import CategorySelect from "./CategorySelect";
import CategoryList from "./CategoryList";
import useBooking from "../bookingProvider/useBooking";

interface Props {
  productCategories: Partial<ProductCategoryOutput>[];
  products: any[];
}

const ProductsContainer = ({
  productCategories = [],
  products = [],
}: Props) => {
  const { device } = useLayout();
  const { stateValues, setStates } = useBooking();
  const [searchValue, setSearchValue] = useState("");
  const [category, setCategory] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (productCategories.length > 0) {
      setCategory(productCategories[0]?.id || null);
    }
  }, [productCategories]);

  useEffect(() => {
    if (searchValue === "") {
      setCategory(productCategories[0]?.id || null);
    } else {
      const product = products.find(
        (product) => product.name?.toLowerCase() === searchValue.toLowerCase(),
      );
      setCategory(product ? product.productCategory?.id || null : null);
    }
  }, [searchValue, productCategories, products]);

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <SubHeader
        searchable
        showSearch={device !== Layout.MOBILE}
        title="Wähle eine Behandlung aus"
        onBack={() => {
          setStates({
            ...stateValues,
            bookedProducts: [],
          });
          navigate("/");
        }}
        onSearch={setSearchValue}
      />
      <CategorySelect
        onSelect={setCategory}
        category={category}
        categories={productCategories}
      />
      <CategoryList
        productCategories={productCategories}
        products={products}
        categoryId={category}
        searchValue={searchValue}
      />
    </div>
  );
};

export default React.memo(ProductsContainer);
