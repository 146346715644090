import React from "react";

export enum GTMEvent {
  BOOKING_FUNNEL_INIT = "booking_funnel_init",
  BOOKING_FUNNEL_ADD_TO_CART = "booking_funnel_add_to_cart",
  BOOKING_FUNNEL_BRANCH_SELECTED = "booking_funnel_branch_selected",
  BOOKING_FUNNEL_REMOVE_FROM_CART = "booking_funnel_remove_from_cart",
  BOOKING_FUNNEL_SERVICE_SELECTION = "booking_funnel_service_selection",
  BOOKING_FUNNEL_APPOINTMENT_SELECTION = "booking_funnel_appointment_selection",
  BOOKING_FUNNEL_APPOINTMENT_SELECTED = "booking_funnel_appointment_selected",
  BOOKING_FUNNEL_CONTACT_DETAILS = "booking_funnel_contact_details",
  BOOKING_FUNNEL_BOOKED = "booking_funnel_booked",
}

interface GTMContextProps {
  sendEvent: (event: GTMEvent, data: any) => void;
}

const GTMContext = React.createContext<GTMContextProps>({
  sendEvent: (event: GTMEvent, data: any) => {},
});

export default GTMContext;
