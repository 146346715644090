import "./instrument";
import React from "react";
import { createRoot } from "react-dom/client";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import "./main.scss";
import "@3ts/react-ant-crud/dist/style.css";
import de_DE from "antd/locale/de_DE";
import dayjs from "dayjs";
import { BrowserRouter } from "react-router-dom";
import "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { ConfigProvider } from "antd";
import App from "./App";
import CustomerAuthProvider from "./customerAuth/customerAuthProvider";
import { LayoutProvider } from "./components/layout/LayoutContext";
import { LoginProvider } from "./components/login/LoginContext";
import theme from "./theme/theme";
import GTMProvider from "./components/gtmProvider/GTMProvider";
import BookingProvider from "./components/bookingProvider/BookingProvider";

dayjs.locale("de");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs.tz.setDefault("Europe/Berlin");

// Init GraphQL connection
const graphClient = new ApolloClient({
  uri: `${
    (window as { apiURL?: string }).apiURL || import.meta.env.VITE_SERVER_URI
  }/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <ApolloProvider client={graphClient}>
      <CustomerAuthProvider>
        <LayoutProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#324E61",
                colorSuccess: "#52c41a",
                fontSize: 16,
                fontFamily: "",
                colorBgBase: theme.colors.background,
                colorText: theme.colors.text,
                boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
              },
              components: {
                Input: {
                  colorTextPlaceholder: theme.colors.text,
                },
                Typography: {
                  fontSizeHeading1: 24,
                  fontSizeHeading2: 18,
                  lineHeightHeading1: 1.2,
                  lineHeightHeading2: 1.2,
                  titleMarginBottom: 0,
                  titleMarginTop: 0,
                  colorTextHeading: theme.colors.text,
                  fontWeightStrong: 600,
                  fontSize: 16,
                },
                Button: {
                  colorText: theme.colors.text,
                  borderRadius: 30,
                  colorPrimary: theme.colors.button,
                  colorBgBase: theme.colors.button,
                  colorBgContainer: theme.colors.button,
                  colorTextBase: theme.colors.text,
                  colorPrimaryText: theme.colors.button,
                  colorPrimaryHover: theme.colors.button,
                  colorPrimaryActive: theme.colors.button,
                  colorPrimaryBgHover: theme.colors.button,
                },
                Card: {},
                Progress: {
                  borderRadius: 10,
                  padding: 20,
                  colorText: theme.colors.text,
                },
              },
            }}
            locale={de_DE}
          >
            <BrowserRouter>
              <BookingProvider>
                <LoginProvider>
                  <GTMProvider>
                    <App />
                  </GTMProvider>
                </LoginProvider>
              </BookingProvider>
            </BrowserRouter>
          </ConfigProvider>
        </LayoutProvider>
      </CustomerAuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
