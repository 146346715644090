import { Button, Card, Col, message, Row, Space, Typography } from "antd";
import { addMinutes } from "date-fns";
// @ts-ignore
import { de } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { ics } from "calendar-link";
import dayjs from "dayjs";
import { useGetPublicStoreQuery } from "../../graphql/schema";
import getProductByBookedProduct from "../../helper/common/getProductByBookedProduct";
import { useLayout } from "../layout/LayoutContext";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

const BookingConfirmationDesktop = () => {
  const { responsive } = useLayout();
  const { stateValues, setStates } = useBooking();
  const navigate = useNavigate();
  const { data: store } = useGetPublicStoreQuery({
    variables: {
      id: stateValues.store.id!,
    },
    skip: !stateValues.store.id,
  });

  const getAppointmentsTotalDuration = () => {
    let totalDuration = 0;
    if (!stateValues.bookedProducts) return totalDuration;
    if (!store?.publicStore.products) return totalDuration;
    stateValues.bookedProducts.forEach((p: any) => {
      const product = getProductByBookedProduct(p, store?.publicStore.products);
      totalDuration += product?.duration ? product.duration : 0;
    });
    return totalDuration;
  };

  const handleAddToCalendar = () => {
    if (!stateValues.appointment) return;
    const startTime = new Date(stateValues.appointment);
    const endTime = addMinutes(
      new Date(stateValues.appointment),
      getAppointmentsTotalDuration(),
    );
    const event = {
      title: "Kalia lab Termin",
      description: "Wir freuen uns auf Ihren Besuch!",
      start: startTime,
      end: endTime,
    };
    try {
      window.open(ics(event));
    } catch (e) {
      message.error(
        "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
      );
    }
  };
  return (
    <Row
      style={{
        width: "100%",
        height: "100vh",
      }}
      justify="center"
      align="middle"
    >
      <Col>
        <Card
          style={{
            borderColor: theme.colors.text,
            width: 400,
            padding: 0,
          }}
          bodyStyle={{
            paddingTop: 40,
            paddingRight: 30,
            paddingBottom: 40,
            paddingLeft: 30,
          }}
        >
          <Space direction="vertical">
            <Typography.Title
              level={3}
              style={{
                textAlign: "center",
              }}
            >
              Danke für deine Buchung!
            </Typography.Title>
            <Row justify="center">
              <Col span={16}>
                <Row justify="center">
                  <Col
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Typography.Text>
                      Dein Termin ist geplant für{" "}
                      <Typography.Text strong>
                        {stateValues.appointment
                          ? dayjs
                              .tz(stateValues.appointment)
                              .format("ddd, D. MMM")
                          : ""}{" "}
                        um{" "}
                        {stateValues.appointment
                          ? dayjs.tz(stateValues.appointment).format("HH:mm")
                          : ""}{" "}
                        Uhr.
                      </Typography.Text>
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <Typography.Text
                  style={{
                    textAlign: "center",
                  }}
                >
                  In Kürz erhälst Du eine Bestätigung per E-Mail. Sollte diese
                  in deinem Postfach erscheinen, überprüfe bitte Deinen
                  Spam-Ordner.
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Button
                  style={{
                    width: "100%",
                    marginTop: 30,
                    borderRadius: "15px",
                    height: "49px",
                    backgroundColor: theme.colors.button,
                  }}
                  onClick={() => handleAddToCalendar()}
                >
                  <Typography.Text
                    strong
                    style={{
                      color: "white",
                    }}
                  >
                    Zum Kalender hinzufügen
                  </Typography.Text>
                </Button>
              </Col>
              <Col span={24}>
                <Button
                  type="text"
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    height: "40px",
                  }}
                  onClick={() => {
                    setStates({
                      appointment: undefined,
                      bookedProducts: [],
                      couponCodes: [],
                      notifyForEarlierAppointment: false,
                    });
                    navigate("/");
                  }}
                >
                  <Typography.Text
                    strong
                    style={{
                      color: theme.colors.text,
                    }}
                  >
                    Zurück zum Start
                  </Typography.Text>
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingConfirmationDesktop;
