import Card from "./ProductCard";
import { ProductOutput } from "../../graphql/schema";
import { Col } from "antd";

interface Props {
  product: Partial<ProductOutput>;
  products: Partial<ProductOutput>[];
  searchValue?: string;
}

const ProductCardContainer = ({ product, searchValue, products }: Props) => {
  if (!product) return null;
  return !product?.variations || !product?.variations.length ? (
    <Col key={product.id} xs={24} sm={24} md={12} lg={12} xl={12}>
      <Card
        searchValue={searchValue}
        singleProduct={product}
        productId={product?.id}
        products={products}
        description={product.descriptionLong}
      />
    </Col>
  ) : (
    product.variations.map((variation: any) => (
      <Col key={product.id} xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card
          key={`ProductCard_${product.id}_${variation.id}`}
          variationId={variation.id}
          productId={product?.id}
          products={products}
          searchValue={searchValue}
          description={product.descriptionLong}
          singleProduct={variation}
        />
      </Col>
    ))
  );
};

export default ProductCardContainer;
