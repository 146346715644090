import { Col, Input, Row, Typography } from "antd";
import { CloseOutlined, LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Layout, useLayout } from "../layout/LayoutContext";
import theme from "../../theme/theme";

interface Props {
  title: string;
  onBack?: () => void;
  onSearch?: (text: string) => void;
  searchable?: boolean;
  showSearch?: boolean;
}

const SubHeader = ({
  title,
  onBack,
  onSearch,
  searchable = false,
  showSearch = false,
}: Props) => {
  const { responsive, device } = useLayout();
  const [searchVisible, setSearchVisible] = useState(showSearch);

  useEffect(() => {
    setSearchVisible(device !== Layout.MOBILE);
  }, [device]);

  if (searchVisible)
    return (
      <Row
        align="middle"
        style={{
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          marginTop: responsive({
            desktop: "40px",
            tablet: "40px",
            mobile: "20px",
          }),
          marginBottom: "25px",
        }}
      >
        {onBack && (
          <Col
            span={responsive({
              desktop: 1,
              tablet: 1,
              mobile: 0,
            })}
          >
            <LeftOutlined
              style={{
                fontSize: device !== Layout.MOBILE ? 20 : 16,
                color: theme.colors.icon,
              }}
              onClick={() => onBack()}
            />
          </Col>
        )}
        <Col
          span={responsive({
            desktop: 23,
            tablet: 23,
            mobile: 24,
          })}
        >
          <Row align="middle" justify="space-between">
            <Col
              span={responsive({
                desktop: 14,
                tablet: 14,
                mobile: 0,
              })}
            >
              <Typography.Title
                level={device !== Layout.MOBILE ? 3 : 5}
                style={{
                  marginBottom: "0px",
                  fontSize: device !== Layout.MOBILE ? 28 : 16,
                  color: theme.colors.header,
                }}
              >
                {title}
              </Typography.Title>
            </Col>
            {searchable && (
              <Col
                span={
                  searchable
                    ? responsive({
                        desktop: 10,
                        tablet: 10,
                        mobile: 21,
                      })
                    : 0
                }
              >
                <Row>
                  <Col span={24}>
                    <Input
                      size="large"
                      onChange={(e) => onSearch && onSearch(e.target.value)}
                      suffix={
                        <SearchOutlined
                          style={{
                            fontSize: 20,
                            color: theme.colors.header,
                          }}
                        />
                      }
                      style={{
                        borderRadius: "15px",
                        borderColor: theme.colors.header,
                        backgroundColor: theme.colors.background,
                        justifyContent: "center",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={device !== Layout.MOBILE ? 0 : 2}>
              {searchVisible ? (
                <CloseOutlined
                  style={{
                    fontSize: device !== Layout.MOBILE ? 30 : 16,
                    color: theme.colors.header,
                  }}
                  onClick={() => setSearchVisible(!searchVisible)}
                />
              ) : (
                <SearchOutlined
                  style={{
                    fontSize: device !== Layout.MOBILE ? 30 : 16,
                    color: theme.colors.header,
                  }}
                  onClick={() => setSearchVisible(!searchVisible)}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );

  return (
    <Row
      align="middle"
      style={{
        width: "100%",
        marginLeft: 0,
        marginRight: 0,
        marginTop: responsive({
          desktop: "40px",
          tablet: "40px",
          mobile: "20px",
        }),
        marginBottom: "20px",
      }}
    >
      {onBack && (
        <Col
          span={responsive({
            desktop: 1,
            tablet: 1,
            mobile: 2,
          })}
        >
          <LeftOutlined
            style={{
              fontSize: device !== Layout.MOBILE ? 20 : 16,
              color: theme.colors.icon,
            }}
            onClick={() => onBack()}
          />
        </Col>
      )}
      <Col
        span={responsive({
          desktop: 23,
          tablet: 23,
          mobile: 22,
        })}
        style={{
          paddingLeft: responsive({
            desktop: 0,
            tablet: 0,
            mobile: 5,
          }),
        }}
      >
        <Row align="middle">
          <Col
            span={responsive({
              desktop: 14,
              tablet: 20,
              mobile: 21,
            })}
          >
            <Typography.Title
              level={device !== Layout.MOBILE ? 3 : 5}
              style={{
                fontSize: device !== Layout.MOBILE ? 28 : 16,
                marginBottom: "0px",
              }}
            >
              {title}
            </Typography.Title>
          </Col>
          {searchable && (
            <Col span={device === Layout.DESKTOP ? 0 : 3}>
              <SearchOutlined
                style={{
                  fontSize: device !== Layout.MOBILE ? 30 : 20,
                  color: theme.colors.header,
                }}
                onClick={() => setSearchVisible(!searchVisible)}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default SubHeader;
