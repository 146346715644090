import { isAfter, isBefore } from "date-fns";
import { Customer, CustomerType, ProductPrice } from "../../graphql/schema";

const getPricesForCustomerCategory = (
  prices: ProductPrice[],
  customerType: CustomerType,
): ProductPrice[] => {
  const pricesForCustomer = prices.filter(
    (price) => price.customerType === customerType,
  );
  if (pricesForCustomer.length > 0) {
    return pricesForCustomer;
  }
  return prices.filter((price) => price.customerType === CustomerType.Standard);
};

const getActiveTemporaryPrice = (prices: ProductPrice[]): ProductPrice[] => {
  return prices.filter((price: ProductPrice) => {
    return (
      price.validTo &&
      price.validFrom &&
      isBefore(new Date(), new Date(price.validTo)) &&
      isAfter(new Date(), new Date(price.validFrom))
    );
  });
};

const getStandardPrice = (prices: ProductPrice[]): number => {
  const standardPrice = prices.find(
    (price: ProductPrice) => !price.validTo && !price.validFrom,
  );
  return parseInt(standardPrice?.price || "0", 10);
};

const getPriceForCustomer = (
  prices: ProductPrice[],
  customer?: Partial<Customer> | undefined,
): number => {
  const pricesForCustomer = getPricesForCustomerCategory(
    prices,
    customer?.category || CustomerType.Standard,
  );

  const activeTemporaryPrice = getActiveTemporaryPrice(pricesForCustomer);

  if (activeTemporaryPrice.length > 0) {
    return parseInt(activeTemporaryPrice[0].price, 10);
  }
  return getStandardPrice(pricesForCustomer);
};

export default getPriceForCustomer;
