import React, { useEffect } from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const AppRedirection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const { userAgent } = navigator;
      if (/windows phone/i.test(userAgent)) {
        window.location.href = `https://play.google.com/store/apps/details?id=de.kalialab.kaliaapp`;
      }

      if (/android/i.test(userAgent)) {
        window.location.href = `https://play.google.com/store/apps/details?id=de.kalialab.kaliaapp`;
      }

      if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.location.href = `https://apps.apple.com/app/KD259G8M9Z`;
      }
    } catch (error) {
      window.location.href = "/";
    }
  }, []);

  return (
    <Result
      status="404"
      title="App nicht gefunden"
      subTitle="Dieser Link ist nur auf mobilen Geräten verfügbar. Bitte öffnen Sie ihn auf Ihrem Smartphone."
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          Zurück zur Startseite
        </Button>
      }
    />
  );
};

export default AppRedirection;
