import React from "react";
import { Checkbox, Col, Form, Row } from "antd";
import formItemProps from "../../helper/form/formItemProps";
import useBooking from "../bookingProvider/useBooking";

const EarlierAppointment = () => {
  const { stateValues, setStates } = useBooking();
  const onChange = (changedValues: any, value: any) => {
    setStates({
      ...stateValues,
      notifyForEarlierAppointment: value.notifyForEarlierAppointment,
    });
  };
  return (
    <Row
      justify="center"
      style={{
        marginTop: 20,
      }}
    >
      <Col span={24}>
        <Form
          onValuesChange={(changedValues, values) =>
            onChange(changedValues, values)
          }
        >
          <Form.Item
            {...formItemProps("notifyForEarlierAppointment", "", false)}
            valuePropName="checked"
          >
            <Checkbox>
              Ich möchte benachrichtigt werden, falls ein früherer Termin
              verfügbar ist
            </Checkbox>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default EarlierAppointment;
