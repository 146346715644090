import React from "react";
import { Col, Row } from "antd";
import { useLayout } from "./LayoutContext";
import ProgressBar from "../base/ProgressBar";

interface Props {
  content: React.ReactNode;
  card?: React.ReactNode;
  subHeader?: React.ReactNode;
  progress: 20 | 40 | 60 | 80 | 100;
  bottomCard?: React.ReactNode;
}

const BookingLayout = ({
  content,
  card,
  subHeader,
  progress,
  bottomCard,
}: Props) => {
  const { responsive } = useLayout();
  return (
    <Row
      style={{
        width: "100%",
      }}
      justify="center"
    >
      <Col
        span={responsive({
          mobile: 22,
          tablet: 22,
          desktop: 20,
        })}
      >
        <Row align="middle" justify="center">
          <Col span={24}>
            <ProgressBar percent={progress} />
          </Col>
        </Row>
        <Row
          id="booking-layout"
          justify="space-between"
          style={{
            alignItems: "space-between",
          }}
        >
          <Col
            span={responsive({
              mobile: 24,
              tablet: 24,
              desktop: 15,
            })}
            style={{
              paddingRight: responsive({
                mobile: 0,
                tablet: 0,
                desktop: 0,
              }),
            }}
          >
            {subHeader}
            {content}
          </Col>
          {card && <Col span={8}>{card}</Col>}
        </Row>
      </Col>
      {bottomCard && bottomCard}
    </Row>
  );
};

export default BookingLayout;
