import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useGetPublicStoresQuery } from "../../graphql/schema";
import useBooking from "../../components/bookingProvider/useBooking";
import { useLayout } from "../../components/layout/LayoutContext";
import BookingLayout from "../../components/layout/BookingLayout";
import StoreCards from "../../components/store/StoreCards";
import SubHeader from "../../components/base/SubHeader";
import OverviewCard from "../../components/overview/OverviewCard";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useGTM from "../../components/gtmProvider/useGTM";
import { GTMEvent } from "../../components/gtmProvider/GTMContext";

const storeDefaults = {
  bookedProducts: [],
  couponCodes: [],
  appointment: undefined,
  notifyForEarlierAppointment: false,
  store: {
    id: undefined,
    name: "",
  },
  rescheduleId: undefined,
};

const StoreSelection = () => {
  const { isAuthenticated } = useCustomerAuth();
  const { sendEvent } = useGTM();
  const { responsive } = useLayout();
  const { data, loading } = useGetPublicStoresQuery({
    fetchPolicy: "no-cache",
  });
  const { stateValues, setStates, resetStates } = useBooking();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
    sendEvent(GTMEvent.BOOKING_FUNNEL_INIT, {
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
  }, []);

  const onContinue = useCallback(async () => {
    navigate("/services");
  }, [stateValues]);

  const handleSelect = useCallback(
    async (slug: string | null) => {
      resetStates();
      if (!slug) return;
      const store = data?.publicStores.find((s) => s.slug === slug);
      if (!store) return;
      setStates({
        ...stateValues,
        store: {
          id: store.id,
          name: store?.name || "",
        },
      });
      navigate("/services");
    },
    [data],
  );

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("store")) {
        await handleSelect(urlParams.get("store"));
      } else {
        setStates(storeDefaults);
      }
    })();
  }, [data]);

  return (
    <BookingLayout
      progress={20}
      content={responsive({
        desktop: (
          <StoreCards
            selectable
            data={data?.publicStores ?? []}
            loading={loading}
          />
        ),
        mobile: (
          <StoreCards
            selectable={false}
            data={data?.publicStores ?? []}
            loading={loading}
          />
        ),
        tablet: (
          <StoreCards
            selectable={false}
            data={data?.publicStores ?? []}
            loading={loading}
          />
        ),
      })}
      subHeader={<SubHeader title="Wähle einen Standort aus" />}
      card={responsive({
        mobile: null,
        tablet: null,
        desktop: (
          <OverviewCard
            isValidated={stateValues?.store?.id !== undefined}
            tooltipTitle={
              !stateValues?.store?.id
                ? "Wähle deine Filiale aus, um fortzufahren."
                : ""
            }
            onContinue={onContinue}
          />
        ),
      })}
    />
  );
};

export default StoreSelection;
