import { Calendar, Card, message, Tag } from "antd";
import { format, isSameDay, startOfDay } from "date-fns";
import { useState } from "react";
import useBookingState from "../components/bookingProvider/useBooking";
import {
  AppointmentStatus,
  useGetCustomerAppointmentsQuery,
  useGetOwnCustomerQuery,
} from "../graphql/schema";
import AppointmentModal from "../components/appointments/AppointmentModal";

const Appointments = () => {
  const { stateValues } = useBookingState();
  const { data: customer, refetch } = useGetOwnCustomerQuery({
    fetchPolicy: "no-cache",
  });
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any | null>(
    null,
  );
  const { data } = useGetCustomerAppointmentsQuery();

  const confirm = (appointmentId: number) => {
    message.success("Click on Yes");
  };

  return (
    <Card title="Behandlungsübersicht">
      <Calendar
        fullscreen={window.innerWidth > 768}
        cellRender={(date, info) => {
          const appointment = data?.customerAppointments.find(
            (currentAppointment) =>
              isSameDay(
                startOfDay(new Date(currentAppointment.dateFrom)),
                startOfDay(new Date(date.toDate())),
              ),
          );
          if (appointment)
            return window.innerWidth > 768 ? (
              <Tag
                color={
                  appointment.appointmentStatus !== AppointmentStatus.Cancelled
                    ? "blue"
                    : "red"
                }
                onClick={() => {
                  setSelectedAppointment(appointment);
                  setAppointmentModalOpen(true);
                }}
              >
                {format(new Date(appointment.dateFrom), "HH:mm")}{" "}
              </Tag>
            ) : (
              <div />
            );
          return <div />;
        }}
      />
      <AppointmentModal
        open={appointmentModalOpen}
        appointment={selectedAppointment}
        onHide={setAppointmentModalOpen}
        onOk={setAppointmentModalOpen}
      />
    </Card>
  );
};

export default Appointments;
