import React, { useCallback } from "react";
import { Col, Divider, Drawer, message, Modal, Row, Typography } from "antd";
import {
  CalendarOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useCancelAppointmentByCustomerMutation,
  useGetCustomerAppointmentQuery,
} from "../../graphql/schema";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

const { warning } = Modal;

interface Props {
  visible: boolean;
  onClose: () => void;
  id: number;
  storeId: number;
}

const MobileOptionsDrawer = ({ visible, onClose, id, storeId }: Props) => {
  const navigate = useNavigate();
  const { stateValues, setStates } = useBooking();
  const { data: appointment, loading } = useGetCustomerAppointmentQuery({
    variables: {
      id,
    },
  });

  const [cancel] = useCancelAppointmentByCustomerMutation();

  const handleCancel = useCallback(async () => {
    warning({
      type: "warning",
      title: "Termin stornieren",
      content: "Möchten Sie den Termin wirklich stornieren?",
      icon: <QuestionCircleOutlined />,
      okText: "Stornieren",
      okType: "danger",
      closable: true,
      centered: true,
      onOk: async () => {
        try {
          await cancel({
            variables: {
              id,
            },
          });
          message.success("Termin wurde storniert");
          navigate("/");
        } catch (error) {
          message.error("Fehler beim Stornieren des Termins");
        }
      },
    });
  }, [id]);

  const handleReschedule = useCallback(() => {
    setStates({
      ...stateValues,
      bookedProducts:
        appointment?.customerAppointment.products
          ?.filter((product) => !product.package?.id)
          ?.map((product) => ({
            id: product.product?.id || 0,
            name: product.variation?.id
              ? product.variation?.name
              : product.product?.name,
            category: product.product?.productCategory?.name || "",
            baseName: product.product?.name || "",
            variation: product.variation?.id,
            price: product.bookedPrice,
            netPrice: Number(
              Number(product.bookedPrice) /
                (1 + Number(product?.product?.taxRate || 0) / 100),
            ).toFixed(2),
          })) || [],
      storeId: appointment?.customerAppointment.store.id,
      rescheduleId: id,
    });
    navigate(`/calendar`);
  }, [appointment]);

  if (!appointment) return null;
  return (
    <Drawer
      style={{
        borderRadius: "20px 20px 0 0",
      }}
      bodyStyle={{
        height: 200,
      }}
      placement="bottom"
      open={visible}
      closable={false}
      onClose={onClose}
    >
      <Row justify="end">
        <Col>
          <CloseOutlined
            style={{
              fontSize: "20px",
              color: theme.colors.disabled,
            }}
            onClick={onClose}
          />
        </Col>
      </Row>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <Typography.Title
            level={4}
            style={{
              textAlign: "left",
              margin: 0,
            }}
          >
            Termin bearbeiten
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Row
            justify="space-between"
            style={{
              padding: "10px 0",
              cursor: "pointer",
            }}
            onClick={handleReschedule}
          >
            <Col>
              <Typography.Text>
                <CalendarOutlined
                  style={{
                    color: theme.colors.text,
                    fontSize: 22,
                  }}
                />{" "}
                Verschieben
              </Typography.Text>
            </Col>
            <Col>
              <RightOutlined
                style={{
                  color: theme.colors.text,
                  fontSize: 22,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider
            style={{
              margin: 10,
              marginBottom: 10,
              marginTop: 0,
            }}
          />
        </Col>
        <Col span={24}>
          <Row
            justify="space-between"
            onClick={handleCancel}
            style={{
              cursor: "pointer",
            }}
          >
            <Col>
              <Typography.Text>
                <CloseOutlined
                  style={{
                    color: theme.colors.text,
                    fontSize: 22,
                  }}
                />{" "}
                Stornieren
              </Typography.Text>
            </Col>
            <Col>
              <RightOutlined
                style={{
                  color: theme.colors.text,
                  fontSize: 22,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider
            style={{
              margin: 10,
            }}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default MobileOptionsDrawer;
