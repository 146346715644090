import React, { createRef, useEffect, useMemo, useRef } from "react";
import { Col, Row } from "antd";
import ProductList from "./ProductList";
import { ProductCategoryOutput, ProductOutput } from "../../graphql/schema";

interface Props {
  productCategories: Partial<ProductCategoryOutput>[];
  products: Partial<ProductOutput>[];
  categoryId: number | null;
  searchValue: string;
}

const CategoryList = ({
  productCategories,
  products,
  categoryId,
  searchValue,
}: Props) => {
  const scrollableRef = useRef<HTMLDivElement>(null);

  // Memoize categoryRefs to prevent re-creation on every render
  const categoryRefs = useMemo(() => {
    return productCategories.reduce(
      (refs, category) => {
        if (category.id !== undefined) {
          refs[category.id] = createRef<HTMLDivElement>();
        }
        return refs;
      },
      {} as Record<number, React.RefObject<HTMLDivElement>>,
    );
  }, [productCategories]);

  useEffect(() => {
    if (
      categoryId &&
      categoryRefs[categoryId]?.current &&
      scrollableRef.current
    ) {
      const categoryElement = categoryRefs[categoryId].current;
      const scrollableElement = scrollableRef.current;
      const topOffset = categoryElement.offsetTop - scrollableElement.offsetTop;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  }, [categoryId, categoryRefs]);

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Row
        justify="start"
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <Col span={24}>
          <div
            ref={scrollableRef}
            style={{
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {productCategories.map((category) => (
              <div
                key={category.id}
                ref={categoryRefs[category.id]}
                style={{
                  marginBottom: 20,
                }}
              >
                <ProductList
                  category={category}
                  products={products.filter(
                    (product) =>
                      category.id &&
                      product.productCategory?.id === category.id,
                  )}
                  searchValue={searchValue}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(CategoryList);
