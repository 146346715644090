import { Button, Col, message, Row, Typography } from "antd";
import { addMinutes } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ics } from "calendar-link";
// @ts-ignore
import { de } from "date-fns/locale";
import dayjs from "dayjs";
import { useGetPublicStoreQuery } from "../../graphql/schema";
import getProductByBookedProduct from "../../helper/common/getProductByBookedProduct";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

interface Props {
  open: boolean;
}

const BookingConfirmationMobile = () => {
  const { stateValues, setStates } = useBooking();
  const navigate = useNavigate();

  const handleHome = () => {
    setStates({
      ...stateValues,
      appointment: undefined,
      bookedProducts: [],
      couponCodes: [],
      notifyForEarlierAppointment: false,
      store: {
        id: undefined,
        name: "",
      },
      additionalCustomers: [],
    });
    navigate("/");
  };

  const { data: store } = useGetPublicStoreQuery({
    variables: {
      id: stateValues.store.id!,
    },
    skip: !stateValues.store.id,
  });

  const getAppointmentsTotalDuration = () => {
    let totalDuration = 0;
    if (!stateValues.bookedProducts) return totalDuration;
    if (!store?.publicStore.products) return totalDuration;
    stateValues.bookedProducts.forEach((p: any) => {
      const product = getProductByBookedProduct(p, store?.publicStore.products);
      totalDuration += product?.duration ? product.duration : 0;
    });
    return totalDuration;
  };

  const handleAddToCalendar = () => {
    if (!stateValues.appointment) return;
    const startTime = new Date(stateValues.appointment);
    const endTime = addMinutes(
      new Date(stateValues.appointment),
      getAppointmentsTotalDuration(),
    );
    const event = {
      title: "Kalia lab Termin",
      description: "Wir freuen uns auf Ihren Besuch!",
      start: startTime,
      end: endTime,
    };
    try {
      window.open(ics(event));
    } catch (e) {
      message.error(
        "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
      );
    }
  };

  return (
    <Row
      align="top"
      justify="center"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Col span={20}>
        <Row
          style={{
            paddingTop: "50%",
            height: "50%",
          }}
        >
          <Col span={24}>
            <Row justify="center" align="middle">
              <Col span={24}>
                <Typography.Title level={5} style={{ textAlign: "center" }}>
                  Danke für deine Buchung!
                </Typography.Title>
              </Col>
            </Row>
            <Row
              align="middle"
              justify="center"
              style={{
                marginTop: "20px",
              }}
            >
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <Typography.Text style={{ textAlign: "center" }}>
                  Dein Termin ist geplant für{" "}
                  <Typography.Text strong>
                    {stateValues.appointment
                      ? dayjs.tz(stateValues.appointment).format("ddd, D. MMM")
                      : ""}{" "}
                    um{" "}
                    {stateValues.appointment
                      ? dayjs.tz(stateValues.appointment).format("HH:mm")
                      : ""}{" "}
                    Uhr.
                  </Typography.Text>
                </Typography.Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                <Typography.Text
                  style={{
                    textAlign: "center",
                  }}
                >
                  In Kürz erhälst Du eine Bestätigung per E-Mail. Sollte diese
                  in deinem Postfach erscheinen, überprüfe bitte Deinen
                  Spam-Ordner.
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Button
                  style={{
                    width: "100%",
                    marginTop: 30,
                    borderRadius: "15px",
                    height: "49px",
                    backgroundColor: theme.colors.button,
                  }}
                  onClick={() => handleAddToCalendar()}
                >
                  <Typography.Text
                    strong
                    style={{
                      color: "white",
                    }}
                  >
                    Zum Kalender hinzufügen
                  </Typography.Text>
                </Button>
              </Col>
              <Col span={24}>
                <Button
                  type="text"
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    height: "40px",
                  }}
                  onClick={() => {
                    setStates({
                      appointment: undefined,
                      bookedProducts: [],
                      couponCodes: [],
                      notifyForEarlierAppointment: false,
                    });
                    navigate("/");
                  }}
                >
                  <Typography.Text
                    strong
                    style={{
                      color: theme.colors.text,
                    }}
                  >
                    Zurück zum Start
                  </Typography.Text>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default BookingConfirmationMobile;
