import { Product } from "../../graphql/schema";
import { ProductWithVariation } from "../../components/bookingProvider/BookingContext";

interface Props {
  products: Partial<Product>[];
  bookedProducts: ProductWithVariation[];
}

const getTotalDuration = ({ products, bookedProducts }: Props): number => {
  let duration: number = 0;
  bookedProducts?.forEach((productWithVariation: ProductWithVariation) => {
    const productsDuration = productWithVariation.variation
      ? products
          ?.find((p) => p?.id === productWithVariation.id)
          ?.variations?.find(
            (variation: any) => variation.id === productWithVariation.variation,
          )?.totalDuration
      : products?.find((p) => productWithVariation.id === p?.id)?.totalDuration;
    if (productsDuration) {
      duration += Number(productsDuration);
    }
  });
  return duration;
};

export default getTotalDuration;
