import React, { useEffect } from "react";
import { Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import { useLayout } from "../components/layout/LayoutContext";
import useCustomerAuth from "../customerAuth/useCustomerAuth";
import DesktopAppointment from "../components/appointment/DesktopAppointment";
import MobileAppointment from "../components/appointment/MobileAppointment";

const Appointment = () => {
  const { responsive } = useLayout();
  const { updateTokens, isAuthenticated } = useCustomerAuth();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token")) updateTokens(searchParams.get("token")!);
  }, []);

  const id = searchParams.get("id");
  return isAuthenticated && id ? (
    responsive({
      desktop: <DesktopAppointment id={Number(id)} />,
      mobile: <MobileAppointment id={Number(id)} />,
      tablet: <DesktopAppointment id={Number(id)} />,
    })
  ) : (
    <Spin />
  );
};

export default Appointment;
