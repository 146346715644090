import React, { useEffect, useMemo } from "react";
import { Col, List, Row, Typography } from "antd";
import { StarOutlined } from "@ant-design/icons";
import { Layout, useLayout } from "../layout/LayoutContext";
import useBooking from "../bookingProvider/useBooking";
import { useGetPublicStoreQuery } from "../../graphql/schema";
import OverviewProductCard from "./OverviewProductCard";
import getUniqueBookedProducts from "../../helper/common/getUniqueBookedProducts";
import theme from "../../theme/theme";
import "./OverviewProductList.scss";

const OverviewProductList = () => {
  const { responsive, device } = useLayout();
  const { stateValues } = useBooking();
  const listRef = React.useRef<HTMLDivElement>(null);

  const { data: store } = useGetPublicStoreQuery({
    variables: {
      id: stateValues.store.id!,
    },
    fetchPolicy: "no-cache",
    skip: !stateValues.store.id,
  });

  const bookedProducts = useMemo(() => {
    return getUniqueBookedProducts(stateValues.bookedProducts);
  }, [stateValues]);

  useEffect(() => {
    if (listRef.current)
      listRef.current.scrollTo(0, listRef.current.scrollHeight);
  }, [bookedProducts]);

  return store && bookedProducts?.length > 0 ? (
    <div
      style={{
        width: "100%",
        height: device === Layout.MOBILE ? "100%" : 250,
        overflowY: bookedProducts?.length > 2 ? "scroll" : "hidden",
        overflowX: "hidden",
      }}
      ref={listRef}
    >
      <List
        style={{
          minHeight: "101%",
          paddingBottom: 50,
        }}
        grid={{
          column: responsive({
            desktop: 1,
            tablet: 1,
            mobile: 1,
          }),
          gutter: 20,
        }}
        dataSource={bookedProducts}
        renderItem={(product) => (
          <OverviewProductCard
            bookedProduct={product}
            products={store?.publicStore?.products || []}
          />
        )}
      />
    </div>
  ) : (
    <Row align="middle" justify="center">
      <Col span={1}>
        <Row>
          <Col span={24}>
            <StarOutlined
              size={40}
              style={{
                fontSize: 22,
                color: theme.colors.text,
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={23}>
        <Row justify="center" align="middle">
          <Col
            span={24}
            style={{
              paddingLeft: 15,
            }}
          >
            <Typography.Text
              style={{
                justifyContent: "center",
                color: theme.colors.text,
                fontSize: 16,
                lineHeight: "19.2px",
                fontWeight: 400,
              }}
            >
              Nicht ausgewählt
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OverviewProductList;
