import { ProductWithVariation } from "../../components/bookingProvider/BookingContext";

const getGTMCart = (stateValues: any) => {
  const uniqueProductsMap = new Map();

  stateValues.bookedProducts.forEach((product: ProductWithVariation) => {
    const key = `${product.id}-${product.variation}`;
    if (!uniqueProductsMap.has(key)) {
      uniqueProductsMap.set(key, {
        ...product,
        quantity: 1,
      });
    } else {
      uniqueProductsMap.set(key, {
        ...uniqueProductsMap.get(key),
        price: Number(
          Number(uniqueProductsMap.get(key).price) + Number(product.price),
        ).toFixed(2),
        netPrice: Number(
          Number(uniqueProductsMap.get(key).netPrice) +
            Number(product.netPrice),
        ).toFixed(2),
        quantity: uniqueProductsMap.get(key).quantity + 1,
      });
    }
  });
  return Array.from(uniqueProductsMap.values()).map((product) => {
    return {
      product_id: product.id,
      product_name: product.baseName || "",
      variant_id: product.variation || null,
      variant_name: product.variation ? product.name : null,
      category: product.category,
      price_gross: Number(product.price),
      price_net: Number(product.netPrice),
      quantity: product.quantity,
    };
  });
};

export default getGTMCart;
