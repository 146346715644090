import React from "react";
import { Flex, List, Typography } from "antd";

interface Props {
  index: number;
  currentDate: any;
  setSelectedDate: any;
  isBeforeToday: boolean;
  itemSize: number;
  itemMarginRight: number;
  backgroundColor: string;
  color: string;
}

const CalendarMobileListItem = ({
  index,
  currentDate,
  setSelectedDate,
  isBeforeToday,
  itemSize,
  itemMarginRight,
  backgroundColor,
  color,
}: Props) => {
  return (
    <List.Item
      key={index}
      style={{
        display: "inline-block",
        minWidth: itemSize,
        maxWidth: itemSize,
        minHeight: itemSize,
        maxHeight: itemSize,
        padding: 5,
        paddingTop: 8,
        backgroundColor,
        borderRadius: "15px",
        marginRight: itemMarginRight,
      }}
      onClick={() => {
        if (isBeforeToday) return;

        setSelectedDate(currentDate.set("hours", 12).toDate());
      }}
    >
      <Flex vertical justify="space-between" align="center">
        <Typography.Text
          style={{
            color,
          }}
        >
          {currentDate.format("dd")}
        </Typography.Text>
        <Typography.Text
          strong
          style={{
            color,
          }}
        >
          {currentDate.date()}
        </Typography.Text>
      </Flex>
    </List.Item>
  );
};

export default CalendarMobileListItem;
