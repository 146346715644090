import React from "react";
import { CustomerType } from "../../graphql/schema";

export interface ProductWithVariation {
  id: number;
  name: string;
  baseName?: string | null;
  variation?: number | null | undefined;
  price: string | null | undefined;
  netPrice?: string | null | undefined;
  category: string;
}

export interface BookingInterface {
  store: {
    id: number | undefined;
    name: string;
  };
  rescheduleId?: number | null;
  bookedProducts: ProductWithVariation[];
  couponCodes: string[];
  notifyForEarlierAppointment: boolean;
  appointment: Date | null;
  customer: {
    id: number | undefined;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password?: string;
    category: CustomerType;
  };
  preferredPaymentMethod: string | null;
  additionalCustomers: {
    id: number | undefined;
    firstName: string;
    lastName: string;
    category: CustomerType;
  }[];
  message: string;
}

interface BookingContextProps {
  stateValues: BookingInterface;
  setStates: (stateValues: Partial<BookingInterface>) => void;
  getStates: (keys: (keyof BookingInterface)[]) => Partial<BookingInterface>;
  resetStates: () => void;
  clearCustomer: () => void;
  refetchCustomer: () => void;
}

const BookingContext = React.createContext<BookingContextProps>({
  stateValues: {
    store: {
      id: undefined,
      name: "",
    },
    bookedProducts: [],
    couponCodes: [],
    notifyForEarlierAppointment: false,
    appointment: null,
    customer: {
      id: undefined,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      category: CustomerType.Standard,
    },
    preferredPaymentMethod: null,
    additionalCustomers: [],
    message: "",
  },
  setStates: (stateValues: Partial<BookingInterface>): void => {},
  getStates: (keys: (keyof BookingInterface)[]): Partial<BookingInterface> => {
    return {};
  },
  resetStates: (): void => {},
  clearCustomer: (): void => {},
  refetchCustomer: (): void => {},
});

export default BookingContext;
