import React from "react";
import { Button, Col, Drawer, Row, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import getPriceForCustomer from "../../helper/common/getPriceForCustomer";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

interface Props {
  open: boolean;
  name: string;
  totalDuration: number;
  prices: any;
  add: () => void;
  onClose: () => void;
  description?: string;
}

const CardDrawer = ({
  open,
  name,
  totalDuration,
  prices,
  add,
  onClose,
  description,
}: Props) => {
  const { stateValues } = useBooking();
  return (
    <Drawer
      placement="bottom"
      open={open}
      style={{
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
      }}
      footer={
        <Row
          align="bottom"
          justify="center"
          style={{
            paddingBottom: 10,
          }}
        >
          <Col span={24}>
            <Button
              style={{
                backgroundColor: theme.colors.button,
                width: "100%",
                height: "49px",
                bottom: "0px",
                borderRadius: "15px",
              }}
              onClick={() => {
                add();
                onClose();
              }}
            >
              <Typography.Title
                level={5}
                style={{
                  color: "white",
                  marginBottom: "0px",
                }}
              >
                Hinzufügen
              </Typography.Title>
            </Button>
          </Col>
        </Row>
      }
      closeIcon={null}
    >
      <Row justify="end">
        <Col>
          <CloseOutlined
            style={{
              fontSize: "20px",
              color: theme.colors.disabled,
            }}
            onClick={onClose}
          />
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Typography.Title level={5}>{name}</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>
            {totalDuration} Min.{" "}
            {getPriceForCustomer(prices, stateValues.customer)
              .toFixed(2)
              .replace(".", ",")}{" "}
            €
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text>{description || ""}</Typography.Text>
        </Col>
      </Row>
    </Drawer>
  );
};

export default CardDrawer;
