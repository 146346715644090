import { useEffect } from "react";
import { useLayout } from "../../components/layout/LayoutContext";
import BookingConfirmationDesktop from "../../components/confirmation/BookingConfirmationDesktop";
import BookingConfirmationMobile from "../../components/confirmation/BookingConfirmationMobile";

const BookingConfirmation = () => {
  const { responsive } = useLayout();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return responsive({
    mobile: <BookingConfirmationMobile />,
    tablet: <BookingConfirmationDesktop />,
    desktop: <BookingConfirmationDesktop />,
  });
};

export default BookingConfirmation;
