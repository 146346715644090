import React from "react";
import { Button, Col, Row } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import theme from "../../theme/theme";

interface Props {
  add: () => void;
  remove: () => void;
  amount: number;
}

const OverviewCardButtons = ({ add, remove, amount }: Props) => {
  return (
    <Row align="middle" justify="end">
      <Col>
        <div
          style={{
            display: "flex",
            minWidth: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              borderWidth: 0,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 0,
              backgroundColor: "#fff",
            }}
            onClick={(event) => {
              event.stopPropagation();
              remove();
            }}
          >
            <MinusOutlined
              style={{
                fontSize: 14,
                color: theme.colors.text,
              }}
            />
          </Button>
          <Button
            disabled
            style={{
              color: theme.colors.text,
              borderWidth: 0,
              borderRadius: 0,
              backgroundColor: "#fff",
            }}
          >
            {amount}
          </Button>
          <Button
            style={{
              borderWidth: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 0,
              backgroundColor: "#fff",
            }}
            onClick={(event) => {
              event.stopPropagation();
              add();
            }}
          >
            <PlusOutlined
              style={{
                fontSize: 14,
                color: theme.colors.text,
              }}
            />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default OverviewCardButtons;
