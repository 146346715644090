import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  TimePicker,
  Typography,
} from "antd";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  AppointmentStatus,
  CustomerAppointmentsOutput,
  useCancelAppointmentByCustomerMutation,
} from "../../graphql/schema";
import formItemProps from "../../helper/form/formItemProps";
import useBooking from "../bookingProvider/useBooking";

interface Props {
  open: boolean;
  appointment: CustomerAppointmentsOutput | null;
  onHide: (open: boolean) => void;
  onOk: (open: boolean) => void;
}

const AppointmentModal = ({ open, appointment, onHide, onOk }: Props) => {
  const { stateValues } = useBooking();
  const [form] = Form.useForm();

  const [cancelAppointment] = useCancelAppointmentByCustomerMutation();

  const handleCancelAppointment = async () => {
    if (!appointment) return;
    try {
      await cancelAppointment({
        variables: {
          id: appointment.id,
        },
      });
      message.success("Termin erfolgreich abgesagt");
      onHide(false);
    } catch (error) {
      message.error("Termin konnte nicht abgesagt werden");
    }
  };

  if (!appointment) return null;
  return (
    <Modal open={open} onOk={() => onOk(false)} onCancel={() => onHide(false)}>
      <Row>
        <Col>
          <Typography.Title level={3}>
            {`Termin am ${format(
              new Date(appointment.dateFrom),
              "dd.MM.yyyy",
            )}`}
          </Typography.Title>
        </Col>
      </Row>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          dateFrom: appointment?.dateFrom
            ? dayjs(appointment.dateFrom)
            : undefined,
          dateUntil: appointment?.dateUntil
            ? dayjs(appointment.dateUntil)
            : undefined,
        }}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Termin" key="1">
            <Space size="small" direction="vertical">
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item {...formItemProps("dateFrom", "Beginn")}>
                    <TimePicker disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...formItemProps("dateUntil", "Ende")}>
                    <TimePicker disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Typography.Text strong>
                        Gebuchte Leistungen
                      </Typography.Text>
                    </Col>
                  </Row>
                  {appointment.products?.map((product) => (
                    <Row key={`product_${product.id}`}>
                      <Col>
                        {`${product.amount}x ${
                          product.variation
                            ? `${product.variation.name}`
                            : product.product?.name
                        }`}
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Typography.Text strong>Preis</Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Typography.Text>
                        {appointment.bookedPrice} €
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Absagen" key="3">
            <Space size="small" direction="vertical">
              <Row>
                <Col>
                  <Typography.Text>
                    {appointment.appointmentStatus ===
                    AppointmentStatus.Cancelled
                      ? "Der Termin wurde bereits abgesagt."
                      : "Möchten Sie den Termin wirklich absagen?"}
                  </Typography.Text>
                </Col>
              </Row>
              {appointment.appointmentStatus !==
                AppointmentStatus.Cancelled && (
                <Row>
                  <Col>
                    <Button onClick={() => handleCancelAppointment()}>
                      <Typography.Text>Termin absagen</Typography.Text>
                    </Button>
                  </Col>
                </Row>
              )}
            </Space>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default AppointmentModal;
