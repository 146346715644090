import theme from "../../theme/theme";

interface Props {
  percent: 20 | 40 | 60 | 80 | 100;
}

const ProgressElement = ({ id, show }: any) => {
  return (
    <div
      key={id}
      style={{
        borderRadius: "5px",
        width: "20px",
        margin: "0 10px",
        height: "5px",
        backgroundColor: show ? theme.colors.button : "#D9D9D9",
      }}
    />
  );
};

const ProgressBar = ({ percent }: Props) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
    >
      {Array.from(Array(5).keys()).map((index) => (
        <ProgressElement
          key={index}
          id={`ProgressElement_${index}`}
          show={(index + 1) * 20 <= percent}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
