import React, { useCallback, useEffect, useMemo } from "react";
import { Button, Checkbox, Col, Form, List, Row, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";
import CalendarMobileListItem from "./CalendarMobileListItem";
import { BookingInterface } from "../bookingProvider/BookingContext";

interface Props {
  dates: dayjs.Dayjs[];
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date | undefined) => void;
  vacanciesSearchDate: {
    start: Date;
    end: Date;
  };
  setVacanciesSearchDate: (date: Date) => void;
  onChangeDates: (start: Date, end: Date) => void;
}

const CalendarMobile: React.FC<Props> = ({
  dates = [],
  selectedDate,
  onChangeDates,
  setSelectedDate,
  vacanciesSearchDate,
}) => {
  const { setStates } = useBooking();
  const [form] = Form.useForm();

  const firstDayOfMonth = dayjs
    .tz(vacanciesSearchDate.start || new Date())
    .startOf("month");

  const handleFormChange = useCallback(
    (changedValues: any, allValues: any) => {
      setStates((prevState: Partial<BookingInterface>) => ({
        ...prevState,
        notifyForEarlierAppointment: allValues.notifyForEarlierAppointment,
      }));
    },
    [setStates],
  );

  const itemSize = 60;
  const itemMarginRight = 16;

  useEffect(() => {
    const list = window.document.getElementById("calendar-list");
    if (!list) return;

    const displayedMonth = dayjs.tz(vacanciesSearchDate.start);
    const currentMonth = dayjs.tz();

    const isCurrentMonth = displayedMonth.isSame(currentMonth, "month");

    if (isCurrentMonth) {
      // Scroll to current day
      list.scrollLeft =
        (currentMonth.date() - 1) * (itemSize + itemMarginRight);
    } else {
      // Start at beginning
      list.scrollLeft = 0;
    }
  }, [vacanciesSearchDate.start, itemMarginRight, itemSize]);

  const daysInMonth = firstDayOfMonth.daysInMonth();

  const dataSource = useMemo(() => {
    return Array.from({ length: daysInMonth }, (_, index) => {
      return firstDayOfMonth.add(index, "day");
    });
  }, [firstDayOfMonth, daysInMonth]);

  const dateSet = useMemo(() => {
    return new Set(dates.map((date) => date.format("YYYY-MM-DD")));
  }, [dates]);

  const renderItem = useCallback(
    (currentDate: dayjs.Dayjs, index: number) => {
      const isBeforeToday = currentDate.isBefore(dayjs.tz(), "day");
      const isSelected =
        selectedDate && currentDate.isSame(dayjs.tz(selectedDate), "day");
      const dateStr = currentDate.format("YYYY-MM-DD");
      const hasSlots = dateSet.has(dateStr);

      let backgroundColor = "#F6F6F4";
      let color = "#000";

      if (isSelected) {
        backgroundColor = "#8B8B8B";
        color = "#fff";
      } else if (isBeforeToday) {
        backgroundColor = "#F8F8F8";
      } else if (hasSlots) {
        backgroundColor = "#B5DE82";
      }

      return (
        <CalendarMobileListItem
          key={index}
          index={index}
          currentDate={currentDate}
          setSelectedDate={setSelectedDate}
          isBeforeToday={isBeforeToday}
          itemSize={itemSize}
          itemMarginRight={itemMarginRight}
          backgroundColor={backgroundColor}
          color={color}
        />
      );
    },
    [dateSet, selectedDate, setSelectedDate, itemSize, itemMarginRight],
  );

  return (
    <div>
      <Row
        style={{
          marginTop: 10,
        }}
      >
        <Col span={24}>
          <Form form={form} onValuesChange={handleFormChange}>
            <Form.Item
              name="notifyForEarlierAppointment"
              valuePropName="checked"
            >
              <Checkbox>
                Ich möchte benachrichtigt werden, falls ein früherer Termin
                verfügbar ist
              </Checkbox>
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Row align="middle" justify="center">
            <Col>
              <Button
                disabled={firstDayOfMonth.isSameOrBefore(
                  dayjs.tz().startOf("month"),
                )}
                type="text"
                icon={<LeftOutlined style={{ color: theme.colors.button }} />}
                onClick={() =>
                  onChangeDates(
                    dayjs(vacanciesSearchDate.start)
                      .subtract(1, "month")
                      .toDate(),
                    dayjs(vacanciesSearchDate.end)
                      .subtract(1, "month")
                      .toDate(),
                  )
                }
              />
            </Col>
            <Col>
              <Typography.Text
                strong
                style={{
                  fontSize: 16,
                  margin: "0 10px",
                }}
              >
                {firstDayOfMonth.format("MMM YYYY")}
              </Typography.Text>
            </Col>
            <Col>
              <Button
                type="text"
                icon={<RightOutlined style={{ color: theme.colors.button }} />}
                onClick={() =>
                  onChangeDates(
                    dayjs(vacanciesSearchDate.start).add(1, "month").toDate(),
                    dayjs(vacanciesSearchDate.end).add(1, "month").toDate(),
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <List
            id="calendar-list"
            style={{
              width: "100%",
              overflowX: "auto",
              padding: "10px",
              scrollbarWidth: "none",
              whiteSpace: "nowrap",
            }}
            dataSource={dataSource}
            renderItem={(item, index) => renderItem(item, index)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(CalendarMobile);
