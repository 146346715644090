import React from "react";
import { Header } from "antd/lib/layout/layout";
import { Button, Layout, Typography } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useBooking from "../bookingProvider/useBooking";
import { useLogin } from "../login/LoginContext";
import theme from "../../theme/theme";
import Footer from "./Footer";

const BaseLayout = () => {
  const { openLogin } = useLogin();
  const location = useLocation();
  const { logout, isAuthenticated } = useCustomerAuth();
  const { stateValues, clearCustomer } = useBooking();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    clearCustomer();
    if (location.pathname === "/") window.location.reload();
    navigate("/");
  };

  return (
    <Layout
      style={{
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100vh",
        display: "flex",
      }}
    >
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: theme.colors.text,
          height: 80,
          paddingLeft: 20,
          paddingRight: 20,
          justifyContent: "space-between",
        }}
      >
        <Logo collapsed={window.innerWidth < 700} type="light" />
        {stateValues.customer.id && isAuthenticated ? (
          <Button
            onClick={() => handleLogout()}
            type="text"
            style={{
              boxShadow: "none",
              color: theme.colors.background,
            }}
          >
            <Typography.Text
              style={{
                color: theme.colors.background,
              }}
            >
              Abmelden
            </Typography.Text>
          </Button>
        ) : (
          <Button
            onClick={openLogin}
            type="text"
            style={{
              boxShadow: "none",
              color: theme.colors.background,
            }}
          >
            <Typography.Text
              style={{
                color: theme.colors.background,
              }}
            >
              Anmelden
            </Typography.Text>
          </Button>
        )}
      </Header>
      <Outlet />
      <Footer />
    </Layout>
  );
};

export default BaseLayout;
