import { ProductWithVariation } from "../../components/bookingProvider/BookingContext";
import getPriceForCustomer from "./getPriceForCustomer";
import findProduct from "./findProduct";
import findVariation from "./findVariation";

const getRegularCartSum = (
  bookedProducts: ProductWithVariation[],
  products: any,
  customer: any,
): number => {
  let sum: number = 0;
  bookedProducts.forEach((bookedProduct) => {
    const product = findProduct({ bookedProduct, products });
    if (product && bookedProduct.variation) {
      const variation = findVariation({
        product,
        variationId: bookedProduct.variation,
      });
      if (variation) {
        sum += getPriceForCustomer(variation.prices, customer);
      }
    } else if (product) {
      sum += getPriceForCustomer(product.prices, customer);
    }
  });
  return sum;
};

export default getRegularCartSum;
