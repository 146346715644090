import { ProductWithVariation } from "../../components/bookingProvider/BookingContext";

interface Props {
  bookedProduct: ProductWithVariation;
  products: any[];
}

const findProduct = ({ bookedProduct, products }: Props) => {
  return products.find((p: any) => {
    return bookedProduct.variation
      ? p.id === bookedProduct.id &&
          p.variations.find(
            (variation: any) => variation.id === bookedProduct.variation,
          )
      : p.id === bookedProduct.id && !bookedProduct.variation;
  });
};

export default findProduct;
