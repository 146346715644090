import React, { useMemo } from "react";
import { Col, Row, Typography } from "antd";
import getCartSum from "../../helper/common/getCartSum";
import getTotalDuration from "../../helper/common/getTotalDuration";
import useBooking from "../bookingProvider/useBooking";
import { ProductOutput } from "../../graphql/schema";
import { useLayout } from "../layout/LayoutContext";

interface Props {
  products: Partial<ProductOutput>[];
}

const ProductsBadgeContent = ({ products }: Props) => {
  const { responsive } = useLayout();
  const { stateValues } = useBooking();
  const sum = useMemo(
    () =>
      getCartSum({ bookedProducts: stateValues.bookedProducts })
        .toFixed(2)
        .replace(".", ","),
    [stateValues.bookedProducts],
  );

  const duration = useMemo(
    () =>
      getTotalDuration({
        products,
        bookedProducts: stateValues.bookedProducts,
      }),
    [stateValues.bookedProducts],
  );

  const amount = stateValues.bookedProducts.length;
  return (
    <Col>
      <Row>
        <Col span={24}>
          <Typography.Text strong>{sum} €</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Text>
            {amount} {amount > 1 ? "Behandlungen" : "Behandlung"};
            <br />
            <Typography.Text>{duration} Min.</Typography.Text>
          </Typography.Text>
        </Col>
      </Row>
    </Col>
  );
};

export default ProductsBadgeContent;
