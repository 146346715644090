import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-light-svg-icons";
import { NotificationPlacement } from "antd/lib/notification/interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formItemProps from "../helper/form/formItemProps";
import { useResetCustomerPasswordMutation } from "../graphql/schema";
import LoginModal from "../components/login/LoginModal";
import useCustomerAuth from "../customerAuth/useCustomerAuth";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const { isAuthenticated } = useCustomerAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [reset] = useResetCustomerPasswordMutation();

  useEffect(() => {
    if (isAuthenticated) {
      setOpen(false);
      navigate("/");
    }
  }, []);
  const openNotification = (
    type: "success" | "info" | "warning" | "error",
    message: string,
    description: string,
    placement: NotificationPlacement,
  ) => {
    if (type === "success")
      api.success({
        message,
        description,
        placement,
      });
    if (type === "info")
      api.info({
        message,
        description,
        placement,
      });
    if (type === "warning")
      api.warning({
        message,
        description,
        placement,
      });
    if (type === "error")
      api.error({
        message,
        description,
        placement,
      });
  };

  const handleResetPassword = async () => {
    if (!form.getFieldValue("email") && !form.getFieldValue("phone")) {
      openNotification(
        "info",
        "Fehler",
        "Bitte gib deine E-Mail oder deine Mobilnummer ein.",
        "top",
      );
      return;
    }
    try {
      await reset({
        variables: {
          dto: {
            email: form.getFieldValue("email"),
            phone: form.getFieldValue("phone"),
          },
        },
      });
      openNotification(
        "success",
        "Erfolgreich",
        "Bitte überprüfe deine E-Mails / deine Nachrichten.",
        "top",
      );
    } catch (e) {
      openNotification("error", "Fehler", "Etwas ist schief gelaufen.", "top");
    }
  };
  return (
    <Card style={{ width: "100%", height: "100%" }}>
      {contextHolder}
      <Row
        align="middle"
        justify="center"
        style={{ height: "100%", marginTop: "100px" }}
      >
        <Col>
          <Card title="Password vergessen?">
            <Space direction="vertical" size="large">
              <Form
                form={form}
                layout="inline"
                onFinish={() => handleResetPassword()}
              >
                <Tabs>
                  <Tabs.TabPane tab="E-Mail" key="1">
                    <Space direction="vertical" size="large">
                      <Row>
                        <Col>
                          <Form.Item
                            {...formItemProps("email", "", false)}
                            rules={[
                              {
                                required: false,
                                message: "Bitte gib deine E-Mail ein.",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: "100%" }}
                              prefix={<FontAwesomeIcon icon={faEnvelope} />}
                              placeholder="E-Mail"
                              autoFocus
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button style={{ width: "100%" }} htmlType="submit">
                            Abschicken
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography.Text>
                            Du hast schon einen Account?
                          </Typography.Text>
                          <Typography.Link onClick={() => setOpen(true)}>
                            {" "}
                            Anmelden
                          </Typography.Link>
                        </Col>
                      </Row>
                    </Space>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Mobilnummer" key="2">
                    <Space direction="vertical" size="large">
                      <Row>
                        <Col>
                          <Form.Item
                            {...formItemProps("phone", "", false)}
                            rules={[
                              {
                                required: false,
                                message: "Bitte gib deine Mobilnummer an.",
                              },
                            ]}
                          >
                            <Input
                              prefix={<FontAwesomeIcon icon={faPhone} />}
                              placeholder="Mobilnummer"
                              autoFocus
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button style={{ width: "100%" }} htmlType="submit">
                            Abschicken
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography.Text>
                            Du hast schon einen Account?
                          </Typography.Text>
                          <Typography.Link onClick={() => setOpen(true)}>
                            {" "}
                            Anmelden
                          </Typography.Link>
                        </Col>
                      </Row>
                    </Space>
                  </Tabs.TabPane>
                </Tabs>
              </Form>
            </Space>
          </Card>
        </Col>
      </Row>
      <LoginModal
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
      />
    </Card>
  );
};

export default ForgotPassword;
