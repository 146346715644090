const colors = {
  header: "#561C2C",
  button: "#FF6439",
  text: "#561C2C",
  icon: "#561C2C",
  background: "#ffffff",
  logo: "#561C2C",
  secondary: "#561C2C",
  disabled: "#A9A5A5",
  footer: "#FF6439",
};

const typography = {
  fontFamily: "Roboto, sans-serif",
  fontSize: 16,
  fontWeight: "normal",
};

const font = {
  h1: "Domain Display Condensed Medium",
  text: "Neuzeit Grotesk Regular",
};

export default {
  colors,
};
