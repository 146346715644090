import React, { useCallback, useEffect } from "react";
import { Button, Card, Col, message, Modal, Row, Typography } from "antd";
import {
  CalendarOutlined,
  CheckOutlined,
  EnvironmentOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  AppointmentStatus,
  ResourcePresenceType,
  useCancelAppointmentByCustomerMutation,
  useGetCustomerAppointmentQuery,
} from "../../graphql/schema";
import { useLayout } from "../layout/LayoutContext";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

const { warning } = Modal;

const AppointmentTitle = ({ status }: { status: AppointmentStatus }) => {
  return (
    <Typography.Title
      level={3}
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        margin: 0,
      }}
    >
      {status === AppointmentStatus.New ? (
        <>
          <QuestionCircleOutlined style={{ color: theme.colors.header }} />{" "}
          Terminanfrage
        </>
      ) : (
        <>
          <CheckOutlined style={{ color: theme.colors.header }} />
          Termin bestätigt
        </>
      )}
    </Typography.Title>
  );
};

interface Props {
  id: number;
}

const DesktopAppointment = ({ id }: Props) => {
  const navigate = useNavigate();
  const { responsive } = useLayout();
  const { setStates, stateValues } = useBooking();
  const [cancel] = useCancelAppointmentByCustomerMutation();
  const { data: appointment, loading } = useGetCustomerAppointmentQuery({
    variables: {
      id,
    },
  });

  const handleCancel = useCallback(async () => {
    warning({
      type: "warning",
      title: "Termin stornieren",
      content: "Möchten Sie den Termin wirklich stornieren?",
      icon: <QuestionCircleOutlined />,
      okText: "Stornieren",
      closable: true,
      centered: true,
      okType: "danger",
      onOk: async () => {
        try {
          await cancel({
            variables: {
              id,
            },
          });
          message.success("Termin wurde storniert");
          navigate("/");
        } catch (error) {
          message.error("Fehler beim Stornieren des Termins");
        }
      },
    });
  }, [id, appointment]);

  const handleReschedule = useCallback(() => {
    setStates({
      ...stateValues,
      bookedProducts:
        appointment?.customerAppointment.products
          ?.filter((product) => !product.package?.id)
          ?.map((product) => ({
            id: product.product?.id || 0,
            name: product.variation?.id
              ? product.variation?.name
              : product.product?.name,
            category: product.product?.productCategory?.name || "",
            baseName: product.product?.name || "",
            variation: product.variation?.id,
            price: product.bookedPrice,
            netPrice: Number(
              Number(product.bookedPrice) /
                (1 + Number(product?.product?.taxRate || 0) / 100),
            ).toFixed(2),
          })) || [],
      store: {
        id: appointment?.customerAppointment.store?.id || 0,
        name: appointment?.customerAppointment.store?.name || "",
      },
      rescheduleId: id,
    });
    navigate(`/calendar`);
  }, [appointment]);

  useEffect(() => {
    const status = appointment?.customerAppointment.appointmentStatus;
    const isValid =
      status === AppointmentStatus.Confirmed ||
      status === AppointmentStatus.ConfirmedWithVirtualPractitioner ||
      status === AppointmentStatus.New;
    if (
      (!loading && !appointment?.customerAppointment) ||
      (appointment?.customerAppointment && !isValid)
    ) {
      navigate("/");
    }
  }, [appointment]);

  if (!appointment) return null;
  return (
    <Row
      justify="center"
      style={{
        flex: 1,
        marginTop: 100,
      }}
    >
      <Col
        span={responsive({
          desktop: 10,
          tablet: 15,
          mobile: 24,
        })}
      >
        <Card
          title={
            <AppointmentTitle
              status={appointment.customerAppointment.appointmentStatus}
            />
          }
          style={{
            borderColor: theme.colors.text,
          }}
        >
          <Row>
            <Col span={24}>
              <Typography.Title
                level={5}
                style={{
                  marginTop: 15,
                }}
              >
                Datum & Uhrzeit
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Row align="middle" justify="center">
                <Col span={1}>
                  <CalendarOutlined
                    style={{
                      fontSize: 22,
                      color: theme.colors.text,
                    }}
                  />
                </Col>
                <Col span={23}>
                  <Row
                    style={{
                      paddingLeft: 15,
                    }}
                  >
                    <Col span={24}>
                      <Typography.Text
                        style={{
                          color: theme.colors.text,
                          fontSize: 16,
                        }}
                      >
                        {dayjs
                          .utc(appointment.customerAppointment.dateFrom)
                          .tz("Europe/Berlin")
                          .format("HH:mm")}{" "}
                        -{" "}
                        {dayjs
                          .utc(appointment.customerAppointment.dateUntil)
                          .tz("Europe/Berlin")
                          .format("HH:mm")}{" "}
                        Uhr
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      paddingLeft: 15,
                    }}
                  >
                    <Col span={24}>
                      <Typography.Text
                        style={{
                          color: theme.colors.text,
                          fontSize: 16,
                        }}
                      >
                        {dayjs
                          .utc(appointment.customerAppointment.dateUntil)
                          .tz("Europe/Berlin")
                          .format("ddd, D. MMM")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Title
                level={5}
                style={{
                  marginTop: 15,
                }}
              >
                Behandlungen
              </Typography.Title>
            </Col>
            {appointment.customerAppointment.products
              ?.filter((product) => !product.package?.id)
              .map((product, index) => (
                <Col span={24} key={product.id}>
                  <Typography.Text
                    style={{
                      color: theme.colors.text,
                      fontSize: 16,
                    }}
                  >
                    {`${index + 1}. ${product.name} - ${product.bookedPrice
                      .toString()
                      .replace(".", ",")}€`}
                  </Typography.Text>
                </Col>
              ))}
            <Col span={24}>
              <Typography.Title
                level={5}
                style={{
                  marginTop: 15,
                }}
              >
                Behandler
              </Typography.Title>
            </Col>
            {appointment.customerAppointment.practitioners
              ?.filter(
                (pracititoner) =>
                  pracititoner.presenceType !== ResourcePresenceType.Virtual,
              )
              .map((practitioner) => (
                <Col span={24} key={practitioner.id}>
                  <Row>
                    <Col span={1}>
                      <UserOutlined
                        style={{
                          fontSize: 22,
                          color: theme.colors.text,
                        }}
                      />
                    </Col>
                    <Col span={23}>
                      <Typography.Text>{`${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}`}</Typography.Text>
                    </Col>
                  </Row>
                </Col>
              ))}
            <Col span={24}>
              <Typography.Title
                level={5}
                style={{
                  marginTop: 15,
                }}
              >
                Filiale
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Row align="middle" justify="center">
                <Col span={1}>
                  <EnvironmentOutlined
                    size={40}
                    style={{
                      fontSize: 22,
                      color: theme.colors.text,
                    }}
                  />
                </Col>
                <Col
                  span={23}
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <Typography.Text
                    style={{
                      justifyContent: "center",
                      color: theme.colors.text,
                      fontSize: 16,
                      lineHeight: "19.2px",
                      fontWeight: 400,
                    }}
                  >
                    {appointment.customerAppointment.store?.name}
                  </Typography.Text>
                  <Row>
                    <Col span={24}>
                      <Typography.Text
                        style={{
                          justifyContent: "center",
                          color: theme.colors.text,
                          fontSize: 16,
                          lineHeight: "19.2px",
                          fontWeight: 400,
                        }}
                      >
                        {`${appointment.customerAppointment.store?.address}, ${appointment.customerAppointment.store?.zipCode} ${appointment.customerAppointment.store?.city}`}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Title
                level={5}
                style={{
                  marginTop: 15,
                }}
              >
                Preis
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Text
                style={{
                  color: theme.colors.text,
                  fontSize: 16,
                }}
              >
                {appointment?.customerAppointment?.bookedPrice
                  ?.toString()
                  ?.replace(".", ",")}
                €
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button
                    type="text"
                    style={{
                      color: theme.colors.text,
                    }}
                    onClick={handleReschedule}
                  >
                    Verschieben
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="text"
                    style={{
                      color: theme.colors.text,
                    }}
                    onClick={handleCancel}
                  >
                    Stornieren
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DesktopAppointment;
