import React from "react";
import { Col, Row, Typography } from "antd";
import { Footer as AntFooter } from "antd/lib/layout/layout";
import theme from "../../theme/theme";
import KaliaK from "../../assets/Kalia_K.png";
import { useLayout } from "./LayoutContext";

const Footer = () => {
  const { responsive } = useLayout();
  return (
    <AntFooter
      style={{
        marginTop: "20px",
        backgroundColor: theme.colors.background,
      }}
    >
      <Row
        style={{
          marginTop: "20px",
          backgroundColor: theme.colors.background,
        }}
        gutter={[16, 16]}
      >
        <Col xs={24} sm={24} md={6} lg={2}>
          <img src={KaliaK} alt="Kalia K" />
        </Col>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Row>
            <Col span={24}>
              <Typography.Text
                style={{
                  color: theme.colors.footer,
                }}
              >
                © Studio Nuvole
              </Typography.Text>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                color: theme.colors.footer,
              }}
            >
              <Typography.Link
                style={{
                  color: theme.colors.footer,
                }}
                href="https://www.kalialab.de/impressum"
              >
                Impressum
              </Typography.Link>
              {", "}
              <Typography.Link
                style={{
                  color: theme.colors.footer,
                  marginLeft: 5,
                }}
                href="https://www.kalialab.de/datenschutz"
              >
                Datenschutz
              </Typography.Link>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                color: theme.colors.footer,
              }}
            >
              <Typography.Link
                style={{
                  color: theme.colors.footer,
                }}
                href="https://www.kalialab.de/agb"
              >
                AGB
              </Typography.Link>
              {", "}
              <Typography.Link
                style={{
                  color: theme.colors.footer,
                  marginLeft: 5,
                }}
                href="https://www.kalialab.de/haftungsausschluss"
              >
                Haftungsausschluss
              </Typography.Link>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={2} lg={12} />
        <Col xs={24} sm={24} md={8} lg={4}>
          <Row>
            <Col span={24}>
              <Typography.Text
                style={{
                  color: theme.colors.footer,
                }}
              >
                Berlin - Am Tacheles
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text
                style={{
                  color: theme.colors.footer,
                }}
              >
                +49 30 255 596 60
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text
                style={{
                  color: theme.colors.footer,
                }}
              >
                Hamburg - Ballindamm
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text
                style={{
                  color: theme.colors.footer,
                }}
              >
                +49 40 229 479 17
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text
                style={{
                  color: theme.colors.footer,
                }}
              >
                info@kontakt.kalialab.de
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default Footer;
