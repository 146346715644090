import { ProductWithVariation } from "../../components/bookingProvider/BookingContext";

const getUniqueBookedProducts = (bookedProducts: ProductWithVariation[]) => {
  const uniqueBookedProducts: ProductWithVariation[] = [];
  bookedProducts.forEach((bookedProduct) => {
    const existingProduct = uniqueBookedProducts.find((product) =>
      bookedProduct.variation
        ? product.id === bookedProduct.id &&
          product.variation === bookedProduct.variation
        : product.id === bookedProduct.id && !product.variation,
    );
    if (!existingProduct) {
      uniqueBookedProducts.push(bookedProduct);
    }
  });
  return uniqueBookedProducts;
};

export default getUniqueBookedProducts;
