import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import formItemProps from "../helper/form/formItemProps";
import { useSetCustomerPasswordMutation } from "../graphql/schema";
import useCustomerAuth from "../customerAuth/useCustomerAuth";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [resetted, setResetted] = useState(false);
  const [reset, setReset] = useState(true);
  const navigate = useNavigate();
  const { getAuthToken } = useCustomerAuth();

  const [create] = useSetCustomerPasswordMutation();

  const handleCustomerPassword = async () => {
    try {
      if (form?.getFieldsValue()) {
        const token = await getAuthToken();
        await create({
          variables: {
            dto: {
              token,
              password: form?.getFieldsValue().password,
            },
          },
        });
        setReset(false);
        setResetted(true);
      }
    } catch (e) {
      message.error("Änderung fehlgeschlagen. Versuche es später erneut");
    }
  };

  if (resetted) {
    return (
      <Modal
        open={resetted}
        closeIcon={false}
        footer={
          <Row justify="center">
            <Col span={22}>
              <Button
                style={{
                  width: "100%",
                  height: 40,
                  borderRadius: 15,
                  backgroundColor: "#8B8B8B",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <Typography.Text
                  style={{
                    color: "white",
                  }}
                >
                  Zurück zur Anmeldung
                </Typography.Text>
              </Button>
            </Col>
          </Row>
        }
      >
        <Row justify="center">
          <Col span={22}>
            <Typography.Text>
              Dein Passwort wurde erfolgreich geändert.
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
    );
  }
  return (
    <Modal
      style={{
        width: "100%",
        height: "100%",
      }}
      title="Neues Passwort vergeben"
      open={reset}
      closeIcon={false}
      footer={
        <Row justify="center">
          <Col span={22}>
            <Button
              style={{
                width: "100%",
                height: 40,
                borderRadius: 15,
                backgroundColor: "#8B8B8B",
              }}
              onClick={async () => {
                await handleCustomerPassword();
              }}
            >
              <Typography.Text
                style={{
                  color: "white",
                }}
              >
                Erstellen
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      }
    >
      <Form form={form} layout="vertical">
        <Row
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Col span={22}>
            <Form.Item
              style={{
                fontWeight: "bold",
              }}
              {...formItemProps("password", "Passwort", true)}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ResetPassword;
