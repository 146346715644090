interface Props {
  product: any;
  variationId: number;
}

const findVariation = ({ product, variationId }: Props) => {
  return product.variations.find((v: any) => v.id === variationId);
};

export default findVariation;
