import React from "react";
import { Col, Row, Typography } from "antd";
import getPriceForCustomer from "../../helper/common/getPriceForCustomer";
import useBooking from "../bookingProvider/useBooking";

interface Props {
  name: string;
  totalDuration: number;
  prices: any;
  separator?: string;
  amount?: number;
}

const CardTitle = ({ name, totalDuration, prices, separator }: Props) => {
  const { stateValues } = useBooking();
  return (
    <Row justify="space-around" align="middle">
      <Col span={24}>
        <Row justify="space-around" align="middle">
          <Col span={24}>
            <Typography.Text strong>{name}</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>
              {totalDuration} Min.{separator || " "}
              {getPriceForCustomer(prices, stateValues.customer)
                .toFixed(2)
                .replace(".", ",")}{" "}
              €
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CardTitle;
