import { useNavigate, useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import { useEffect } from "react";
import useCustomerAuth from "../customerAuth/useCustomerAuth";

const Login = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { updateTokens } = useCustomerAuth();

  useEffect(() => {
    if (searchParams.get("token")) updateTokens(searchParams.get("token")!);
    navigate("/");
  }, []);
  return <Spin />;
};

export default Login;
