import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { CustomerAuthContext, LoginResult } from "./customerAuthContext";
import {
  deleteTokensFromStorage,
  getOptionsFromToken,
  getRoles,
  getTokensFromStorage,
  setTokensInStorage,
  tokenIsValid,
} from "./tokenHelper";
import { useCustomerLoginMutation } from "../graphql/schema";
import generateLink from "./generateLink";

const CustomerAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const apolloClient = useApolloClient();
  const [login] = useCustomerLoginMutation();
  const [token, setToken] = useState<string | null | undefined>(undefined);

  const updateClientToken = useCallback(
    (t?: string | null) => {
      apolloClient.setLink(
        generateLink(t, () => {
          deleteTokensFromStorage();
          setToken(null);
          updateClientToken(null);
          apolloClient.resetStore();
        }),
      );
    },
    [apolloClient],
  );

  const updateTokens = (t: string) => {
    updateClientToken(t);
    setTokensInStorage(t);
    setToken(t);
  };

  useEffect(() => {
    (async () => {
      const [loadedToken] = await getTokensFromStorage();
      if (loadedToken && tokenIsValid(loadedToken)) {
        updateClientToken(loadedToken);
        setToken(loadedToken);
      } else {
        updateClientToken(null);
        setToken(null);
      }
    })();
  }, []);

  const providerValue = useMemo(() => {
    const getAuthToken = async (): Promise<string> => {
      if (token && tokenIsValid(token)) return token;

      // Invalid token
      setToken(null);

      return "";
    };
    const signInWithPhone = async (
      phone: string,
      password?: string,
      options?: any,
    ): Promise<LoginResult> => {
      try {
        const result = await login({
          variables: {
            dto: {
              phone,
              password: password || null,
              options: options || null,
            },
          },
          fetchPolicy: "no-cache",
        });

        if (result.data?.customerLoginBookingRoute?.token) {
          const newToken = result.data.customerLoginBookingRoute.token;
          updateTokens(newToken);
          return LoginResult.successful;
        }

        if (result.data?.customerLoginBookingRoute?.mailSent) {
          return LoginResult.mailSent;
        }

        return LoginResult.failed;
      } catch (error) {
        return LoginResult.failed;
      }
    };

    const signInWithEmail = async (
      email: string,
      password?: string,
      options?: any,
    ): Promise<LoginResult> => {
      try {
        const result = await login({
          variables: {
            dto: {
              email,
              password: password || null,
              options: options || null,
            },
          },
          fetchPolicy: "no-cache",
        });

        if (result.data?.customerLoginBookingRoute?.token) {
          const newToken = result.data.customerLoginBookingRoute.token;
          updateTokens(newToken);
          return LoginResult.successful;
        }

        if (result.data?.customerLoginBookingRoute?.mailSent) {
          return LoginResult.mailSent;
        }
        return LoginResult.failed;
      } catch (error) {
        return LoginResult.failed;
      }
    };

    const logout = () => {
      deleteTokensFromStorage();
      setToken(null);
      updateClientToken(null);
      apolloClient.resetStore();
    };

    return {
      isAuthenticated: !!token,
      authIsLoading: token === undefined,
      getAuthToken,
      signInWithEmail,
      signInWithPhone,
      updateTokens,
      logout,
      roles: getRoles(token),
      options: getOptionsFromToken(token),
    };
  }, [token, apolloClient]);

  return (
    <CustomerAuthContext.Provider value={providerValue}>
      {children}
    </CustomerAuthContext.Provider>
  );
};

export default CustomerAuthProvider;
