import React, { useMemo } from "react";
import { Col, Row } from "antd";
import CardTitle from "../products/CardTitle";
import { ProductWithVariation } from "../bookingProvider/BookingContext";
import { ProductOutput } from "../../graphql/schema";
import useProductCard from "../products/useProductCard";
import OverviewCardButtons from "./OverviewCardButtons";

interface Props {
  bookedProduct: ProductWithVariation;
  products: Partial<ProductOutput>[];
}

const OverviewProductCard = ({ bookedProduct, products }: Props) => {
  const product = useMemo(() => {
    const p = products?.find((p) => p?.id === bookedProduct.id);
    return bookedProduct.variation
      ? p?.variations?.find((v) => v.id === bookedProduct?.variation)
      : p;
  }, [bookedProduct]);

  const { addProduct, getProductQuantity, remove, changeProducts } =
    useProductCard({
      products,
      product,
      baseProductId: bookedProduct.id,
      variationId: bookedProduct?.variation,
    });

  return (
    <Col span={24}>
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          cursor: "pointer",
          marginBottom: "10px",
          padding: "20px",
          backgroundColor: "#F6F6F4",
          borderRadius: "10px",
        }}
      >
        <Col span={16}>
          <CardTitle
            separator=" à "
            name={product?.name || ""}
            totalDuration={product?.totalDuration || 0}
            prices={product?.prices || []}
          />
        </Col>
        <Col span={8}>
          <OverviewCardButtons
            amount={getProductQuantity(
              bookedProduct.id,
              bookedProduct.variation,
            )}
            add={() =>
              changeProducts(() =>
                addProduct(bookedProduct.id, bookedProduct.variation),
              )
            }
            remove={() =>
              changeProducts(() =>
                remove(bookedProduct.id, bookedProduct.variation),
              )
            }
          />
        </Col>
      </Row>
    </Col>
  );
};

export default OverviewProductCard;
