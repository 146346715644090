import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { useLocation } from "react-router-dom";
import formItemProps from "../../helper/form/formItemProps";
import { LoginResult } from "../../customerAuth/customerAuthContext";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useBooking from "../bookingProvider/useBooking";
import { useLogin } from "./LoginContext";
import theme from "../../theme/theme";
import getCustomerLoginOptions from "../../helper/common/getCustomerLoginOptions";

const LoginDrawer = () => {
  const { signInWithEmail, signInWithPhone } = useCustomerAuth();
  const { refetchCustomer, stateValues } = useBooking();
  const location = useLocation();
  const { loginOpen, closeLogin, openResetPassword } = useLogin();
  const [form] = Form.useForm();


  const handleLoginInfo = (loginResult: LoginResult) => {
    switch (loginResult) {
      case LoginResult.successful:
        message.success("Erfolgreich angemeldet");
        break;
      case LoginResult.failed:
        message.error("Zugangsdaten ungültig");
        break;
      case LoginResult.mailSent:
        message.info("Mail wurde gesendet");
        break;
      default:
        break;
    }
  };

  const handleLogin = async () => {
    const { email, phone, password } = await form.validateFields();
    const loginResult = email
      ? await signInWithEmail(email, password, {
          route: location.pathname,
          ...getCustomerLoginOptions(stateValues),
        })
      : await signInWithPhone(phone, password, {
          route: location.pathname,
          ...getCustomerLoginOptions(stateValues),
        });
    if (loginResult === LoginResult.failed)
      return handleLoginInfo(LoginResult.failed);
    handleLoginInfo(loginResult);
    await refetchCustomer();
    closeLogin();
  };

  return (
    <Drawer
      open={loginOpen}
      placement="bottom"
      closeIcon={null}
      style={{
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
      }}
      bodyStyle={{
        paddingBottom: 0,
        height: "100%",
      }}
      footer={
        <Row>
          <Col span={24}>
            <Button
              style={{
                width: "100%",
                height: 49,
                marginBottom: 10,
                borderRadius: 15,
                backgroundColor: theme.colors.button,
              }}
              onClick={() => handleLogin()}
            >
              <Typography.Text
                style={{
                  color: "white",
                }}
              >
                Anmelden
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      }
    >
      <Row justify="end">
        <Col>
          <CloseOutlined
            style={{
              fontSize: "20px",
              color: theme.colors.disabled,
            }}
            onClick={closeLogin}
          />
        </Col>
      </Row>
      <Form form={form} layout="vertical" onFinish={() => handleLogin()}>
        <Row gutter={[0, 26]}>
          <Col span={24}>
            <Typography.Title
              level={4}
              style={{
                textAlign: "left",
                margin: 0,
              }}
            >
              Anmelden
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Text strong>E-Mail</Typography.Text>
            <Form.Item
              style={{
                marginBottom: 0,
              }}
              {...formItemProps("email", "", false)}
              rules={[
                {
                  required: false,
                  message: "Bitte gib deine E-Mail ein.",
                },
              ]}
            >
              <Input autoFocus />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Typography.Text strong>Passwort</Typography.Text>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <Col>
                    <Typography.Text
                      style={{
                        cursor: "pointer",
                      }}
                      type="secondary"
                      onClick={async () => {
                        await handleLogin();
                      }}
                    >
                      Anmeldelink senden
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{
                    marginBottom: 0,
                  }}
                  {...formItemProps("password", "", false)}
                  rules={[
                    {
                      required: false,
                      message: "Bitte gib dein Passwort an!",
                    },
                  ]}
                >
                  <Input.Password
                    iconRender={(visible) =>
                      visible ? (
                        <Typography.Text
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Verstecken
                        </Typography.Text>
                      ) : (
                        <Typography.Text
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Anzeigen
                        </Typography.Text>
                      )
                    }
                    type="password"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default LoginDrawer;
