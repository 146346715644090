import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import {
  CalendarOutlined,
  EnvironmentOutlined,
  GiftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useCallback, useState } from "react";
import dayjs from "dayjs";
import {
  CouponVerificationOutput,
  Product,
  useGetPublicStoreQuery,
  useVerifyCouponsMutation,
} from "../../graphql/schema";
import formItemProps from "../../helper/form/formItemProps";
import CouponDrawer from "./CouponDrawer";
import getPriceForCustomer from "../../helper/common/getPriceForCustomer";
import { Layout, useLayout } from "../layout/LayoutContext";
import OverviewProductList from "../overview/OverviewProductList";
import useBooking from "../bookingProvider/useBooking";
import { ProductWithVariation } from "../bookingProvider/BookingContext";
import getProductByBookedProduct from "../../helper/common/getProductByBookedProduct";

interface Props {
  products: any;
  checkboxForm: FormInstance;
}

const DetailsMobile = ({ products, checkboxForm }: Props) => {
  const { responsive, device } = useLayout();
  const [couponForm] = Form.useForm();
  const [verify] = useVerifyCouponsMutation();
  const { stateValues, getStates, setStates } = useBooking();
  const [couponDrawerVisible, setCouponDrawerVisible] = useState(false);
  const [validatingCoupon, setValidatingCoupon] = useState(false);
  const { data: store } = useGetPublicStoreQuery({
    variables: {
      id: stateValues.store.id!,
    },
  });

  const getCouponCodes = () => {
    const { couponCodes } = getStates(["couponCodes"]);
    return couponCodes?.length ? couponCodes : [];
  };

  const removeLastCouponCode = () => {
    const codes = getCouponCodes();
    if (codes.length) {
      codes.splice(codes.length - 1, 1);
    }
    setStates({
      ...stateValues,
      couponCodes: codes,
    });
  };
  const getBookedProducts = () => {
    const { bookedProducts } = getStates(["bookedProducts"]);
    return bookedProducts?.length ? bookedProducts : [];
  };

  const resetPrices = useCallback(() => {
    const bookedProducts = getBookedProducts();
    setStates({
      ...stateValues,
      couponCodes: [],
      bookedProducts: bookedProducts.map((product) => {
        let price = "0";
        if (product.variation) {
          const variation = products
            .find((p: Product) => p.id === product.id)
            .variations.find(
              (variation: any) => variation.id === product.variation,
            );
          if (variation?.prices)
            price = getPriceForCustomer(
              variation.prices,
              stateValues.customer,
            ).toString();
        } else {
          price = getPriceForCustomer(
            products.find((p: Product) => p.id === product.id).prices,
            stateValues.customer,
          ).toString();
        }
        const baseProduct = getProductByBookedProduct(product, products);
        return {
          ...product,
          price: price.toString(),
          netPrice: Number(price) / (1 + Number(baseProduct.taxRate) / 100),
        };
      }),
    });
  }, [getStates, setStates, stateValues]);

  const setNewPriceForBookedProducts = (
    bookedProducts: CouponVerificationOutput[] | undefined,
    codes: string[],
  ) => {
    if (!bookedProducts) return;
    const verifiedBookedProducts: ProductWithVariation[] = bookedProducts.map(
      (product) => {
        const baseProduct = getProductByBookedProduct(product, products);
        return {
          id: product.productId,
          variation: product.variationId ? product.variationId : undefined,
          price: product.price ? product.price : null,
          netPrice:
            Number(product.price) / (1 + Number(baseProduct.taxRate) / 100),
        };
      },
    );

    setStates({
      ...stateValues,
      bookedProducts: verifiedBookedProducts,
      couponCodes:
        JSON.stringify(verifiedBookedProducts) ===
        JSON.stringify(stateValues.bookedProducts)
          ? codes.filter((code) => code !== codes.pop())
          : codes,
    });
    if (codes.length < getCouponCodes().length) {
      if (codes.length === 0) {
        resetPrices();
      }
      message.success("Der Gutscheincode wurde erfolgreich entfernt");
    } else if (
      JSON.stringify(verifiedBookedProducts) ===
      JSON.stringify(stateValues.bookedProducts)
    ) {
      message.error("Der Gutscheincode ist nicht anwendbar");
    } else {
      message.success("Der Gutscheincode wurde erfolgreich angewendet");
    }
  };

  const verifyCoupon = useCallback(
    async (codes?: string[]): Promise<boolean> => {
      const bookedProducts = getBookedProducts();
      if (!bookedProducts) return false;
      try {
        const response = await verify({
          variables: {
            dto: {
              products: bookedProducts.map((product) => ({
                id: product.id,
                variation: product.variation ? product.variation : undefined,
                price: product.price ? product.price.toString() : null,
              })),
              couponCodes: codes || getCouponCodes(),
              customer: {
                id: stateValues.customer.id || 0,
              },
            },
          },
        });
        setNewPriceForBookedProducts(
          response.data?.verifyCoupons || [],
          codes || getCouponCodes(),
        );
      } catch (error) {
        removeLastCouponCode();
        message.error("Der Gutscheincode ist nicht anwendbar");
      }
      return true;
    },
    [getStates, setStates, stateValues],
  );

  const addCouponCode = async (code: string) => {
    if (!code) return;
    setValidatingCoupon(true);
    const codes = getCouponCodes();
    if (!codes.some((c) => c.toLowerCase() === code.toLowerCase())) {
      codes.push(code);
      await verifyCoupon(codes);

      setValidatingCoupon(false);
    } else {
      message.error("Der Gutscheincode wurde bereits hinzugefügt");
      setValidatingCoupon(false);
    }
  };

  const handleChangeCheckbox = (values: any) => {
    if (values?.message) setStates({ ...stateValues, message: values.message });
  };

  if (!store || !products) return null;
  return (
    <Row
      style={{
        width: "100%",
      }}
    >
      <Col span={24}>
        <Row justify="center">
          <Col
            span={responsive({
              tablet: 24,
              mobile: 24,
            })}
          >
            <Row>
              <Col span={24}>
                <Typography.Title level={5}>Datum & Uhrzeit</Typography.Title>
              </Col>
            </Row>
            <Row align="middle" justify="start">
              <Col
                span={responsive({
                  mobile: 2,
                  tablet: 1,
                })}
              >
                <CalendarOutlined
                  style={{
                    fontSize: "25px",
                  }}
                />
              </Col>
              <Col span={21}>
                <Row>
                  <Col>
                    <Typography.Text>
                      {stateValues.appointment
                        ? dayjs.tz(stateValues.appointment).format("HH:mm")
                        : ""}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography.Text>
                      {stateValues.appointment
                        ? dayjs
                            .tz(stateValues.appointment)
                            .format("ddd, D. MMM")
                        : ""}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: "10px",
              }}
            >
              <Col span={24}>
                <Typography.Title level={5}>Gutschein</Typography.Title>
              </Col>
            </Row>

            {device === Layout.TABLET ? (
              <Form
                initialValues={{
                  couponCode:
                    getCouponCodes().length > 0 ? getCouponCodes()[0] : "",
                }}
                style={{ marginBottom: -20 }}
                form={couponForm}
              >
                <Col span={12}>
                  <Space.Compact style={{ width: "100%" }}>
                    <Form.Item
                      hasFeedback={validatingCoupon}
                      validateStatus={
                        validatingCoupon ? "validating" : undefined
                      }
                      {...formItemProps("couponCode", "")}
                    >
                      <Input disabled={getCouponCodes().length > 0} />
                    </Form.Item>
                    <Button
                      type="text"
                      disabled={getCouponCodes().length > 0}
                      style={{
                        borderColor: "#d9d9d9",
                        color:
                          getCouponCodes().length > 0 ? "#9DC072" : "#8B8B8B",
                      }}
                      onClick={() =>
                        addCouponCode(couponForm.getFieldValue("couponCode"))
                      }
                    >
                      {getCouponCodes().length > 0 ? "Angewendet" : "Anwenden"}
                    </Button>
                  </Space.Compact>
                </Col>
              </Form>
            ) : (
              <>
                <Row justify="center">
                  <Col span={20}>
                    <Divider
                      style={{
                        margin: "5px",
                      }}
                    />
                  </Col>
                </Row>
                <Row onClick={() => setCouponDrawerVisible(true)}>
                  <Col
                    span={responsive({
                      mobile: 2,
                      tablet: 2,
                    })}
                  >
                    <GiftOutlined
                      style={{
                        fontSize: "20px",
                      }}
                    />
                  </Col>
                  <Col span={20}>
                    <Typography.Text>
                      {stateValues.couponCodes.length
                        ? stateValues.couponCodes.map((code, index) => (
                            <Typography.Text key={`coupon_${code}`}>{`${code}${
                              index < stateValues.couponCodes.length - 1
                                ? ", "
                                : ""
                            }`}</Typography.Text>
                          ))
                        : "Gutschein Code hinzufügen"}
                    </Typography.Text>
                  </Col>
                  <Col span={2}>
                    <RightOutlined
                      style={{
                        fontSize: "15px",
                      }}
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={20}>
                    <Divider
                      style={{
                        margin: "5px",
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row
              style={{
                marginTop: "10px",
              }}
            >
              <Col span={24}>
                <Typography.Title level={5}>Filiale</Typography.Title>
              </Col>
            </Row>
            <Row align="middle">
              <Col
                span={responsive({
                  mobile: 2,
                  tablet: 1,
                })}
              >
                <EnvironmentOutlined
                  style={{
                    fontSize: "20px",
                  }}
                />
              </Col>
              <Col span={20}>
                <Row>
                  <Col>
                    <Typography.Text>{store.publicStore.name}</Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography.Text>
                      {store.publicStore.address}, {store.publicStore.city}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "10px",
              }}
            >
              <Col span={24}>
                <Typography.Title level={5}>Behandlungen</Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col
                span={responsive({
                  tablet: 12,
                  mobile: 24,
                })}
              >
                {stateValues.store.id && <OverviewProductList />}
              </Col>
            </Row>
            <Form
              layout="vertical"
              form={checkboxForm}
              onValuesChange={(changedValues, values) =>
                handleChangeCheckbox(values)
              }
            >
              <Row
                style={{
                  marginTop: "10px",
                }}
              >
                <Col span={24}>
                  <Form.Item
                    {...formItemProps("comment", "Nachricht", false)}
                    style={{
                      fontWeight: "bold",
                      marginBottom: 10,
                    }}
                  >
                    <Input.TextArea
                      rows={responsive({
                        tablet: 6,
                        mobile: 3,
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!stateValues.customer.id && (
                <Row
                  style={{
                    marginBottom: "80px",
                    marginTop: "10px",
                  }}
                >
                  {/* <Col span={24}>
                    <Form.Item
                      {...formItemProps("newsletter", "", true, [
                        {
                          required: true,
                          message: "Stimme zu, um deinen Termin zu buchen.",
                          validator: (_, value) => value,
                        },
                      ])}
                      valuePropName="checked"
                    >
                      <Checkbox>
                        Trage dich in unseren Newsletter ein, um über neue
                        Behandlungen und Angebote informiert zu werden.
                      </Checkbox>
                    </Form.Item>
                  </Col> */}
                  <Col span={24}>
                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                      {...formItemProps("terms", "", true, [
                        {
                          required: true,
                          message:
                            "Bitte stimme den AGBs & Datenschutzbestimmungen zu.",
                          validator: (_, value) => value,
                        },
                      ])}
                      valuePropName="checked"
                    >
                      <Checkbox
                        style={{
                          padding: 0,
                        }}
                      >
                        <Typography.Text>
                          Ich stimme den{" "}
                          <Typography.Link
                            underline
                            href="https://kalialab.de/agb/"
                          >
                            AGB's
                          </Typography.Link>{" "}
                          und{" "}
                          <Typography.Link
                            underline
                            href="https://kalialab.de/datenschutz-2/"
                          >
                            Datenschutzbestimmungen
                          </Typography.Link>{" "}
                          zu.
                        </Typography.Text>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>
        <CouponDrawer
          products={products}
          open={couponDrawerVisible}
          onClose={() => setCouponDrawerVisible(false)}
        />
      </Col>
    </Row>
  );
};

export default DetailsMobile;
