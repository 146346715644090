import React from "react";
import { Button, Col, Flex, Row, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useGetCouponByCodeQuery } from "../../graphql/schema";

interface Props {
  code: string;
  remove: (code: string) => void;
}

const CouponLabel = ({ code, remove }: Props) => {
  const { data } = useGetCouponByCodeQuery({
    variables: {
      code,
    },
  });

  if (!data) return null;
  return (
    <Row
      gutter={[3, 3]}
      style={{
        marginBottom: 15,
      }}
    >
      <Col span={24}>
        <Button
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: 15,
            marginRight: 5,
            textAlign: "left",
            height: "100%",
          }}
          key={`Code_${code}`}
          type="text"
          onClick={() => remove(code)}
        >
          <Flex vertical>
            <Typography.Text>{code}</Typography.Text>
          </Flex>
          <CloseOutlined />
        </Button>
      </Col>
      <Col span={24}>
        <Typography.Text
          style={{
            fontStyle: "italic",
          }}
        >
          {data.couponByCode.description}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default CouponLabel;
