import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import React, { useState } from "react";
import formItemProps from "../../helper/form/formItemProps";
import { useResetCustomerPasswordMutation } from "../../graphql/schema";
import { useLogin } from "./LoginContext";
import theme from "../../theme/theme";

const ForgotPasswordModal = () => {
  const [mailSent, setMailSent] = useState(false);
  const [form] = Form.useForm();
  const { openLogin, resetPasswordOpen, closeResetPassword } = useLogin();

  const [send] = useResetCustomerPasswordMutation();

  const handleResetPassword = async () => {
    if (form.getFieldsValue()) {
      try {
        await send({
          variables: {
            dto: {
              email: form?.getFieldsValue()?.email || "",
              phone: form?.getFieldsValue()?.phone || "",
            },
          },
        });
        setMailSent(true);
      } catch (e) {
        message.error(
          "Das Versenden ist fehlgeschlagen, achte darauf eine gültige E-Mail zu verwenden.",
        );
      }
    }
  };

  if (mailSent) {
    return (
      <Modal
        centered
        width={400}
        onCancel={() => {
          setMailSent(false);
          closeResetPassword();
        }}
        open={mailSent}
        footer={
          <Row
            justify="center"
            style={{
              marginTop: 20,
            }}
          >
            <Col span={24}>
              <Button
                style={{
                  width: "100%",
                  height: 49,
                  borderRadius: 15,
                  backgroundColor: theme.colors.button,
                }}
                onClick={() => {
                  closeResetPassword();
                  openLogin();
                }}
              >
                <Typography.Text
                  strong
                  style={{
                    color: "white",
                  }}
                >
                  Zurück zur Anmeldung
                </Typography.Text>
              </Button>
            </Col>
          </Row>
        }
      >
        <Row justify="center">
          <Col span={22}>
            <Typography.Text
              style={{
                textAlign: "center",
                display: "block",
              }}
            >
              Eine E-Mail wurde an deine E-Mail{" "}
              <Typography.Text strong>
                {form.getFieldsValue().email}{" "}
              </Typography.Text>
              gesendet.
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
    );
  }
  return (
    <Modal
      width={400}
      centered
      open={resetPasswordOpen}
      title=""
      onCancel={closeResetPassword}
      footer={
        <Row
          align="middle"
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Col span={24}>
            <Button
              style={{
                width: "100%",
                height: 49,
                borderRadius: 15,
              }}
              onClick={() => handleResetPassword()}
            >
              <Typography.Text
                strong
                style={{
                  color: "white",
                }}
              >
                Passwort zurücksetzen
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      }
    >
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Typography.Title
            level={4}
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            Passwort vergessen?
          </Typography.Title>
        </Col>
        <Col span={24}>
          <div>
            <Typography.Text
              style={{
                textAlign: "center",
                display: "block",
              }}
            >
              Trage deine E-Mail Adresse in das Feld ein & erhalte einen Link,
              um dein Passwort neu zu vergeben.
            </Typography.Text>
          </div>
        </Col>
        <Col span={24}>
          <Form form={form} layout="vertical">
            <Form.Item
              style={{
                margin: 0,
                fontWeight: "bold",
              }}
              {...formItemProps("email", "E-Mail", false, [
                {
                  required: false,
                  message: "Bitte trage eine gültige E-Mail ein.",
                },
              ])}
            >
              <Input />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default ForgotPasswordModal;
