import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { CalendarOutlined } from "@ant-design/icons";
// @ts-ignore
import { de } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import Link from "antd/es/typography/Link";
import dayjs from "dayjs";
import { useGetPublicStoresQuery } from "../../graphql/schema";
import formItemProps from "../../helper/form/formItemProps";
import getCartSum from "../../helper/common/getCartSum";
import getRegularCartSum from "../../helper/common/getRegularCartSum";
import useBooking from "../bookingProvider/useBooking";
import OverviewProductList from "./OverviewProductList";
import theme from "../../theme/theme";

interface Props {
  isValidated: boolean;
  tooltipTitle: string;
  onContinue: () => void;
  showPrice?: boolean;
  products?: any[];
  containerHeight?: number;
  onValidateFormFields?: () => void;
  continueButtonTitle?: string;
  isLastView?: boolean;
}

const OverviewCard = ({
  isValidated,
  tooltipTitle,
  onContinue,
  showPrice,
  containerHeight,
  onValidateFormFields,
  continueButtonTitle = "Fortfahren",
  products,
}: Props) => {
  const { stateValues, setStates } = useBooking();
  const { data: stores } = useGetPublicStoresQuery();
  const [validToContinue, setValidToContinue] = useState<boolean>(isValidated);
  const [form] = Form.useForm();

  useEffect(() => {
    setValidToContinue(isValidated);
  }, [isValidated]);

  const totalPrice = useMemo(
    () =>
      getCartSum({
        bookedProducts: stateValues.bookedProducts,
      }),
    [stateValues],
  );

  const regularTotalPrice = useMemo(() => {
    if (!products) return 0;
    return getRegularCartSum(
      stateValues.bookedProducts,
      products,
      stateValues.customer,
    );
  }, [stateValues, products]);

  const handleChange = (values: any) => {
    setStates({
      ...stateValues,
    });
    if (!!values?.terms && stateValues.bookedProducts.length)
      setValidToContinue(true);
    else setValidToContinue(false);
  };

  return (
    <Card
      style={{
        marginTop: 30,
        height: 575,
        maxHeight: 575,
        padding: 30,
        position: "sticky",
        top: 100,
        right: 50,
        transition: "top 0.2s ease-in-out",
        width: 425,
        backgroundColor: theme.colors.background,
        border: `1px solid ${theme.colors.icon}`,
        borderRadius: "15px",
      }}
      bodyStyle={{
        padding: 0,
      }}
    >
      <Row gutter={[0, 24]} justify="space-evenly">
        <Col
          span={24}
          style={{
            minHeight: 29,
          }}
        >
          <Typography.Title level={1}>Übersicht</Typography.Title>
        </Col>
        <Col span={24}>
          <Row
            justify="space-between"
            gutter={[0, 10]}
            style={{
              minHeight: 52,
            }}
          >
            <Col span={24}>
              <Typography.Title level={2}>Datum & Uhrzeit</Typography.Title>
            </Col>
            <Col span={24}>
              <Row align="middle" justify="center">
                <Col span={1}>
                  <CalendarOutlined
                    style={{
                      fontSize: 22,
                      color: theme.colors.text,
                    }}
                  />
                </Col>
                <Col span={23}>
                  <Row
                    style={{
                      paddingLeft: 15,
                    }}
                  >
                    <Col span={24}>
                      <Typography.Text
                        style={{
                          color: theme.colors.text,
                        }}
                      >
                        {stateValues.appointment
                          ? `${dayjs
                              .tz(stateValues.appointment)
                              .format("HH:mm")} Uhr`
                          : "Nicht ausgewählt"}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Typography.Text
                        style={{
                          color: theme.colors.text,
                        }}
                      >
                        {stateValues.appointment
                          ? dayjs
                              .tz(stateValues.appointment)
                              .format("ddd, D. MMM")
                          : ""}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            align="middle"
            gutter={[0, 10]}
            justify="space-between"
            style={{
              minHeight: 52,
            }}
          >
            <Col span={24}>
              <Typography.Title level={2}>Behandlungen</Typography.Title>
            </Col>
            <Col span={24}>
              <OverviewProductList />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: "white",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 20,
        }}
        justify="center"
      >
        <Col span={24}>
          {showPrice && (
            <Row
              style={{
                padding: 0,
              }}
            >
              <Col span={24}>
                <Row justify="center">
                  <Col span={24}>
                    <Divider
                      style={{
                        margin: 0,
                        borderColor: theme.colors.header,
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  justify="center"
                  style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                >
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Typography.Text strong>Betrag: </Typography.Text>{" "}
                        <Typography.Text
                          delete={regularTotalPrice > totalPrice}
                        >
                          {regularTotalPrice.toFixed(2).replace(".", ",")}€
                        </Typography.Text>{" "}
                        {totalPrice < regularTotalPrice && (
                          <Typography.Text>
                            {Number(totalPrice).toFixed(2).replace(".", ",")}€
                          </Typography.Text>
                        )}
                      </Col>
                    </Row>
                    {stateValues?.customer?.id ? null : (
                      <Form
                        form={form}
                        onValuesChange={(changedValues, values) =>
                          handleChange(values)
                        }
                      >
                        {/* <Row justify="center">
                              <Col span={24}>
                                <Form.Item
                                  style={{
                                    marginBottom: 10,
                                  }}
                                  {...formItemProps("newsletter", "", true, [
                                    {
                                      required: true,
                                      message:
                                        "Deine Zustimmung ist notwendig, um deine Buchung abzuschließen.",
                                      validator: (_, value) => value,
                                    },
                                  ])}
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    Ich möchte den Newsletter erhalten
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row> */}
                        <Row justify="center">
                          <Col span={24}>
                            <Form.Item
                              style={{
                                marginBottom: 10,
                              }}
                              {...formItemProps("terms", "", true, [
                                {
                                  required: true,
                                  message:
                                    "Deine Zustimmung ist erforderlich, um die Buchung durchzuführen",
                                  validator: (_, value) => value,
                                },
                              ])}
                              valuePropName="checked"
                            >
                              <Checkbox
                                style={{
                                  padding: 0,
                                }}
                              >
                                <Typography.Text>
                                  Ich stimme den{" "}
                                  <Link
                                    href="https://kalialab.de/agb/"
                                    target="_blank"
                                  >
                                    AGB's
                                  </Link>{" "}
                                  and{" "}
                                  <Link
                                    href="https://kalialab.de/datenschutzerklaerung/"
                                    target="_blank"
                                  >
                                    Datenschutzbestimmungen
                                  </Link>{" "}
                                  zu.
                                </Typography.Text>
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row
            justify="center"
            style={{
              paddingTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
              paddingBottom: 10,
            }}
          >
            <Col span={24}>
              <Tooltip title={tooltipTitle}>
                <Button
                  style={{
                    cursor: "pointer",
                    borderRadius: "15px",
                    borderColor: validToContinue
                      ? theme.colors.button
                      : "#D1D0D0",
                    backgroundColor: validToContinue
                      ? theme.colors.button
                      : "#D1D0D0",
                    height: 50,
                    width: "100%",
                  }}
                  onClick={() => {
                    if (onValidateFormFields) {
                      onValidateFormFields();
                    }
                    if (validToContinue) {
                      onContinue();
                    }
                  }}
                >
                  <Typography.Text
                    strong
                    style={{
                      color: "#FFFFFF",
                    }}
                  >
                    {continueButtonTitle}
                  </Typography.Text>
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default OverviewCard;
