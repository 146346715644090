import { Col, Row, Typography } from "antd";
import React from "react";
// @ts-ignore
import { de } from "date-fns/locale";
import dayjs from "dayjs";
import useBooking from "../bookingProvider/useBooking";

const CalendarBadgeContent = () => {
  const { stateValues } = useBooking();
  return (
    <Col span={12}>
      <Row>
        <Col span={24}>
          <Typography.Text strong>
            {stateValues?.appointment
              ? dayjs.tz(stateValues.appointment).format("HH:mm")
              : ""}
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Text strong>
            {stateValues?.appointment
              ? dayjs.tz(stateValues.appointment).format("ddd, D. MMM")
              : ""}
          </Typography.Text>
        </Col>
      </Row>
    </Col>
  );
};

export default CalendarBadgeContent;
