import { ProductWithVariation } from "../../components/bookingProvider/BookingContext";
import { ProductOutput } from "../../graphql/schema";

const getProductByBookedProduct = (
  bookedProduct: ProductWithVariation,
  products: Partial<ProductOutput>[],
): any | undefined => {
  return products.find((product) => {
    return bookedProduct.variation
      ? product.id === bookedProduct.id &&
          product?.variations?.find(
            (variation: any) => variation.id === bookedProduct.variation,
          )
      : product.id === bookedProduct.id && !bookedProduct.variation;
  });
};

export default getProductByBookedProduct;
