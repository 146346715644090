import { Col, Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useBooking from "../../components/bookingProvider/useBooking";
import {
  CustomerType,
  useValidateCustomerEmailMutation,
} from "../../graphql/schema";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import { useLayout } from "../../components/layout/LayoutContext";
import OverviewCard from "../../components/overview/OverviewCard";
import BookingLayout from "../../components/layout/BookingLayout";
import ContactMobile from "../../components/register/ContactMobile";
import BottomBadge from "../../components/utils/BottomCard";
import ContactDesktop from "../../components/register/ContactDesktop";
import SubHeader from "../../components/base/SubHeader";
import getGTMCart from "../../helper/common/getGTMCart";
import useGTM from "../../components/gtmProvider/useGTM";
import { GTMEvent } from "../../components/gtmProvider/GTMContext";
import { LoginResult } from "../../customerAuth/customerAuthContext";
import getCustomerLoginOptions from "../../helper/common/getCustomerLoginOptions";

const mailRegex =
  /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;
const nameRegex = /^[a-zA-ZäöüßÄÖÜẞ\- ]{2,50}$/g;
const phoneRegex = /^\+?\d{7,12}$/;

const Register = () => {
  const { responsive } = useLayout();
  const { sendEvent } = useGTM();
  const { stateValues, setStates } = useBooking();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const watch = Form.useWatch([], form);
  const { isAuthenticated, signInWithEmail, signInWithPhone } =
    useCustomerAuth();
  const [validInput, setValidInput] = useState<boolean>(false);

  const [validateEmail] = useValidateCustomerEmailMutation();

  useEffect(() => {
    window.scroll(0, 0);
    if (stateValues.customer.firstName) {
      form.setFieldsValue({
        firstName: stateValues.customer.firstName,
        lastName: stateValues.customer.lastName,
        email: stateValues.customer.email,
        phone: stateValues.customer.phone.includes(" ")
          ? stateValues.customer.phone.split(" ")[1]
          : stateValues.customer.phone,
      });
    }
  }, []);

  useEffect(() => {
    sendEvent(GTMEvent.BOOKING_FUNNEL_APPOINTMENT_SELECTED, {
      appointment: {
        datetime: stateValues.appointment?.toISOString(),
        notify_about_earlier_possible_appointment:
          stateValues.notifyForEarlierAppointment,
      },
      cart_items: getGTMCart(stateValues),
      branch: {
        id: stateValues.store.id,
        name: stateValues.store.name,
      },
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
  }, []);

  useEffect(() => {
    if (stateValues.customer.id && isAuthenticated) navigate("/details");
  }, [isAuthenticated]);

  const isValidInput = (values: any) => {
    if (
      new RegExp(nameRegex).test(values.firstName) &&
      new RegExp(nameRegex).test(values.lastName) &&
      new RegExp(mailRegex).test(values.email) &&
      new RegExp(phoneRegex).test(values.phone)
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (watch) setValidInput(isValidInput(watch));
  }, [watch]);

  const handleLoginInfo = (loginResult: LoginResult, content?: string) => {
    switch (loginResult) {
      case LoginResult.successful:
        message.success("Erfolgreich angemeldet");
        break;
      case LoginResult.failed:
        message.error("Zugangsdaten ungültig");
        break;
      case LoginResult.mailSent:
        message.info(`${content}`, 15);
        break;
      default:
        break;
    }
  };

  const handleFinish = async (values: any) => {
    if (stateValues.customer.id && isAuthenticated) navigate("/details");
    try {
      const isValidEmail = await validateEmail({
        variables: {
          dto: {
            email: values.email,
          },
        },
      });
      if (!isValidEmail?.data?.validateEmail) {
        const loginResult = await signInWithEmail(values.email, undefined, {
          route: "register",
          ...getCustomerLoginOptions(stateValues),
        });
        if (loginResult === LoginResult.failed)
          return handleLoginInfo(LoginResult.failed);
        handleLoginInfo(
          loginResult,
          "Ein Benutzer mit dieser E-Mail existiert bereits. Ein Anmeldelink wurde an deine E-Mail gesendet.",
        );
        return null;
      }
      setStates({
        ...stateValues,
        customer: {
          id: undefined,
          firstName: values.firstName,
          lastName: values.lastName,
          category: CustomerType.Standard,
          email: values.email,
          phone: `${values.countryCode} ${values.phone}`,
          password: values.password,
        },
        additionalCustomers: [],
      });
      message.success("Kontaktdaten erfolgreich gespeichert.");
      navigate("/details");
    } catch (error) {
      message.error("Diese Mail ist bereits vergeben.");
    }
  };

  return (
    <BookingLayout
      content={responsive({
        desktop: <ContactDesktop form={form} />,
        tablet: <ContactMobile form={form} />,
        mobile: <ContactMobile form={form} />,
      })}
      subHeader={
        <SubHeader title="Kontaktdaten" onBack={() => navigate("/calendar")} />
      }
      progress={80}
      card={responsive({
        tablet: null,
        mobile: null,
        desktop: (
          <OverviewCard
            isValidated={validInput}
            tooltipTitle={
              !validInput
                ? "Trage deine Kontaktdaten ein, um fortzufahren."
                : ""
            }
            onContinue={() => handleFinish(form.getFieldsValue())}
          />
        ),
      })}
      bottomCard={
        validInput && (
          <BottomBadge
            content={<Col span={12} />}
            onContinue={() => handleFinish(form.getFieldsValue())}
          />
        )
      }
    />
  );
};

export default Register;
