import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Logo.module.scss";
import KaliaLogo from "../../assets/kalia-logo-white.png";
import useBooking from "../bookingProvider/useBooking";

interface Props {
  collapsed?: boolean;
  type?: "light" | "dark";
}

const Logo = ({ collapsed, type }: Props) => {
  const navigate = useNavigate();
  const { resetStates } = useBooking();
  return (
    <div
      onClick={() => {
        resetStates();
        navigate("/");
      }}
      className={[
        collapsed ? styles.collapsedLogo : styles.logo,
        type === "light" ? styles.light : styles.dark,
      ].join(" ")}
    >
      {!collapsed && <img src={KaliaLogo} alt="Kalia Lab" height={35} />}
      {collapsed && <img src={KaliaLogo} alt="Kalia Lab" height={35} />}
    </div>
  );
};

export default Logo;
