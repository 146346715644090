import { Spin, Typography } from "antd";
import { StoreOutput } from "../../graphql/schema";
import StoreCard from "./StoreCard";

interface Props {
  data: Partial<StoreOutput>[];
  loading: boolean;
  selectable?: boolean;
}

const StoreCards = ({ data, loading, selectable = true }: Props) => {
  return data.length ? (
    data.map((store: any) => (
      <StoreCard
        key={store.id}
        id={store.id}
        selectable={selectable}
        name={store.name}
        zipCode={store.zipCode}
        address={store.address}
        city={store.city}
      />
    ))
  ) : (
    <Typography.Text>
      {loading ? <Spin /> : "Es stehen keine Filialen zur Verfügung."}
    </Typography.Text>
  );
};

export default StoreCards;
