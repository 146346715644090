import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import {
  CalendarOutlined,
  CheckOutlined,
  EnvironmentOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  AppointmentStatus,
  ResourcePresenceType,
  useGetCustomerAppointmentQuery,
} from "../../graphql/schema";
import MobileOptionsDrawer from "./MobileOptionsDrawer";
import theme from "../../theme/theme";

const AppointmentTitle = ({
  id,
  storeId,
  status,
}: {
  id: number;
  storeId: number;
  status: AppointmentStatus;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title
          level={3}
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            margin: 0,
          }}
        >
          {status === AppointmentStatus.New ? (
            <>
              <QuestionCircleOutlined style={{ color: theme.colors.header }} />{" "}
              Terminanfrage
            </>
          ) : (
            <>
              <CheckOutlined style={{ color: theme.colors.header }} />
              Termin bestätigt
            </>
          )}
        </Typography.Title>
      </Col>
      <Col>
        <MoreOutlined
          style={{
            fontSize: 28,
            color: "#000000",
          }}
          onClick={() => setVisible(true)}
        />
        <MobileOptionsDrawer
          id={id}
          storeId={storeId}
          visible={visible}
          onClose={() => setVisible(false)}
        />
      </Col>
    </Row>
  );
};

interface Props {
  id: number;
}

const MobileAppointment = ({ id }: Props) => {
  const navigate = useNavigate();
  const { data: appointment, loading } = useGetCustomerAppointmentQuery({
    variables: {
      id,
    },
  });

  useEffect(() => {
    const status = appointment?.customerAppointment.appointmentStatus;
    const isValid =
      status === AppointmentStatus.Confirmed ||
      status === AppointmentStatus.ConfirmedWithVirtualPractitioner ||
      status === AppointmentStatus.New;
    if (
      (!loading && !appointment?.customerAppointment) ||
      (appointment?.customerAppointment && !isValid)
    ) {
      navigate("/");
    }
  }, [appointment]);

  if (!appointment) return null;
  return (
    <Row justify="center">
      <Col span={22}>
        <Row>
          <Col span={24}>
            <AppointmentTitle
              id={appointment.customerAppointment.id}
              storeId={appointment.customerAppointment.store.id}
              status={appointment.customerAppointment.appointmentStatus}
            />
          </Col>
          <Col span={24}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 15,
              }}
            >
              Datum & Uhrzeit
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              <Col span={1}>
                <CalendarOutlined
                  style={{
                    fontSize: 22,
                    color: theme.colors.text,
                  }}
                />
              </Col>
              <Col span={23}>
                <Row
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <Col span={24}>
                    <Typography.Text>
                      {dayjs
                        .utc(appointment.customerAppointment.dateFrom)
                        .tz("Europe/Berlin")
                        .format("HH:mm")}{" "}
                      -{" "}
                      {dayjs
                        .utc(appointment.customerAppointment.dateUntil)
                        .tz("Europe/Berlin")
                        .format("HH:mm")}{" "}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <Col span={24}>
                    <Typography.Text>
                      {dayjs
                        .utc(appointment.customerAppointment.dateFrom)
                        .tz("Europe/Berlin")
                        .format("ddd, D. MMM")}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 15,
              }}
            >
              Behandlungen
            </Typography.Title>
          </Col>
          {appointment.customerAppointment.products
            ?.filter((product) => !product.package?.id)
            .map((product, index) => (
              <Col span={24} key={product.id}>
                <Typography.Text>
                  {`${index + 1}. ${product.name} - ${product.bookedPrice
                    .toString()
                    .replace(".", ",")}€`}
                </Typography.Text>
              </Col>
            ))}
          <Col span={24}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 15,
              }}
            >
              Behandler
            </Typography.Title>
          </Col>
          {appointment.customerAppointment.practitioners
            ?.filter(
              (pracititoner) =>
                pracititoner.presenceType !== ResourcePresenceType.Virtual,
            )
            .map((practitioner) => (
              <Col span={24} key={practitioner.id}>
                <Row>
                  <Col span={1}>
                    <UserOutlined
                      style={{
                        fontSize: 22,
                        color: theme.colors.text,
                      }}
                    />
                  </Col>
                  <Col span={23}>
                    <Typography.Text>{`${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}`}</Typography.Text>
                  </Col>
                </Row>
              </Col>
            ))}
          <Col span={24}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 15,
              }}
            >
              Filiale
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center">
              <Col span={1}>
                <EnvironmentOutlined
                  size={40}
                  style={{
                    fontSize: 22,
                    color: theme.colors.text,
                  }}
                />
              </Col>
              <Col
                span={23}
                style={{
                  paddingLeft: 15,
                }}
              >
                <Typography.Text>
                  {appointment.customerAppointment.store?.name}
                </Typography.Text>
                <Row>
                  <Col span={24}>
                    <Typography.Text>
                      {`${appointment.customerAppointment.store?.address}, ${appointment.customerAppointment.store?.zipCode} ${appointment.customerAppointment.store?.city}`}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Title
              level={5}
              style={{
                marginTop: 15,
              }}
            >
              Preis
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Text>
              {appointment?.customerAppointment?.bookedPrice
                ?.toString()
                ?.replace(".", ",")}
              €
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MobileAppointment;
