import { Button, Card, Col, Form, Input, message, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formItemProps from "../helper/form/formItemProps";
import { useSetCustomerPasswordMutation } from "../graphql/schema";
import { useLayout } from "../components/layout/LayoutContext";
import theme from "../theme/theme";
import { useLogin } from "../components/login/LoginContext";

const NewPassword = () => {
  const { responsive } = useLayout();
  const { openLogin } = useLogin();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const [created, setCreated] = useState<boolean>(false);

  const [setPassword] = useSetCustomerPasswordMutation();

  const handleSubmit = async () => {
    const { password } = form.getFieldsValue();
    if (!token || !password) return;
    try {
      await setPassword({
        variables: {
          dto: {
            token,
            password,
          },
        },
      });
      setCreated(true);
    } catch (error) {
      message.error("Etwas ist schief gelaufen. Bitte versuche es erneut.");
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const urlToken = url.searchParams.get("token");
    if (urlToken) setToken(urlToken);
  }, []);

  if (created)
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col
          span={responsive({
            mobile: 24,
            tablet: 10,
            desktop: 6,
          })}
        >
          <Card
            style={{
              borderRadius: 15,
              border: responsive({
                mobile: "none",
                tablet: `1px solid ${theme.colors.text}`,
                desktop: `1px solid ${theme.colors.text}`,
              }),
            }}
          >
            <Row justify="center">
              <Col
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <Typography.Title level={4}>
                  Dein Passwort wurde erfolgreich geändert
                </Typography.Title>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 10,
              }}
            >
              <Col span={24}>
                <Button
                  style={{
                    height: 49,
                    width: "100%",
                    bottom: "0px",
                    borderRadius: "15px",
                    backgroundColor: theme.colors.button,
                  }}
                  onClick={async () => openLogin()}
                >
                  <Typography.Text
                    style={{
                      color: "white",
                    }}
                  >
                    Anmelden
                  </Typography.Text>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col
        span={responsive({
          mobile: 24,
          tablet: 10,
          desktop: 10,
        })}
      >
        <Card
          style={{
            borderRadius: 15,
            border: responsive({
              mobile: "none",
              tablet: `1px solid ${theme.colors.text}`,
              desktop: `1px solid ${theme.colors.text}`,
            }),
          }}
        >
          <Row justify="center">
            <Col span={24}>
              <Typography.Title
                level={3}
                style={{
                  textAlign: "center",
                }}
              >
                Neues Passwort wählen
              </Typography.Title>
            </Col>
          </Row>
          <Form form={form} layout="vertical">
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col span={24}>
                <Form.Item
                  style={{
                    fontWeight: "bold",
                  }}
                  {...formItemProps("password", "Neues Passwort")}
                >
                  <Input.Password
                    size="large"
                    iconRender={(visible) =>
                      visible ? (
                        <Typography.Text
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Verstecken
                        </Typography.Text>
                      ) : (
                        <Typography.Text
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Anzeigen
                        </Typography.Text>
                      )
                    }
                    type="password"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  style={{
                    height: 49,
                    width: "100%",
                    bottom: "0px",
                    borderRadius: "15px",
                    backgroundColor: theme.colors.button,
                  }}
                  onClick={async () => handleSubmit()}
                >
                  <Typography.Text
                    style={{
                      color: "white",
                    }}
                  >
                    Erstellen
                  </Typography.Text>
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default NewPassword;
