import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Typography,
} from "antd";
import { RightOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import formItemProps from "../../helper/form/formItemProps";
import { useLayout } from "../layout/LayoutContext";
import { useLogin } from "../login/LoginContext";
import useBooking from "../bookingProvider/useBooking";
import SelectCountryCode from "./SelectCountryCode";

const nameRegex = /^[a-zA-ZäöüßÄÖÜẞ\- ]{2,50}$/g;
const phoneRegex = /^\+?\d{7,12}$/;
const mailRegex =
  /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;

interface Props {
  form: FormInstance;
}

const ContactMobile = ({ form }: Props) => {
  const { responsive } = useLayout();
  const { openLogin } = useLogin();
  const { stateValues } = useBooking();
  return (
    <div
      style={{
        paddingBottom: "100px",
      }}
    >
      <Row
        style={{
          width: "100%",
        }}
      >
        <Col span={24}>
          <Button
            onClick={openLogin}
            type="text"
            style={{
              backgroundColor: "#fff",
              width: "100%",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Row
              justify="space-between"
              style={{
                width: "100%",
              }}
            >
              <Col span={1}>
                <UserOutlined
                  style={{
                    fontSize: "20px",
                  }}
                />
              </Col>
              <Col
                span={23}
                style={{
                  paddingLeft: 10,
                }}
              >
                <Row justify="space-between">
                  <Col>
                    <Typography.Text strong>Melde dich an</Typography.Text>
                  </Col>
                  <Col span={2}>
                    <RightOutlined />
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: 5,
                  }}
                >
                  <Col span={24}>
                    <Divider
                      style={{
                        margin: 0,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{
          marginTop: 20,
        }}
      >
        <Col
          span={responsive({
            tablet: 24,
            mobile: 24,
          })}
        >
          <Typography.Text
            style={{
              marginRight: "10px",
            }}
          >
            ODER
          </Typography.Text>
          trage deine Kontaktdaten ein:
          <Typography.Text />
        </Col>
      </Row>
      <Form
        layout="vertical"
        initialValues={{
          firstName: stateValues.customer.firstName,
          lastName: stateValues.customer.lastName,
          email: stateValues.customer.email,
          phone: stateValues.customer.phone.includes(" ")
            ? stateValues.customer.phone.split(" ")[1]
            : "",
          password: stateValues.customer.password,
        }}
        style={{
          paddingBottom: 20,
          paddingTop: 20,
        }}
        form={form}
      >
        <Row
          gutter={responsive({
            tablet: [10, 24],
            mobile: [0, 24],
          })}
        >
          <Col
            span={responsive({
              tablet: 12,
              mobile: 24,
            })}
          >
            <Form.Item
              style={{
                fontWeight: "bold",
                marginBottom: 0,
              }}
              {...formItemProps("firstName", "Vorname", true)}
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    return value.length < 50
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Die maximale Länge beträgt 50 Zeichen.",
                },
                {
                  required: true,
                  validator: (rule, value) => {
                    return new RegExp(nameRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Bitte trage deinen Vornamen ein.",
                },
              ]}
            >
              <Input
                size="large"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>

          <Col
            span={responsive({
              tablet: 12,
              mobile: 24,
            })}
          >
            <Form.Item
              style={{
                fontWeight: "bold",
                marginBottom: 0,
              }}
              {...formItemProps("lastName", "Nachname", true)}
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    return value.length < 50
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Die maximale Länge beträgt 50 Zeichen.",
                },
                {
                  required: true,
                  validator: (rule, value) => {
                    return new RegExp(nameRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Bitte trage deinen Nachnamen ein.",
                },
              ]}
            >
              <Input
                size="large"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>

          <Col
            span={responsive({
              tablet: 12,
              mobile: 24,
            })}
          >
            <Form.Item
              style={{
                fontWeight: "bold",
                marginBottom: 0,
              }}
              {...formItemProps("email", "Email", true)}
              rules={[
                {
                  required: true,
                  type: "email",
                  validator: (rule, value) => {
                    return new RegExp(mailRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Bitte trage deine E-Mail ein.",
                },
              ]}
            >
              <Input
                size="large"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>
          <Col
            span={responsive({
              tablet: 12,
              mobile: 24,
            })}
          >
            <Form.Item
              style={{
                fontWeight: "bold",
                marginBottom: 0,
              }}
              {...formItemProps("phone", "Mobilnummer", true)}
              rules={[
                {
                  required: true,
                  message: "Bitte trage deine Mobilnummer ein.",
                  validator: (rule, value) => {
                    return new RegExp(phoneRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                },
              ]}
            >
              <Input
                size="large"
                addonBefore={
                  <Form.Item
                    style={{
                      minWidth: 80,
                    }}
                    name={["countryCode"]}
                    noStyle
                    initialValue="+49"
                  >
                    <SelectCountryCode />
                  </Form.Item>
                }
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>

          <Col
            span={responsive({
              tablet: 12,
              mobile: 24,
            })}
          >
            <Form.Item
              style={{
                fontWeight: "bold",
                marginBottom: 0,
              }}
              {...formItemProps(
                "password",
                "Password (Wenn Du einen Account anlegen möchtest)",
                false,
              )}
            >
              <Input.Password
                size="large"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContactMobile;
