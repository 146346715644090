import { Col, Row, Typography } from "antd";
import React, { useMemo } from "react";
import { Dayjs } from "dayjs";
import SlotsContainer from "./SlotsContainer";
import { useLayout } from "../layout/LayoutContext";

interface Props {
  dates: Dayjs[];
  selectedDate: Dayjs | undefined;
}

const SlotCard = ({ dates, selectedDate }: Props) => {
  const { responsive } = useLayout();

  const datesForDay = useMemo(() => {
    if (!selectedDate) return [];
    return (
      dates?.filter((date) => date.tz().isSame(selectedDate, "date")) || []
    );
  }, [dates, selectedDate]);

  const timeSlots = [
    {
      label: "Vormittags",
      startHour: 6,
      endHour: 14,
      timeOfDay: "Morning",
    },
    {
      label: "Nachmittags",
      startHour: 14,
      endHour: 17,
      timeOfDay: "Nachmittags",
    },
    {
      label: "Abends",
      startHour: 17,
      endHour: 23,
      timeOfDay: "Abends",
    },
  ];

  const colStyle = {
    textAlign: "center" as const,
  };

  const textStyle = {
    width: "100%",
    textAlign: "center" as const,
  };

  return (
    <Row
      justify="center"
      style={{
        marginBottom: 100,
        marginTop: responsive({
          desktop: "0",
          tablet: 20,
          mobile: 20,
        }),
      }}
    >
      {timeSlots.map((slot) => (
        <Col key={slot.timeOfDay} span={8} style={colStyle}>
          <Typography.Text strong style={textStyle}>
            {slot.label}
          </Typography.Text>

          <SlotsContainer
            startHour={slot.startHour}
            endHour={slot.endHour}
            timeOfDay={slot.timeOfDay}
            dates={datesForDay}
            selectedDate={selectedDate}
          />
        </Col>
      ))}
    </Row>
  );
};

export default SlotCard;
