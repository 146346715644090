import React from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import getPriceForCustomer from "../../helper/common/getPriceForCustomer";
import useBooking from "../bookingProvider/useBooking";
import theme from "../../theme/theme";

interface Props {
  open: boolean;
  name: string;
  totalDuration: number;
  prices: any;
  add: () => void;
  onClose: () => void;
  description?: string;
}

const CardModal = ({
  name,
  totalDuration,
  prices,
  add,
  open,
  onClose,
  description,
}: Props) => {
  const { stateValues } = useBooking();
  return (
    <Modal
      centered
      width={400}
      footer={null}
      open={open}
      okText="Hinzufügen"
      closable
      closeIcon={<CloseOutlined color="#000000" width={10} />}
      cancelText="Abbrechen"
      onCancel={onClose}
      onOk={() => {
        add();
        onClose();
      }}
    >
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Typography.Title level={3}>{name}</Typography.Title>
        </Col>
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Typography.Text>
            {totalDuration} Min.{" "}
            {getPriceForCustomer(prices, stateValues.customer)
              .toFixed(2)
              .replace(".", ",")}{" "}
            €
          </Typography.Text>
        </Col>
        <Col
          span={24}
          style={{
            textAlign: "center",
            marginTop: 20,
          }}
        >
          <Typography.Text>{description || ""}</Typography.Text>
        </Col>
        <Col
          span={24}
          style={{
            marginTop: 20,
          }}
        >
          <Button
            style={{
              backgroundColor: theme.colors.button,
              width: "100%",
              height: "49px",
              bottom: "0px",
              borderRadius: "15px",
            }}
            onClick={() => {
              add();
              onClose();
            }}
          >
            <Typography.Title
              level={5}
              style={{
                color: "white",
                marginBottom: "0px",
              }}
            >
              Hinzufügen
            </Typography.Title>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CardModal;
