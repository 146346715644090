import { Col, Row, Typography } from "antd";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBooking from "../bookingProvider/useBooking";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useGTM from "../gtmProvider/useGTM";
import { GTMEvent } from "../gtmProvider/GTMContext";
import theme from "../../theme/theme";

interface Props {
  id: number;
  name: string;
  address: string;
  city: string;
  zipCode: string;
  selectable?: boolean;
}

const StoreCard = ({ id, name, address, selectable, city, zipCode }: Props) => {
  const { isAuthenticated } = useCustomerAuth();
  const { sendEvent } = useGTM();
  const { stateValues, setStates } = useBooking();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const onSelect = useCallback(() => {
    setStates({
      ...stateValues,
      store: {
        id,
        name,
      },
    });
    sendEvent(GTMEvent.BOOKING_FUNNEL_BRANCH_SELECTED, {
      branch: {
        id,
        name,
      },
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
    if (!selectable) {
      navigate("/services");
    }
  }, []);

  return (
    <Row
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        marginTop: "20px",
        padding: "20px",
        borderRadius: "15px",
        cursor: "pointer",
        backgroundColor:
          selectable && stateValues?.store.id === id
            ? "#A9A5A5"
            : isHovered
            ? "#EAE9E6"
            : "#F6F6F4",
      }}
      onClick={() => {
        onSelect();
      }}
    >
      <Col>
        <Row>
          <Col>
            <Typography.Text
              strong
              style={{
                color:
                  selectable && stateValues?.store.id === id
                    ? theme.colors.background
                    : theme.colors.text,
              }}
            >
              {name}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text
              style={{
                color:
                  selectable && stateValues?.store.id === id
                    ? theme.colors.background
                    : theme.colors.text,
              }}
            >
              {address}, {zipCode} {city}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StoreCard;
