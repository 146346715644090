import React, { useContext, useLayoutEffect, useMemo, useState } from "react";

export enum Layout {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  TABLET = "tablet",
}

const getDeviceConfig = (width: number) => {
  if (width < 680) {
    return Layout.MOBILE;
  }
  if (width >= 680 && width < 1200) {
    return Layout.TABLET;
  }
  return Layout.DESKTOP;
};

export const LayoutContext = React.createContext({
  device: Layout.DESKTOP,
  responsive: <D, T, M>(choices: {
    desktop?: D;
    tablet?: T;
    mobile: M;
  }): D | T | M => {
    throw new Error(
      "Responsive function must be called within a LayoutProvider",
    );
  },
});

export const useLayout = () => useContext(LayoutContext);

interface Props {
  children: React.ReactNode;
}

export const LayoutProvider = ({ children }: Props) => {
  const [device, setDevice] = useState(getDeviceConfig(window.outerWidth));

  useLayoutEffect(() => {
    const handleResize = () => {
      setDevice(getDeviceConfig(window.innerWidth));
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const responsive = <D, T, M>(choices: {
    desktop?: D;
    tablet?: T;
    mobile: M;
  }): D | T | M => {
    if (device === Layout.DESKTOP && choices.desktop) return choices.desktop;
    if (device === Layout.TABLET && choices.tablet) return choices.tablet;
    return choices.mobile;
  };

  const providerValue = useMemo(() => {
    return {
      responsive,
      device,
    };
  }, [device]);

  return (
    <LayoutContext.Provider value={providerValue}>
      {children}
    </LayoutContext.Provider>
  );
};
