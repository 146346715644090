import React, { ComponentProps, useState } from "react";
import { Col, Row } from "antd";
import { Layout, useLayout } from "../layout/LayoutContext";
import useProductCard from "./useProductCard";
import CardTitle from "./CardTitle";
import CardButton from "./CardButton";
import CardModal from "./CardModal";
import CardDrawer from "./CardDrawer";
import "./ProductCard.scss";

interface CardProps extends ComponentProps<typeof Col> {
  singleProduct: any;
  productId: number;
  products: any;
  variationId?: number | null;
  description?: string;
  searchValue?: string;
  spinner?: boolean;
}

export const Card = ({
  singleProduct,
  productId,
  products,
  searchValue,
  description,
  variationId = null,
}: CardProps) => {
  const { responsive, device } = useLayout();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { addProduct, remove, getProductQuantity } = useProductCard({
    products,
    product: singleProduct,
    baseProductId: productId,
    variationId,
  });

  if (
    searchValue &&
    !singleProduct.name.toLowerCase().includes(searchValue.toLowerCase())
  )
    return null;
  return (
    <Col span={24}>
      <Row
        align="middle"
        style={{
          width: device === Layout.MOBILE ? "100%" : "95%",
          cursor: "pointer",
          marginTop: "24px",
          padding: "20px",
          minHeight: 105,
          backgroundColor: "#F6F6F4",
          borderRadius: "10px",
          justifyContent: "space-between",
        }}
        className="productCard"
        onClick={() => setDrawerVisible(true)}
      >
        <Col span={20}>
          <CardTitle
            name={singleProduct.name}
            totalDuration={singleProduct.totalDuration}
            prices={singleProduct.prices}
          />
        </Col>
        <Col span={4}>
          <CardButton
            amount={getProductQuantity(productId, variationId)}
            add={() => addProduct(productId, variationId)}
            remove={() => remove(productId, variationId)}
          />
        </Col>
      </Row>
      {responsive({
        desktop: (
          <CardModal
            open={drawerVisible}
            name={singleProduct.name}
            totalDuration={singleProduct.totalDuration}
            prices={singleProduct.prices}
            add={() => addProduct(productId, variationId)}
            onClose={() => setDrawerVisible(false)}
            description={description}
          />
        ),
        tablet: (
          <CardModal
            open={drawerVisible}
            name={singleProduct.name}
            totalDuration={singleProduct.totalDuration}
            prices={singleProduct.prices}
            add={() => addProduct(productId, variationId)}
            onClose={() => setDrawerVisible(false)}
            description={description}
          />
        ),
        mobile: (
          <CardDrawer
            open={drawerVisible}
            name={singleProduct.name}
            totalDuration={singleProduct.totalDuration}
            prices={singleProduct.prices}
            add={() => addProduct(productId, variationId)}
            onClose={() => setDrawerVisible(false)}
            description={description}
          />
        ),
      })}
    </Col>
  );
};

export default Card;
