import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAfter } from "date-fns";
import BookingContext, { BookingInterface } from "./BookingContext";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import { CustomerType, useGetOwnCustomerQuery } from "../../graphql/schema";
import Loading from "../base/Loading";

interface BookingProviderProps {
  children: React.ReactNode;
}

const BookingProvider = ({ children }: BookingProviderProps) => {
  const navigate = useNavigate();
  const { isAuthenticated, options } = useCustomerAuth();
  const {
    data: customer,
    refetch,
    loading,
  } = useGetOwnCustomerQuery({
    skip: !isAuthenticated,
    fetchPolicy: "no-cache",
  });
  const [bookingStates, setBookingStates] = useState<BookingInterface>({
    store: {
      id: undefined,
      name: "",
    },
    bookedProducts: [],
    couponCodes: [],
    notifyForEarlierAppointment: false,
    appointment: null,
    customer: {
      id: customer?.ownCustomer?.id || undefined,
      firstName: customer?.ownCustomer?.firstName || "",
      lastName: customer?.ownCustomer?.lastName || "",
      category: customer?.ownCustomer?.category || CustomerType.Standard,
      email: customer?.ownCustomer?.email || "",
      phone: customer?.ownCustomer?.phone || "",
      password: "",
    },
    preferredPaymentMethod: null,
    additionalCustomers: [],
    message: "",
  });

  useEffect(() => {
    const booking: any = localStorage.getItem("bookingStates");
    // localStorage.setItem("bookingStates", JSON.stringify(bookingStates));
    if (booking) {
      const parsed = JSON.parse(booking);
      setBookingStates({
        ...parsed,
        appointment: parsed?.appointment ? new Date(parsed.appointment) : null,
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && options) {
      if (isAfter(options.exp, new Date())) {
        setBookingStates((prevState) => ({
          ...prevState,
          ...{
            ...options,
            appointment: options?.appointment
              ? new Date(options.appointment)
              : null,
          },
        }));
        localStorage.setItem(
          "bookingStates",
          JSON.stringify({
            ...bookingStates,
            ...{
              ...options,
              appointment: new Date(options.appointment) || null,
            },
          }),
        );
        if (options?.route) {
          navigate(options.route);
        }
      }
    }
  }, [isAuthenticated]);

  const providerValue = useMemo(() => {
    const setStates = (values: Partial<BookingInterface>): void => {
      setBookingStates((prevState) => ({
        ...prevState,
        ...values,
      }));
      localStorage.setItem(
        "bookingStates",
        JSON.stringify({ ...bookingStates, ...values }),
      );
    };

    const clearCustomer = (): void => {
      const resetBookingStates: BookingInterface = {
        store: {
          id: undefined,
          name: "",
        },
        bookedProducts: [],
        couponCodes: [],
        notifyForEarlierAppointment: false,
        appointment: null,
        customer: {
          id: undefined,
          firstName: "",
          lastName: "",
          category: CustomerType.Standard,
          email: "",
          phone: "",
          password: "",
        },
        preferredPaymentMethod: null,
        additionalCustomers: [],
        message: "",
      };

      setBookingStates(resetBookingStates);
      localStorage.setItem("bookingStates", JSON.stringify(resetBookingStates));
    };

    const resetStates = (): void => {
      const resetBookingStates: BookingInterface = {
        store: {
          id: undefined,
          name: "",
        },
        bookedProducts: [],
        couponCodes: [],
        notifyForEarlierAppointment: false,
        appointment: null,
        customer: {
          id: customer?.ownCustomer?.id || undefined,
          firstName: customer?.ownCustomer?.firstName || "",
          lastName: customer?.ownCustomer?.lastName || "",
          category: customer?.ownCustomer?.category || CustomerType.Standard,
          email: customer?.ownCustomer?.email || "",
          phone: customer?.ownCustomer?.phone || "",
          password: "",
        },
        preferredPaymentMethod: null,
        additionalCustomers: [],
        message: "",
      };

      setBookingStates(resetBookingStates);
      localStorage.setItem("bookingStates", JSON.stringify(resetBookingStates));
    };

    const getStates = (
      keys: (keyof BookingInterface)[],
    ): Partial<BookingInterface> => {
      const stateValues: Partial<BookingInterface> = {};
      if (keys.includes("bookedProducts"))
        stateValues.bookedProducts = bookingStates.bookedProducts;
      if (keys.includes("couponCodes"))
        stateValues.couponCodes = bookingStates.couponCodes;
      if (keys.includes("customer"))
        stateValues.customer = bookingStates.customer;
      return stateValues;
    };

    const refetchCustomer = () => {
      refetch();
    };

    return {
      stateValues: {
        ...bookingStates,
        customer: {
          id:
            customer?.ownCustomer?.id ||
            bookingStates?.customer?.id ||
            undefined,
          firstName:
            customer?.ownCustomer?.firstName ||
            bookingStates.customer.firstName ||
            "",
          lastName:
            customer?.ownCustomer?.lastName ||
            bookingStates.customer.lastName ||
            "",
          category:
            customer?.ownCustomer?.category ||
            bookingStates.customer.category ||
            CustomerType.Standard,
          email:
            customer?.ownCustomer?.email || bookingStates.customer.email || "",
          phone:
            customer?.ownCustomer?.phone || bookingStates.customer.phone || "",
          password: bookingStates.customer.password || "",
        },
      },
      setStates,
      getStates,
      resetStates,
      clearCustomer,
      refetchCustomer,
    };
  }, [bookingStates, customer, isAuthenticated]);
  if (loading) return <Loading />;
  return (
    <BookingContext.Provider value={providerValue}>
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
