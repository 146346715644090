import { useMemo } from "react";
import GTMContext, { GTMEvent } from "./GTMContext";

interface GTMProviderProps {
  children: React.ReactNode;
}

const GTMProvider = ({ children }: GTMProviderProps) => {
  const preventDuplicateEvent = (name: GTMEvent) => {
    const doubleEvents = [
      GTMEvent.BOOKING_FUNNEL_INIT,
      GTMEvent.BOOKING_FUNNEL_SERVICE_SELECTION,
      GTMEvent.BOOKING_FUNNEL_APPOINTMENT_SELECTION,
      GTMEvent.BOOKING_FUNNEL_APPOINTMENT_SELECTED,
      GTMEvent.BOOKING_FUNNEL_CONTACT_DETAILS,
    ];
    const lastEvent = window.dataLayer?.[window.dataLayer.length - 1];
    return doubleEvents.includes(lastEvent.event) && lastEvent.event === name;
  };

  const providerValue = useMemo(() => {
    const sendEvent = (name: GTMEvent, data: any) => {
      if (preventDuplicateEvent(name)) return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: name,
        ...data,
      });
    };

    return {
      sendEvent,
    };
  }, []);
  return (
    <GTMContext.Provider value={providerValue}>{children}</GTMContext.Provider>
  );
};

export default GTMProvider;
