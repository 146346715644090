import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useBooking from "../../components/bookingProvider/useBooking";
import BookingLayout from "../../components/layout/BookingLayout";
import CalendarContainer from "../../components/calendar/CalendarContainer";
import OverviewCard from "../../components/overview/OverviewCard";
import { useLayout } from "../../components/layout/LayoutContext";
import CalendarBadgeContent from "../../components/calendar/CalendarBadgeContent";
import BottomBadge from "../../components/utils/BottomCard";
import getGTMCart from "../../helper/common/getGTMCart";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useGTM from "../../components/gtmProvider/useGTM";
import { GTMEvent } from "../../components/gtmProvider/GTMContext";

const CalendarSelection = () => {
  const { sendEvent } = useGTM();
  const { isAuthenticated } = useCustomerAuth();
  const { responsive } = useLayout();
  const { stateValues } = useBooking();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "reset_items",
      items: null,
    });
    sendEvent(GTMEvent.BOOKING_FUNNEL_APPOINTMENT_SELECTION, {
      cart_items: getGTMCart(stateValues),
      branch: {
        id: stateValues.store.id,
        name: stateValues.store.name,
      },
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
  }, []);

  return (
    <BookingLayout
      progress={60}
      content={<CalendarContainer />}
      card={responsive({
        tablet: null,
        mobile: null,
        desktop: (
          <OverviewCard
            isValidated={!!stateValues.appointment}
            tooltipTitle={
              !stateValues?.appointment
                ? "Wähle ein Datum und eine Uhrzeit aus"
                : ""
            }
            onContinue={() => navigate("/register")}
          />
        ),
      })}
      bottomCard={
        stateValues.appointment && (
          <BottomBadge content={<CalendarBadgeContent />} to="/register" />
        )
      }
    />
  );
};

export default CalendarSelection;
