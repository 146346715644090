import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { useLocation } from "react-router-dom";
import { LoginResult } from "../../customerAuth/customerAuthContext";
import formItemProps from "../../helper/form/formItemProps";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useBooking from "../bookingProvider/useBooking";
import { useLogin } from "./LoginContext";
import theme from "../../theme/theme";
import getCustomerLoginOptions from "../../helper/common/getCustomerLoginOptions";

const LoginModal = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { openResetPassword, closeLogin, loginOpen } = useLogin();
  const { refetchCustomer, stateValues } = useBooking();
  const { signInWithEmail, signInWithPhone } = useCustomerAuth();

  const handleLoginInfo = (loginResult: LoginResult) => {
    switch (loginResult) {
      case LoginResult.successful:
        message.success("Erfolgreich angemeldet");
        break;
      case LoginResult.failed:
        message.error("Zugangsdaten ungültig");
        break;
      case LoginResult.mailSent:
        message.info("Mail wurde gesendet");
        break;
      default:
        break;
    }
  };

  const handleLogin = async () => {
    const { email, phone, password } = await form.validateFields();
    if (!email) {
      message.error("Bitte gib deine E-Mail ein.");
      return;
    }
    const loginResult = email
      ? await signInWithEmail(email, password, {
          route: location.pathname,
          ...getCustomerLoginOptions(stateValues),
        })
      : await signInWithPhone(phone, password, {
          route: location.pathname,
          ...getCustomerLoginOptions(stateValues),
        });
    if (loginResult === LoginResult.failed)
      return handleLoginInfo(LoginResult.failed);
    handleLoginInfo(loginResult);
    await refetchCustomer();
    closeLogin();
  };

  return (
    <Modal
      centered
      width={400}
      title=""
      open={loginOpen}
      onOk={() => form.submit()}
      okText="Anmelden"
      onCancel={() => closeLogin()}
      footer={
        <Row>
          <Col span={24}>
            <Button
              style={{
                width: "100%",
                height: 49,
                borderRadius: 15,
                backgroundColor: theme.colors.button,
              }}
              onClick={() => handleLogin()}
            >
              <Typography.Text
                strong
                style={{
                  color: "white",
                }}
              >
                Anmelden
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      }
    >
      <Form form={form} layout="vertical" onFinish={() => handleLogin()}>
        <Row gutter={[0, 26]}>
          <Col span={24}>
            <Typography.Title
              level={4}
              style={{
                textAlign: "center",
                margin: 0,
              }}
            >
              Anmelden
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Text strong>E-Mail</Typography.Text>
            <Form.Item
              {...formItemProps("email", "", false)}
              rules={[
                {
                  required: false,
                  message: "Bitte gib deine E-Mail ein.",
                },
              ]}
            >
              <Input autoFocus />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Typography.Text strong>Passwort</Typography.Text>
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col>
                <Typography.Text
                  style={{
                    cursor: "pointer",
                  }}
                  type="secondary"
                  onClick={async () => {
                    await handleLogin();
                  }}
                >
                  Anmeldelink senden
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              {...formItemProps("password", "", false)}
              rules={[
                {
                  required: false,
                  message: "Bitte gib dein Passwort an!",
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? (
                    <Typography.Text
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Verstecken
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Anzeigen
                    </Typography.Text>
                  )
                }
                type="password"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default LoginModal;
