import React from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "./components/base/Loading";
import ProductsSelection from "./pages/bookingwidget/ProductsSelection";
import CalendarSelection from "./pages/bookingwidget/CalendarSelection";
import BookingDetails from "./pages/bookingwidget/BookingDetails";
import Register from "./pages/bookingwidget/Register";
import useCustomerAuth from "./customerAuth/useCustomerAuth";
import Appointments from "./pages/Appointments";
import StoreSelection from "./pages/bookingwidget/StoreSelection";
import BaseLayout from "./components/layout/BaseLayout";
import ForgotPassword from "./pages/ForgotPassword";
import BookingConfirmation from "./pages/bookingwidget/BookingConfirmation";
import NewPassword from "./pages/NewPassword";
import ResetPassword from "./pages/ResetPassword";
import Login from "./pages/Login";
import Appointment from "./pages/Appointment";
import AppRedirection from "./pages/AppRedirection";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const App = () => {
  const { authIsLoading, isAuthenticated } = useCustomerAuth();

  if (authIsLoading) return <Loading />;

  return isAuthenticated ? (
    <Routes>
      <Route element={<BaseLayout />} path="/">
        <Route element={<ResetPassword />} path="/reset-password" />
        <Route element={<Appointments />} path="/appointments" />
        <Route element={<NewPassword />} path="/setPassword" />
        <Route element={<Login />} path="/login" />
        <Route element={<StoreSelection />} path="/" />
        <Route element={<ProductsSelection />} path="services" />
        <Route element={<CalendarSelection />} path="calendar" />
        <Route element={<Register />} path="register" />
        <Route element={<BookingDetails />} path="details" />
        <Route element={<BookingConfirmation />} path="confirmation" />
        <Route element={<Appointment />} path="/appointment" />
        <Route element={<AppRedirection />} path="/login-app" />
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route element={<BaseLayout />} path="/">
        <Route element={<ResetPassword />} path="/reset-password" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<NewPassword />} path="/setPassword" />
        <Route element={<Login />} path="/login" />
        <Route element={<StoreSelection />} path="/" />
        <Route element={<ProductsSelection />} path="services" />
        <Route element={<CalendarSelection />} path="calendar" />
        <Route element={<Register />} path="register" />
        <Route element={<BookingDetails />} path="details" />
        <Route element={<BookingConfirmation />} path="confirmation" />
        <Route element={<Appointment />} path="/appointment" />
        <Route element={<AppRedirection />} path="/login-app" />
      </Route>
    </Routes>
  );
};

export default App;
