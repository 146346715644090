import { Button, Col, Row } from "antd";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import theme from "../../theme/theme";

const CardButton = ({ amount, add, remove }: any) => {
  return (
    <Row align="middle" justify="end">
      <Col>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              display: "block",
              width: 42,
              height: 36,
              borderWidth: 0,
              padding: 0,
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
              borderBottomLeftRadius: 15,
              backgroundColor: (() => {
                return amount > 0 ? theme.colors.text : theme.colors.background;
              })(),
            }}
            onClick={(event) => {
              event.stopPropagation();
              return amount > 0 ? remove() : add();
            }}
          >
            {amount > 0 ? (
              <CheckOutlined
                style={{
                  fontSize: 18,
                  color: theme.colors.background,
                }}
              />
            ) : (
              <PlusOutlined
                style={{
                  width: "100%",
                  fontSize: 18,
                  color: theme.colors.text,
                }}
              />
            )}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default CardButton;
