import React from "react";

export enum LoginResult {
  successful,
  failed,
  mailSent,
}

interface CustomerAuthContextProps {
  authIsLoading: boolean;
  isAuthenticated: boolean;
  getAuthToken: () => Promise<string>;
  signInWithEmail: (
    email: string,
    password?: string,
    options?: any,
  ) => Promise<LoginResult>;
  signInWithPhone: (
    phone: string,
    password?: string,
    options?: any,
  ) => Promise<LoginResult>;
  logout: () => void;
  updateTokens: (token: string) => void;
  roles: string[];
  options?: any;
}

export const CustomerAuthContext =
  React.createContext<CustomerAuthContextProps>({
    authIsLoading: true,
    isAuthenticated: false,
    signInWithEmail: async () => LoginResult.failed,
    signInWithPhone: async () => LoginResult.failed,
    logout: () => null,
    getAuthToken: async () => "",
    updateTokens: () => null,
    roles: [],
    options: {},
  });
