import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBooking from "../../components/bookingProvider/useBooking";
import {
  useGetPublicProductCategoriesQuery,
  useGetPublicStoreQuery,
  useGetPublicStoresQuery,
  useValidateProductSelectionQuery,
} from "../../graphql/schema";
import Loading from "../../components/base/Loading";
import { useLayout } from "../../components/layout/LayoutContext";
import BookingLayout from "../../components/layout/BookingLayout";
import OverviewCard from "../../components/overview/OverviewCard";
import ProductsContainer from "../../components/products/ProductsContainer";
import BottomBadge from "../../components/utils/BottomCard";
import ProductsBadgeContent from "../../components/products/ProductsBadgeContent";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import getProductByBookedProduct from "../../helper/common/getProductByBookedProduct";
import useGTM from "../../components/gtmProvider/useGTM";
import { GTMEvent } from "../../components/gtmProvider/GTMContext";

const ProductsSelection = () => {
  const { isAuthenticated } = useCustomerAuth();
  const { responsive } = useLayout();
  const { sendEvent } = useGTM();
  const { stateValues, setStates } = useBooking();
  const [productsAreValid, setProductsAreValid] = useState(false);
  const navigate = useNavigate();
  const { data: stores } = useGetPublicStoresQuery({
    fetchPolicy: "no-cache",
  });
  const { data: store } = useGetPublicStoreQuery({
    variables: {
      id: stateValues.store.id ?? Number.MIN_SAFE_INTEGER,
    },
    fetchPolicy: "no-cache",
    skip: !stateValues.store.id,
  });
  const { data: productCategories } = useGetPublicProductCategoriesQuery();

  useValidateProductSelectionQuery({
    variables: {
      store: stateValues.store.id!,
      products: stateValues.bookedProducts.map((p) => ({
        id: p.id,
        variation: p.variation,
        price: p.price,
      })),
    },
    fetchPolicy: "no-cache",
    skip: !stateValues.bookedProducts?.length || !stateValues.store.id,
    onCompleted: (data) => {
      const validProducts = data.validateProductSelection?.products || [];
      setStates({
        ...stateValues,
        bookedProducts: validProducts.map((p) => {
          const baseProduct = getProductByBookedProduct(
            p,
            store?.publicStore?.products || [],
          );

          const variation = baseProduct?.variations?.find(
            (v) => v.id === p.variation,
          );

          const taxRate = baseProduct?.taxRate || 0;
          const netPrice = Number(
            Number(p.price) / (1 + taxRate / 100),
          ).toFixed(2);
          return {
            ...p,
            id: p.id,
            variation: p.variation,
            price: p.price,
            netPrice,
            baseName: baseProduct?.name || "",
            name: variation?.name || baseProduct?.name || "",
            category: baseProduct?.productCategory?.name || "",
          };
        }),
      });

      if (validProducts?.length) {
        setProductsAreValid(true);
      }
    },
    onError: () => {
      setProductsAreValid(false);
      setStates({
        ...stateValues,
        bookedProducts: [],
      });
    },
  });

  useEffect(() => {
    if (!stateValues.bookedProducts.length) {
      setProductsAreValid(false);
    }
  }, [stateValues]);

  const handleProductSelection = useCallback(
    (
      storeSlug: string | null,
      productSlug: string | null,
      variationSlug: string | null,
    ) => {
      if (!productSlug) return;
      const urlStore = stores?.publicStores.find((s) => s.slug === storeSlug);
      const storeId = urlStore?.id;
      if (!storeId) return;
      const product = store?.publicStore.products.find(
        (p) => p.slug === productSlug,
      )?.id;
      if (!product) return;
      setStates({
        ...stateValues,
        ...(storeId && {
          branch: {
            id: storeId,
            name: urlStore?.name || "",
          },
        }),
        bookedProducts: [
          {
            id: product,
            variation: null,
            price: "0",
            name: "",
            category: "",
          },
        ],
      });
    },
    [setStates, store, stores],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("product")) {
      handleProductSelection(
        urlParams.get("store"),
        urlParams.get("product"),
        urlParams.get("variation"),
      );
    }
  }, [stores, store]);

  useEffect(() => {
    window.scroll(0, 0);
    sendEvent(GTMEvent.BOOKING_FUNNEL_SERVICE_SELECTION, {
      branch: {
        id: stateValues.store.id,
        name: stateValues.store.name,
      },
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
  }, []);

  if (
    !productCategories?.publicProductCategories ||
    !store?.publicStore.products
  )
    return <Loading />;

  const getProductCategories = (categories: any, products: any) => {
    return categories.filter((category: any) =>
      products.some(
        (product: any) => product.productCategory.id === category.id,
      ),
    );
  };

  return (
    <BookingLayout
      progress={40}
      card={responsive({
        mobile: null,
        tablet: null,
        desktop: (
          <OverviewCard
            isValidated={productsAreValid}
            tooltipTitle={
              !stateValues?.bookedProducts?.length
                ? "Füge eine Leistung hinzu, um fortzufahren."
                : ""
            }
            onContinue={() => navigate("/calendar")}
          />
        ),
      })}
      content={
        <ProductsContainer
          productCategories={getProductCategories(
            productCategories.publicProductCategories,
            store?.publicStore.products,
          )}
          products={store?.publicStore?.products || []}
        />
      }
      bottomCard={
        <BottomBadge
          content={
            <ProductsBadgeContent
              products={store?.publicStore?.products || []}
            />
          }
          to="/calendar"
        />
      }
    />
  );
};

export default ProductsSelection;
