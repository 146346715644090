import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import formItemProps from "../../helper/form/formItemProps";
import { useResetCustomerPasswordMutation } from "../../graphql/schema";
import { useLogin } from "./LoginContext";
import theme from "../../theme/theme";

const ForgotPasswordDrawer = () => {
  const [mailSent, setMailSent] = useState(false);
  const { openLogin, resetPasswordOpen, closeResetPassword } = useLogin();
  const [form] = Form.useForm();

  const [send] = useResetCustomerPasswordMutation();

  const handleResetPassword = async () => {
    if (form.getFieldsValue()) {
      try {
        await send({
          variables: {
            dto: {
              email: form?.getFieldsValue()?.email || "",
              phone: form?.getFieldsValue()?.phone || "",
            },
          },
        });
        setMailSent(true);
      } catch (e) {
        message.error(
          "Das Versenden ist fehlgeschlagen, achte darauf eine gültige E-Mail zu verwenden.",
        );
      }
    }
  };

  if (mailSent) {
    return (
      <Drawer
        open={mailSent}
        placement="bottom"
        title=""
        closeIcon={null}
        footer={
          <Row justify="center" align="bottom">
            <Col span={24}>
              <Button
                onClick={() => {
                  setMailSent(false);
                  closeResetPassword();
                  openLogin();
                }}
                style={{
                  width: "100%",
                  height: 49,
                  backgroundColor: theme.colors.button,
                  borderRadius: "20px",
                }}
              >
                <Typography.Text
                  strong
                  style={{ color: "#fff", marginBottom: 0 }}
                >
                  Anmelden
                </Typography.Text>
              </Button>
            </Col>
          </Row>
        }
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <Row justify="end">
          <Col>
            <CloseOutlined
              style={{
                fontSize: "20px",
                color: theme.colors.disabled,
              }}
              onClick={closeResetPassword}
            />
          </Col>
        </Row>
        <Row gutter={[0, 26]}>
          <Col span={24}>
            <Typography.Text>
              Eine E-Mail wurde an deine E-Mail{" "}
              <Typography.Text strong>
                {form.getFieldsValue().email}{" "}
              </Typography.Text>
              gesendet.
            </Typography.Text>
          </Col>
        </Row>
      </Drawer>
    );
  }

  return (
    <Drawer
      open={resetPasswordOpen}
      placement="bottom"
      title=""
      closeIcon={null}
      footer={
        <Row justify="center" align="bottom">
          <Col span={24}>
            <Button
              onClick={() => handleResetPassword()}
              style={{
                width: "100%",
                height: 49,
                backgroundColor: theme.colors.button,
                borderRadius: "20px",
              }}
            >
              <Typography.Text
                strong
                style={{ color: "#fff", marginBottom: 0 }}
              >
                Zurücksetzen
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      }
      style={{
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
      }}
    >
      <Row justify="end">
        <Col>
          <CloseOutlined
            style={{
              fontSize: "20px",
              color: theme.colors.disabled,
            }}
            onClick={closeResetPassword}
          />
        </Col>
      </Row>
      <Form form={form} layout="vertical">
        <Row gutter={[0, 26]}>
          <Col span={24}>
            <Typography.Title
              level={4}
              style={{
                textAlign: "left",
                margin: 0,
              }}
            >
              Passwort vergessen?
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{
                fontWeight: "bold",
                marginBottom: 10,
                paddingBottom: 0,
              }}
              {...formItemProps("email", "Email", true)}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default ForgotPasswordDrawer;
