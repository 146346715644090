import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import LoginModal from "./LoginModal";
import { useLayout } from "../layout/LayoutContext";
import LoginDrawer from "./LoginDrawer";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ForgotPasswordDrawer from "./ForgotPasswordDrawer";

interface LoginContextProps {
  loginOpen: boolean;
  resetPasswordOpen: boolean;
  openLogin: () => void;
  closeLogin: () => void;
  openResetPassword: () => void;
  closeResetPassword: () => void;
}

export const LoginContext = createContext<LoginContextProps>({
  loginOpen: false,
  resetPasswordOpen: false,
  openLogin: () => null,
  closeLogin: () => null,
  openResetPassword: () => null,
  closeResetPassword: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const useLogin = () => useContext(LoginContext);

export const LoginProvider = ({ children }: Props) => {
  const { responsive } = useLayout();
  const [loginOpen, setLoginOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

  const openLogin = useCallback(() => {
    setLoginOpen(true);
  }, []);

  const closeLogin = useCallback(() => {
    setLoginOpen(false);
  }, []);

  const openResetPassword = useCallback(() => {
    setResetPasswordOpen(true);
  }, []);

  const closeResetPassword = useCallback(() => {
    setResetPasswordOpen(false);
  }, []);

  const value = useMemo(() => {
    return {
      loginOpen,
      resetPasswordOpen,
      openLogin,
      closeLogin,
      openResetPassword,
      closeResetPassword,
    };
  }, [loginOpen]);

  return (
    <LoginContext.Provider value={value}>
      {loginOpen &&
        responsive({
          desktop: <LoginModal />,
          tablet: <LoginModal />,
          mobile: <LoginDrawer />,
        })}
      {resetPasswordOpen &&
        responsive({
          desktop: <ForgotPasswordModal />,
          tablet: <ForgotPasswordModal />,
          mobile: <ForgotPasswordDrawer />,
        })}
      {children}
    </LoginContext.Provider>
  );
};
