import { useCallback } from "react";
import { message, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import getPriceForCustomer from "../../helper/common/getPriceForCustomer";
import useBooking from "../bookingProvider/useBooking";
import { ProductOutput } from "../../graphql/schema";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useGTM from "../gtmProvider/useGTM";
import { GTMEvent } from "../gtmProvider/GTMContext";

const { confirm } = Modal;

const forbiddenLocations = ["/register", "/details"];

interface Props {
  products: Partial<ProductOutput>[];
  product: Partial<ProductOutput>;
  variationId?: number | null;
  baseProductId: number;
}

const useProductCard = ({
  products,
  product,
  variationId,
  baseProductId,
}: Props) => {
  const location = useLocation();
  const { sendEvent } = useGTM();
  const navigate = useNavigate();
  const { isAuthenticated } = useCustomerAuth();

  const { stateValues, setStates } = useBooking();

  const addToCart = () => {};

  const changeCartItem = () => {};

  const removeCartItem = () => {};

  const getTaxRate = useCallback((): number => {
    const baseProduct = products.find((p) =>
      variationId
        ? p?.variations?.some((v) => v.id === variationId)
        : p?.id === product?.id,
    );
    return Number(baseProduct?.taxRate || 0);
  }, [product, products, variationId]);

  const getProductById = useCallback(
    (productId: number) => {
      return products.find((p) => p?.id === productId);
    },
    [products],
  );

  const sendAddEvent = useCallback(() => {
    const grossPrice = getPriceForCustomer(
      product?.prices || [],
      stateValues.customer,
    );
    const baseProduct = getProductById(baseProductId);
    const taxRate = getTaxRate();
    const netPrice = Number((grossPrice / (1 + taxRate / 100)).toFixed(2));
    sendEvent(GTMEvent.BOOKING_FUNNEL_ADD_TO_CART, {
      items: [
        {
          product_id: baseProduct?.id || null,
          product_name: baseProduct?.name || null,
          variant_id: variationId || null,
          variant_name: variationId ? product?.name : null,
          category: baseProduct?.productCategory?.name || "",
          price_gross: grossPrice,
          price_net: netPrice,
          quantity: 1,
        },
      ],
      branch: {
        id: stateValues.store.id,
        name: stateValues.store.name,
      },
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
  }, []);

  const sendRemoveEvent = useCallback(() => {
    const grossPrice = getPriceForCustomer(
      product?.prices || [],
      stateValues.customer,
    );
    const baseProduct = getProductById(baseProductId);
    const taxRate = getTaxRate();
    const netPrice = Number((grossPrice / (1 + taxRate / 100)).toFixed(2));
    sendEvent(GTMEvent.BOOKING_FUNNEL_REMOVE_FROM_CART, {
      items: [
        {
          product_id: baseProduct?.id || null,
          product_name: baseProduct?.name || null,
          variant_id: variationId || null,
          variant_name: variationId ? product?.name : null,
          category: baseProduct?.productCategory?.name || "",
          price_gross: grossPrice,
          price_net: netPrice,
          quantity: 1,
        },
      ],
      branch: {
        id: stateValues.store.id,
        name: stateValues.store.name,
      },
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
  }, []);

  const changeProducts = useCallback(
    (change: () => void) => {
      if (
        forbiddenLocations.some(
          (locationTrigger) => locationTrigger === location.pathname,
        )
      ) {
        confirm({
          title: "Möchtest du deine Auswahl ändern?",
          content:
            "Wenn du deine Auswahl änderst, muss du erneut einen Termin auswählen.",
          onOk() {
            change();
            navigate("/calendar");
          },
          onCancel() {},
        });
      } else {
        change();
      }
    },
    [location],
  );

  const isComptible = useCallback(
    (productId: number) => {
      return !(
        stateValues.bookedProducts?.some(
          (p: any) =>
            product?.incompatibleProducts?.some(
              (incompatibleProduct) => incompatibleProduct.id === p.id,
            ),
        ) &&
        product?.incompatibleProducts?.some((p) =>
          stateValues.bookedProducts.some(
            (bookedProduct) => bookedProduct.id === p.id,
          ),
        )
      );
    },
    [stateValues.bookedProducts, products],
  );

  const addProduct = useCallback(
    (productId: number, variationId: number | null) => {
      if (!isComptible(productId)) {
        message.info({
          content:
            "Das Produkt ist mit einem anderen Produkt nicht kompatibel.",
        });
        return;
      }

      const baseProduct = getProductById(productId);

      const grossPrice = getPriceForCustomer(
        product?.prices || [],
        stateValues.customer,
      );
      const taxRate = getTaxRate();
      const netPrice = Number((grossPrice / (1 + taxRate / 100)).toFixed(2));

      setStates({
        bookedProducts:
          stateValues.bookedProducts.length > 0
            ? [
                ...stateValues.bookedProducts,
                {
                  id: productId,
                  name: product.name || "",
                  baseName: baseProduct?.name || "",
                  category: baseProduct?.productCategory?.name || "",
                  variation: variationId || null,
                  price: grossPrice.toString(),
                  netPrice: netPrice.toString(),
                },
              ]
            : [
                {
                  id: productId,
                  name: product.name || "",
                  baseName: baseProduct?.name || "",
                  category: baseProduct?.productCategory?.name || "",
                  variation: variationId || null,
                  price: grossPrice.toString(),
                  netPrice: netPrice.toString(),
                },
              ],
      });
      sendAddEvent();
    },
    [stateValues.bookedProducts, product, stateValues.customer],
  );

  const remove = useCallback(
    (productId: number, variationId: number | null) => {
      const productIndex = stateValues.bookedProducts?.findIndex((p) =>
        variationId
          ? p.id === productId && p.variation === variationId
          : p.id === productId,
      );
      if (productIndex && productIndex < 0) return;
      setStates({
        bookedProducts: [...stateValues.bookedProducts].filter(
          (p, index) => index !== productIndex,
        ),
      });

      sendRemoveEvent();
    },
    [product, stateValues.customer],
  );

  const getProductQuantity = useCallback(
    (productId: number, variationId: number | null) => {
      return stateValues.bookedProducts.filter((bookedProduct) => {
        return variationId
          ? variationId === bookedProduct.variation &&
              bookedProduct.id === productId
          : bookedProduct.id === productId;
      }).length;
    },
    [product, stateValues],
  );
  return {
    addToCart,
    changeCartItem,
    removeCartItem,
    getProductById,
    changeProducts,
    isComptible,
    addProduct,
    remove,
    getProductQuantity,
  };
};

export default useProductCard;
