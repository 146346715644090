import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Typography,
} from "antd";
import React from "react";
import { RightOutlined, UserOutlined } from "@ant-design/icons";
import { useLogin } from "../login/LoginContext";
import formItemProps from "../../helper/form/formItemProps";
import useBooking from "../bookingProvider/useBooking";
import SelectCountryCode from "./SelectCountryCode";

const nameRegex = /^[a-zA-ZäöüßÄÖÜẞ\- ]{2,50}$/g;
const phoneRegex = /^\+?\d{7,12}$/;
const mailRegex =
  /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;

interface Props {
  form: FormInstance;
}

const ContactDesktop = ({ form }: Props) => {
  const { openLogin } = useLogin();
  const { stateValues } = useBooking();

  return (
    <div>
      <Row
        style={{
          width: "100%",
        }}
      >
        <Col span={12}>
          <Button
            onClick={openLogin}
            type="text"
            style={{
              backgroundColor: "#fff",
              width: "100%",
              padding: 0,
            }}
          >
            <Row align="middle">
              <Col
                span={23}
                style={{
                  display: "flex",
                }}
              >
                <UserOutlined
                  style={{
                    fontSize: "20px",
                    paddingRight: 10,
                  }}
                />
                <Typography.Text strong>
                  Du hast einen Account? Jetzt anmelden
                </Typography.Text>
              </Col>
              <Col
                span={1}
                style={{
                  textAlign: "right",
                }}
              >
                <RightOutlined />
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: 5,
        }}
      >
        <Col
          span={12}
          style={{
            paddingLeft: 30,
          }}
        >
          <Divider
            style={{
              margin: 0,
            }}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: 24,
        }}
      >
        <Col
          style={{
            textAlign: "left",
          }}
        >
          <Typography.Text
            style={{
              marginRight: "10px",
            }}
          >
            ODER
          </Typography.Text>
          trage deine Kontaktdaten ein:
          <Typography.Text />
        </Col>
      </Row>
      <Form
        layout="vertical"
        initialValues={{
          firstName: stateValues.customer.firstName,
          lastName: stateValues.customer.lastName,
          email: stateValues.customer.email,
          phone: stateValues.customer.phone.includes(" ")
            ? stateValues.customer.phone.split(" ")[1]
            : "",
          password: stateValues.customer.password,
          countryCode: "+49",
        }}
        style={{
          paddingBottom: "20px",
          paddingTop: "24px",
        }}
        form={form}
      >
        <Row gutter={[26, 6]}>
          <Col span={12}>
            <Form.Item
              style={{
                fontWeight: "bold",
              }}
              {...formItemProps("firstName", "Vorname", true)}
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    return value.length < 50
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Die maximale Länge beträgt 50 Zeichen.",
                },
                {
                  required: true,
                  validator: (rule, value) => {
                    return new RegExp(nameRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Bitte trage deinen Vornamen ein.",
                },
              ]}
            >
              <Input
                size="large"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              style={{
                fontWeight: "bold",
              }}
              {...formItemProps("lastName", "Nachname", true)}
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    return value.length < 50
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Die maximale Länge beträgt 50 Zeichen.",
                },
                {
                  required: true,
                  validator: (rule, value) => {
                    return new RegExp(nameRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Bitte trage deinen Nachnamen ein.",
                },
              ]}
            >
              <Input
                size="large"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              style={{
                fontWeight: "bold",
              }}
              {...formItemProps("email", "E-Mail", true)}
              rules={[
                {
                  required: true,
                  type: "email",
                  validator: (rule, value) => {
                    return new RegExp(mailRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                  message: "Bitte trage deine E-Mail ein.",
                },
              ]}
            >
              <Input
                size="large"
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemProps("phone", "Mobilnummer", true)}
              rules={[
                {
                  required: true,
                  message: "Bitte trage deine Mobilnummer ein.",
                  validator: (rule, value) => {
                    return new RegExp(phoneRegex).test(value)
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                },
              ]}
            >
              <Input
                size="large"
                addonBefore={
                  <Form.Item
                    style={{
                      minWidth: 80,
                    }}
                    name={["countryCode"]}
                    noStyle
                    initialValue="+49"
                  >
                    <SelectCountryCode />
                  </Form.Item>
                }
                style={{
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              style={{
                fontWeight: "bold",
              }}
              {...formItemProps("password", "Passwort", false)}
            >
              <Input.Password
                size="large"
                style={{
                  justifyContent: "center",
                  borderRadius: "10px",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContactDesktop;
