import React, { createRef, useEffect, useRef } from "react";
import { Col, List, Row, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./ProductsContainer.module.scss";
import { useLayout } from "../layout/LayoutContext";
import theme from "../../theme/theme";

interface Props {
  onSelect: (value: number | null) => void;
  categories: any[];
  category: number | null;
}

const CategorySelect = ({ onSelect, category, categories }: Props) => {
  const { responsive } = useLayout();
  const scrollableRef = useRef<HTMLDivElement>(null);

  const index = categories.findIndex((c) => c.id === category);

  const categoryRefs = categories.reduce(
    (refs, category) => {
      if (category.id !== undefined) {
        refs[category.id] = createRef<HTMLDivElement>();
      }
      return refs;
    },
    {} as Record<number, React.RefObject<HTMLDivElement>>,
  );

  useEffect(() => {
    if (category && categoryRefs[category]?.current && scrollableRef.current) {
      const categoryElement = categoryRefs[category].current;
      const scrollableElement = scrollableRef.current;
      const leftOffset =
        categoryElement.offsetLeft - scrollableElement.offsetLeft;
      scrollableElement.scrollTo({
        left: leftOffset,
        behavior: "smooth",
      });
    }
  }, [category, categoryRefs]);

  const handleSelect = (selectedCategory: number | null) => {
    onSelect(selectedCategory);
    if (
      selectedCategory &&
      categoryRefs[selectedCategory]?.current &&
      scrollableRef.current
    ) {
      const selectedElement = categoryRefs[selectedCategory].current;
      const scrollableElement = scrollableRef.current;
      const leftOffset =
        selectedElement.offsetLeft - scrollableElement.offsetLeft;
      scrollableElement.scrollTo({
        left: leftOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <Row
      align="middle"
      style={{
        paddingBottom: "10px",
      }}
    >
      <Col
        style={{
          justifyItems: "start",
          alignItems: "start",
        }}
        span={responsive({
          desktop: 1,
          tablet: 1,
          mobile: 0,
        })}
      >
        <LeftOutlined
          style={{
            color: index <= 0 ? theme.colors.disabled : theme.colors.header,
            justifySelf: "center",
            fontSize: "20px",
          }}
          onClick={() => {
            if (index > 0) {
              handleSelect(categories[index - 1].id);
            }
          }}
        />
      </Col>
      <Col
        span={responsive({
          desktop: 22,
          tablet: 22,
          mobile: 24,
        })}
      >
        <div
          ref={scrollableRef}
          style={{
            width: "100%",
            overflowX: "auto",
            whiteSpace: "nowrap",
            scrollbarWidth: "none",
          }}
        >
          <List
            id="category-list"
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              scrollbarWidth: "none",
              scrollbarGutter: "10px",
            }}
            itemLayout="horizontal"
            className={styles.hideHorizontalScrollbar}
            dataSource={categories}
            renderItem={(item) => (
              <div
                key={item.id}
                ref={categoryRefs[item.id]}
                style={{
                  display: "inline-block",
                }}
              >
                <List.Item
                  onClick={() =>
                    handleSelect(category === item.id ? null : item.id)
                  }
                  style={{
                    cursor: "pointer",
                    minHeight: 38,
                    border:
                      category === item.id
                        ? `1px solid ${theme.colors.header}`
                        : `1px solid ${theme.colors.header}`,
                    backgroundColor:
                      category === item.id
                        ? theme.colors.header
                        : theme.colors.background,
                    paddingLeft: "34px",
                    paddingRight: "34px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    borderRadius: "15px",
                    marginRight: "16px",
                  }}
                >
                  <Col>
                    <Typography.Text
                      strong
                      style={{
                        color:
                          category === item.id
                            ? theme.colors.background
                            : theme.colors.header,
                      }}
                    >
                      {item.name}
                    </Typography.Text>
                  </Col>
                </List.Item>
              </div>
            )}
          />
        </div>
      </Col>
      <Col
        style={{
          justifyContent: "flex-end",
        }}
        span={responsive({
          desktop: 1,
          tablet: 1,
          mobile: 0,
        })}
      >
        <RightOutlined
          style={{
            color:
              index === categories.length - 1 || index < 0
                ? theme.colors.disabled
                : theme.colors.header,
            fontSize: "20px",
          }}
          onClick={() => {
            if (index < categories.length - 1) {
              handleSelect(categories[index + 1].id);
            }
          }}
        />
      </Col>
    </Row>
  );
};

export default CategorySelect;
