import { useNavigate } from "react-router-dom";
import { Form, message } from "antd";
import { useEffect, useMemo } from "react";
import DetailsMobile from "../../components/details/DetailsMobile";
import useBooking from "../../components/bookingProvider/useBooking";
import {
  CommunicationType,
  Country,
  CustomerType,
  useCreateAppointmentByBookingRouteMutation,
  useGetIsExistingCustomerQuery,
  useGetPublicStoreQuery,
  useRegisterCustomerMutation,
  useRescheduleAppointmentByCustomerMutation,
} from "../../graphql/schema";
import DetailsDesktop from "../../components/details/DetailsDesktop";
import { useLayout } from "../../components/layout/LayoutContext";
import BookingLayout from "../../components/layout/BookingLayout";
import OverviewCard from "../../components/overview/OverviewCard";
import SubHeader from "../../components/base/SubHeader";
import BottomBadge from "../../components/utils/BottomCard";
import DetailsBadgeContent from "../../components/details/DetailsBadgeContent";
import getGTMCart from "../../helper/common/getGTMCart";
import useCustomerAuth from "../../customerAuth/useCustomerAuth";
import useGTM from "../../components/gtmProvider/useGTM";
import { GTMEvent } from "../../components/gtmProvider/GTMContext";
import toFixedNumber from "../../helper/common/toFixedNumber";

const BookingDetails = () => {
  const { sendEvent } = useGTM();
  const { isAuthenticated, updateTokens } = useCustomerAuth();
  const [checkboxForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { stateValues, setStates } = useBooking();
  const newsletter = Form.useWatch("newsletter", checkboxForm);
  const terms = Form.useWatch("terms", checkboxForm);
  const { responsive } = useLayout();
  const navigate = useNavigate();
  const [register] = useRegisterCustomerMutation();
  const { data: isExistingCustomer, loading: loadingExistingCustomer } =
    useGetIsExistingCustomerQuery({
      variables: {
        email: stateValues.customer.email,
      },
      skip: !stateValues.customer.email,
    });
  const [createBooking] = useCreateAppointmentByBookingRouteMutation();
  const [reschedule] = useRescheduleAppointmentByCustomerMutation();
  const scuid =
    document.cookie.replace(
      /(?:(?:^|.*;\s*)scuid\s*\=\s*([^;]*).*$)|^.*$/,
      "$1",
    ) || null;

  const { data: store } = useGetPublicStoreQuery({
    variables: {
      id: stateValues.store.id!,
    },
    skip: !stateValues.store.id,
  });

  useEffect(() => {
    window.scroll(0, 0);
    sendEvent(GTMEvent.BOOKING_FUNNEL_CONTACT_DETAILS, {
      appointment: {
        datetime: stateValues?.appointment?.toISOString(),
        notify_about_earlier_possible_appointment:
          stateValues.notifyForEarlierAppointment,
      },
      cart_items: getGTMCart(stateValues),
      branch: {
        id: stateValues.store.id,
        name: stateValues.store.name,
      },
      user: {
        id: stateValues?.customer?.id || null,
        has_account: isAuthenticated,
        email: stateValues?.customer?.email?.toLowerCase() || null,
        phone: stateValues?.customer?.phone || null,
      },
    });
  }, []);

  const handleReschedule = async (customerId: number) => {
    try {
      await reschedule({
        variables: {
          dto: {
            customerId: customerId || 0,
            products: stateValues.bookedProducts.map((product) => ({
              productId: product.id,
              variationId: product.variation,
              price: String(product.price),
            })),
            storeId: stateValues.store.id || 0,
            couponCodes: stateValues.couponCodes,
            notifyForEarlierAppointment:
              stateValues.notifyForEarlierAppointment,
            additionalCustomerIds: [],
            appointment: stateValues.appointment,
            message: stateValues.message || "",
            scuid,
            rescheduleId: stateValues.rescheduleId || 0,
          },
        },
      });
      setStates({
        ...stateValues,
        rescheduleId: undefined,
      });
      navigate("/confirmation");
    } catch (e: any) {
      if (e.message === "The desired slot is not available anymore.") {
        message.error(
          "Der gewünschte Slot ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Termin.",
        );
      } else {
        message.error(
          "Der Termin konnte nicht verschoben werden. Wählen Sie bitte einen anderes Datum.",
        );
      }
      setStates({
        ...stateValues,
        rescheduleId: undefined,
      });
    }
  };

  const handleCreateBooking = async (customerId: number) => {
    try {
      const appointment = await createBooking({
        variables: {
          dto: {
            storeId: stateValues.store.id || 0,
            customerId: customerId || 0,
            products: stateValues.bookedProducts.map((product) => ({
              productId: product.id,
              variationId: product.variation,
              price: String(product.price),
            })),
            couponCodes: stateValues.couponCodes,
            notifyForEarlierAppointment:
              stateValues.notifyForEarlierAppointment,
            additionalCustomerIds: [],
            appointment: stateValues.appointment,
            message: stateValues.message || "",
            scuid,
          },
        },
      });

      sendEvent(GTMEvent.BOOKING_FUNNEL_BOOKED, {
        is_first_booking: !isExistingCustomer?.isExistingCustomer,
        transaction_id: appointment?.data?.createAppointmentByBookingRoute?.id,
        total_gross: toFixedNumber(
          appointment?.data?.createAppointmentByBookingRoute.bookedPrice,
        ),
        total_net: toFixedNumber(
          stateValues.bookedProducts
            .map((product) => Number(product.netPrice))
            .reduce((acc, netPrice) => (acc || 0) + (netPrice || 0)),
        ),
        coupon: stateValues.couponCodes.join(", ") || null,
        appointment: {
          datetime: stateValues?.appointment?.toISOString(),
          notify_about_earlier_possible_appointment:
            stateValues.notifyForEarlierAppointment,
        },
        cart_items: getGTMCart(stateValues),
        branch: {
          id: stateValues.store.id,
          name: stateValues.store.name,
        },
        user: {
          id: customerId || null,
          has_account: true,
          email: stateValues?.customer?.email?.toLowerCase() || null,
          phone: stateValues?.customer?.phone || null,
        },
      });

      setStates({
        ...stateValues,
        rescheduleId: undefined,
      });
      navigate("/confirmation");
    } catch (e: any) {
      if (e.message === "The desired slot is not available anymore.") {
        message.error(
          "Der gewünschte Slot ist nicht mehr verfügbar. Bitte wählen Sie einen anderen Termin.",
        );
      } else {
        message.error(
          "Die Buchung konnte nicht erstellt werden. Wählen Sie bitte einen anderen Termin.",
        );
      }
      setStates({
        ...stateValues,
        rescheduleId: undefined,
      });
    }
  };

  const handleRegister = async () => {
    try {
      const customer = await register({
        variables: {
          dto: {
            firstName: stateValues.customer.firstName || "",
            lastName: stateValues.customer.lastName || "",
            email: stateValues.customer.email || "",
            password: stateValues.customer.password || "",
            phone: stateValues.customer.phone || "",
            street: "",
            streetNumber: "",
            zipCode: "",
            city: "",
            country: Country.Germany,
            preferredCommunicationType: CommunicationType.Email,
            termsAccepted: true,
          },
        },
      });

      setStates({
        ...stateValues,
        customer: {
          id: customer?.data?.registerCustomer?.customer?.id || undefined,
          firstName:
            customer?.data?.registerCustomer?.customer?.firstName || "",
          lastName: customer?.data?.registerCustomer?.customer?.lastName || "",
          category:
            customer?.data?.registerCustomer?.customer?.category ||
            CustomerType.Standard,
          email: customer?.data?.registerCustomer?.customer?.email || "",
          phone: customer?.data?.registerCustomer?.customer?.phone || "",
          password: "",
        },
        additionalCustomers: [],
      });

      updateTokens(customer?.data?.registerCustomer.token || "");

      return customer?.data?.registerCustomer?.customer?.id;
    } catch (error: any) {
      if (error.message === "User must accept terms and conditions") {
        message.error(
          "Bitte akzeptieren Sie die AGB und Datenschutzbestimmungen.",
        );
        return null;
      }
      if (error.message === "A user with this email address already exists") {
        message.error("Ein Kunde mit dieser E-Mail-Adresse existiert bereits.");
        return null;
      }

      if (error.message === "A user with this phone number already exists") {
        message.error("Ein Kunde mit dieser Telefonnummer existiert bereits.");
        return null;
      }

      message.error(
        "Es ist ein Fehler aufgetreten. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte.",
      );
      return null;
    }
  };

  const handleBooking = async () => {
    try {
      messageApi.open({
        content: stateValues.rescheduleId
          ? "Termin wird verschoben..."
          : "Buchung wird erstellt...",
        type: "loading",
        duration: 0,
      });
      const customerId = stateValues?.customer?.id || (await handleRegister());
      if (!customerId) return;
      if (stateValues.rescheduleId) {
        await handleReschedule(customerId);
        return;
      }
      await handleCreateBooking(customerId);
      messageApi.destroy();
    } catch (e) {
      message.error(
        "Es ist ein Fehler aufgetreten. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte.",
      );
    }
  };

  const showBadge = useMemo(() => {
    return !!terms || stateValues.customer.id;
  }, [terms]);

  if (loadingExistingCustomer) return null;

  return (
    <>
      {contextHolder}
      <BookingLayout
        progress={100}
        card={responsive({
          tablet: null,
          mobile: null,
          desktop: (
            <OverviewCard
              isLastView
              isValidated={!!stateValues.customer.id}
              continueButtonTitle={
                stateValues?.rescheduleId
                  ? "Termin verschieben"
                  : "Termin buchen"
              }
              tooltipTitle="Stimme den AGB, Datenschutzbestimmungen zu, um deinen Termin zu buchen."
              onContinue={() => handleBooking()}
              showPrice
              products={store?.publicStore?.products || []}
            />
          ),
        })}
        subHeader={
          <SubHeader
            title="Übersicht & Bestätigung"
            onBack={() => {
              if (stateValues?.customer.id) {
                navigate("/calendar");
              } else {
                navigate(-1);
              }
            }}
          />
        }
        content={responsive({
          tablet: (
            <DetailsMobile
              products={store?.publicStore.products}
              checkboxForm={checkboxForm}
            />
          ),
          mobile: (
            <DetailsMobile
              products={store?.publicStore.products}
              checkboxForm={checkboxForm}
            />
          ),
          desktop: (
            <DetailsDesktop products={store?.publicStore?.products || []} />
          ),
        })}
        bottomCard={
          showBadge ? (
            <BottomBadge
              title={
                stateValues?.rescheduleId
                  ? "Termin verschieben"
                  : "Termin buchen"
              }
              content={
                <DetailsBadgeContent
                  products={store?.publicStore?.products || []}
                />
              }
              onContinue={() => handleBooking()}
              to="/confirmation"
            />
          ) : null
        }
      />
    </>
  );
};
export default BookingDetails;
