import { BookingInterface } from "../../components/bookingProvider/BookingContext";

const getCustomerLoginOptions = (stateValues: BookingInterface) => {
  return {
    customer: {
      id: stateValues.customer.id || null,
      firstName: stateValues.customer.firstName,
      lastName: stateValues.customer.lastName,
      email: stateValues.customer.email,
      phone: stateValues.customer.phone,
      password: stateValues.customer.password,
      category: stateValues.customer.category,
    },
    bookedProducts: stateValues.bookedProducts.map((product) => ({
      id: product.id,
      name: product.name,
      baseName: product.baseName || null,
      variation: product.variation || null || undefined,
      price: product.price || null || undefined,
      netPrice: product.netPrice || null || undefined,
      category: product.category,
    })),
    notifyForEarlierAppointment: stateValues.notifyForEarlierAppointment,
    appointment: stateValues.appointment
      ? new Date(stateValues.appointment)
      : null,
    store: {
      id: stateValues.store.id || null,
      name: stateValues.store.name,
    },
  };
};

export default getCustomerLoginOptions;
